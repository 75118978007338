import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// styling and related components
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Presyes screens and components
import {
  getParticipant,
  updateParticipant,
  deleteParticipant,
  clearParticipantState
} from "../../store/actions/participantActions";
import {
  getActivity,
  updateActivity,
  getActivityParticipants
} from "../../store/actions/activityActions";
import BaseInputField from "../fields/BaseInputField";
import BaseCheckBox from "../fields/BaseCheckBox";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";

// configs and utility function
import { testLog } from "../../utils/index";
import { getValueFromField } from "../../utils/htmlUtils";
import Label from "../layout/Label";
import { getLabel } from "../../config/labels";
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardHeaderTextStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";
import { HISTORY_BACK_INTERVAL } from "../../config/settings";

const EditParticipant = (props: any) => {
  const {
    auth,
    profile,
    participant,
    activity,
    match,
    getParticipant,
    deleteParticipant,
    getActivity,
    getActivityParticipants,
    updateParticipant,
    updateActivity,
    clearParticipantState
  } = props;
  const [open, setOpen] = useState(false);
  const { uid } = auth;
  const { participantId, activityId } = match.params;

  useEffect(() => {
    if (uid && participantId && activityId) {
      getParticipant(participantId);
      getActivity(activityId);
      getActivityParticipants(activityId);
    }
    return () => {
      clearParticipantState();
    };
  }, [
    uid,
    participantId,
    activityId,
    getParticipant,
    getActivity,
    getActivityParticipants,
    clearParticipantState
  ]);

  let language = window.navigator.language;
  if (profile && profile.settings && profile.settings.language) {
    language = profile.settings.language;
  }

  if (!uid) return <Redirect to="/signin" />;

  const handleUpdateParticipant = async () => {
    if (participant && participant.userId && activity && activity.admins) {
      const role = getValueFromField("role");
      const note = getValueFromField("note");
      const defaultPresence =
        getValueFromField("default-presence").toLowerCase() === "true"
          ? true
          : false;

      let admin = false;
      if (document.getElementById("admin")) {
        admin =
          getValueFromField("admin").toLowerCase() === "true" ? true : false;
      }

      const supportOnly =
        getValueFromField("support-only").toLowerCase() === "true"
          ? true
          : false;

      const newParticipant = {
        ...participant,
        role: role,
        note: note,
        supportOnly: supportOnly,
        defaultPresence: defaultPresence,
        admin: admin
      };

      let newAdmins = activity.admins;
      if (admin) {
        if (!activity.admins.includes(participant.userId)) {
          newAdmins.push(participant.userId);
        }
      } else {
        newAdmins = activity.admins.filter((admin: any) => {
          return admin !== participant.userId;
        });
      }

      const newActivity = {
        ...activity,
        admins: newAdmins
      };

      await updateParticipant(newParticipant);
      await updateActivity(newActivity);
      setTimeout(() => window.history.back(), HISTORY_BACK_INTERVAL);
    } else {
      testLog("Participant data not correct");
    }
  };

  const handleDeleteParticipant = async () => {
    if (participant && participant.userId && activity && activity.admins) {
      // remove from activity admins if participant is also admin
      if (activity.admins.includes(participant.userId)) {
        let newAdmins = activity.admins;
        newAdmins = activity.admins.filter((admin: any) => {
          return admin !== participant.userId;
        });
        const newActivity = {
          ...activity,
          admins: newAdmins
        };
        await updateActivity(newActivity);
      }
      await deleteParticipant(participant);
      window.history.back();
    } else {
      testLog("Participant data not correct");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleDeleteParticipant();
    setOpen(false);
  };

  let disableIt = false;
  if (
    auth &&
    auth.uid &&
    activity &&
    activity.admins &&
    activity.admins.length < 2 &&
    participant &&
    participant.userId === auth.uid
  ) {
    if (activity.admins.includes(auth.uid)) {
      disableIt = true;
    }
  }

  const adminField =
    auth &&
    auth.uid &&
    participant &&
    activity &&
    activity.admins &&
    activity.admins.includes(auth.uid) ? (
      <div>
        <BaseCheckBox
          id="admin"
          name="admin"
          label={getLabel("admin", language)}
          checked={participant.admin ? participant.admin : false}
          disabled={disableIt}
        />
      </div>
    ) : (
      <div></div>
    );

  const removeButton = !disableIt ? (
    <div>
      <StyledButton onClick={handleClickOpen}>
        <Label label={"delete-participant"} />
      </StyledButton>
      <ConfirmDeleteDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        name={"confirm-delete"}
        description={"confirm-delete-description"}
      />
    </div>
  ) : (
    <div></div>
  );

  if (participant) {
    const fullName = `${participant.firstName}${" "}${participant.lastName}`;
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard raised>
            <StyledHeader>{fullName}</StyledHeader>
            <BaseCheckBox
              id="default-presence"
              name="default-presence"
              label={getLabel("default-presence", language)}
              checked={
                participant.defaultPresence
                  ? participant.defaultPresence
                  : false
              }
            />
            {adminField}
            <BaseCheckBox
              id="support-only"
              name="support-only"
              label={getLabel("support-only", language)}
              checked={
                participant.supportOnly ? participant.supportOnly : false
              }
            />
            <BaseInputField
              id="role"
              name="role"
              label={getLabel("role", language)}
              defaultValue={participant.role ? participant.role : ""}
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <BaseInputField
              id="note"
              name="note"
              label={getLabel("note", language)}
              defaultValue={participant.note ? participant.note : ""}
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <StyledButton onClick={handleUpdateParticipant}>
              <Label label={"update-participant"} />
            </StyledButton>
            {removeButton}
          </StyledCard>
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activity: state.activity.activity,
    participant: state.participant.participant
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getParticipant: (partcipantId: string) =>
      dispatch(getParticipant(partcipantId)),
    getActivity: (activityId: string) => dispatch(getActivity(activityId)),
    updateActivity: (activityId: string) =>
      dispatch(updateActivity(activityId)),
    getActivityParticipants: (activityId: string) =>
      dispatch(getActivityParticipants(activityId)),
    updateParticipant: (participant: any) =>
      dispatch(updateParticipant(participant)),
    deleteParticipant: (participant: any) =>
      dispatch(deleteParticipant(participant)),
    clearParticipantState: () => dispatch(clearParticipantState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParticipant);

// Styles
const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px  
`;

const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  color: ${(props: any) => props.theme.colors.onBackground};
  ${buttonStyle}
`;
const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.primaryLight};
  ${cardStyle}
`;

const StyledHeader = styled(Typography)`
  padding-left: 12px;
  padding-top: 5px;
  margin-bottom: 10px;

  ${cardHeaderTextStyle};
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;
