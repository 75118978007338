import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// styling and related components
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
// Presyes screens and components and actions
import SpecialField from "../fields/SpecialField";
import DateTimePickerField from "../fields/DateTimePickerField";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";
import {
  getActivity,
  clearActivityState
} from "../../store/actions/activityActions";
import {
  getEvent,
  updateEvent,
  deleteEvent,
  clearEventState
} from "../../store/actions/eventActions";
// configs and utility functions
import { getValueFromField } from "../../utils/htmlUtils";
import { dateStringToTimestamp, timestampToDate, testLog } from "../../utils";
import Label from "../layout/Label";
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const EditEvent = (props: any) => {
  const {
    auth,
    profile,
    event,
    activity,
    match,
    getEvent,
    getActivity,
    clearActivityState,
    clearEventState
  } = props;
  const { uid } = auth;
  const { eventId, activityId } = match.params;
  const [open, setOpen] = useState(false);
  const [gotoUpcoming, setGotoUpcoming] = useState(false);

  useEffect(() => {
    if (uid && eventId) {
      getEvent(eventId);
      getActivity(activityId);
    }
    return () => {
      clearActivityState();
      clearEventState();
    };
  }, [uid, eventId, activityId, getEvent, getActivity, clearActivityState, clearEventState]);

  if (!uid) return <Redirect to="/signin" />;
  if (gotoUpcoming) return <Redirect to="/upcoming" />;

  const handleUpdateEvent = async () => {
    const eventName = getValueFromField("event-name");
    const startDateTime = getValueFromField("start-date-time");
    const endDateTime = getValueFromField("end-date-time");
    const eventLocation = getValueFromField("location");
    const eventAddress = getValueFromField("address");
    const presenceLimit = parseInt(getValueFromField("presence-limit"));
    const note = getValueFromField("note");

    if (event) {
      const newEvent = {
        ...event,
        eventName: eventName,
        startDateTime: dateStringToTimestamp(startDateTime),
        endDateTime: dateStringToTimestamp(endDateTime),
        eventLocation: eventLocation,
        eventAddress: eventAddress,
        presenceLimit: presenceLimit,
        note: note
      };
      props.updateEvent(auth, profile, newEvent);
      window.history.back();
    } else {
      testLog("Activity data not correct");
    }
  };

  const handleDeleteEvent = async () => {
    if (event && event.eventId) {
      await props.deleteEvent(event.eventId);
      setTimeout(() => setGotoUpcoming(true), 1000);
      setGotoUpcoming(true);
    } else {
      testLog("Event data not correct");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleDeleteEvent();
    setOpen(false);
  };

  let enableIt = false;
  if (auth && auth.uid && activity && activity.admins) {
    if (activity.admins.includes(auth.uid)) {
      enableIt = true;
    }
  }

  const removeButton = enableIt ? (
    <div>
      <StyledButton onClick={handleClickOpen}>
        <Label label={"delete-event"} />
      </StyledButton>
      <ConfirmDeleteDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        name={"confirm-delete"}
        description={"confirm-delete-description"}
      />
    </div>
  ) : (
    <div></div>
  );

  if (event) {
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard raised>
            <form>
              <SpecialField
                name="event-name"
                defaultValue={event.eventName}
                fullWidth
              />
              <DateTimePickerField
                name="start-date-time"
                defaultValue={timestampToDate(event.startDateTime)}
                fullWidth
                extraBottomMargin="25px"
              />
              <DateTimePickerField
                name="end-date-time"
                defaultValue={timestampToDate(event.endDateTime)}
                minDateTime={timestampToDate(event.startDateTime)}
                fullWidth
                extraBottomMargin="10px"
              />
              <SpecialField
                name="location"
                defaultValue={event.eventLocation}
                fullWidth
              />
              <SpecialField
                name="address"
                defaultValue={event.eventAddress}
                fullWidth
              />
              <SpecialField
                name="presence-limit"
                defaultValue={event.presenceLimit}
                fullWidth
              />
              <SpecialField name="note" defaultValue={event.note} fullWidth />
              <StyledButton onClick={handleUpdateEvent}>
                <Label label={"update-event"} />
              </StyledButton>
              {removeButton}
            </form>
          </StyledCard>
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activity: state.activity.activity,
    event: state.event.event
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEvent: (eventId: string) => dispatch(getEvent(eventId)),
    getActivity: (activityId: string) => dispatch(getActivity(activityId)),
    updateEvent: (auth: any, profile: any, event: any) =>
      dispatch(updateEvent(auth, profile, event)),
    deleteEvent: (eventId: string) => dispatch(deleteEvent(eventId)),
    clearActivityState: () => dispatch(clearActivityState()),
    clearEventState: () => dispatch(clearEventState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px
  `;
const StyledWrapper = styled("div")`
    ${wrapperStyle}
    background-color: ${(props: any) => props.theme.colors.background};
  `;
const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  color: ${(props: any) => props.theme.colors.button};
  ${buttonStyle}
`;
const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.secundary};
  ${cardStyle}
`;
