import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import {
  saveUserDetails,
  getUserDetails
} from "../../store/actions/userActions";
import ProfileField from "../fields/ProfileField";

// import { devLog } from '../../utils';
import { getValueFromField } from "../../utils/htmlUtils";
import Label from "../layout/Label";
import MessageSnackBar from "../dialogs/MessageSnackBar";
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";
import {
  // HISTORY_BACK_INTERVAL,
  SNACKBAR_INTERVAL,
  DEFAULT_USER_SETTINGS
} from "../../config/settings";
import { getLabel } from "../../config/labels";
import { getNavigatorLanguage } from "../../language";

const Profile = (props: any) => {
  const { auth, profile, userDetails, getUserDetails, saveUserDetails } = props;
  const { uid } = auth;
  const [formValues, setFormValues] = useState({
    receivedFromUserDetails: false,
    firstname: "",
    firstnameError: false,
    lastname: "",
    lastnameError: false,
    bioKeywords: "",
    bioKeywordsError: false
  });
  const [showMessage, setShowMessage] = React.useState(false);
  const [language, setLanguage] = React.useState(getNavigatorLanguage());

  useEffect(() => {
    if (profile && profile.settings && profile.settings.language) {
      setLanguage(profile.settings.language);
    }
    if (uid) {
      getUserDetails(uid);
    }
  }, [uid, getUserDetails, profile]);

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  }

  const handleSave = () => {
    // get new form values from DOM for input fields and formValues for select menu fields.
    let newFormValues = {
      receivedFromUserDetails: true,
      firstname: "",
      firstnameError: false,
      lastname: "",
      lastnameError: false,
      bioKeywords: "",
      bioKeywordsError: false
    };

    let formError = false;
    newFormValues.firstname = getValueFromField("firstname");
    if (!newFormValues.firstname) {
      newFormValues.firstnameError = true;
      formError = true;
    } else {
      newFormValues.firstnameError = false;
    }
    newFormValues.lastname = getValueFromField("lastname");
    if (!newFormValues.lastname) {
      newFormValues.lastnameError = true;
      formError = true;
    } else {
      newFormValues.lastnameError = false;
    }
    newFormValues.bioKeywords = getValueFromField("bio-keywords");

    // check on errors and if not create the newdata profiles and set the formValues for the rendered form.
    if (formError) {
      setFormValues({
        ...newFormValues
      });
    } else if (userDetails) {
      const userSettings = userDetails.settings
        ? userDetails.settings
        : DEFAULT_USER_SETTINGS;
      const newUserDetails = {
        uid: auth.uid,
        email: auth.email,
        firstName: newFormValues.firstname,
        lastName: newFormValues.lastname,
        bioKeywords: newFormValues.bioKeywords,
        settings: userSettings
      };
      setFormValues({
        ...formValues,
        firstname: newFormValues.firstname,
        lastname: newFormValues.lastname,
        bioKeywords: newFormValues.bioKeywords
      });
      saveUserDetails(newUserDetails);
      setShowMessage(true);
      // setTimeout(() => window.history.back(), HISTORY_BACK_INTERVAL);
    }
  };

  const message = showMessage ? (
    <MessageSnackBar
      message={getLabel("saved", language)}
      duration={SNACKBAR_INTERVAL}
      language={language}
      setShowMessage={setShowMessage}
    />
  ) : (
    <div></div>
  );

  const profileForm = (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledCard raised>
          <form>
            <ProfileField name="email" defaultValue={auth.email} disabled />
            <StyledDiv>
              <ProfileField
                name="firstname"
                defaultValue={formValues.firstname}
                error={formValues.firstnameError}
                // onBlur={handleChange('firstname')}
                variant="outlined"
                fullWidth
              />
            </StyledDiv>
            <StyledDiv>
              <ProfileField
                name="lastname"
                defaultValue={formValues.lastname}
                error={formValues.lastnameError}
                // onBlur={handleChange('lastname')}
                variant="outlined"
                fullWidth
              />
            </StyledDiv>
            <ProfileField
              name="bio-keywords"
              defaultValue={formValues.bioKeywords}
              error={formValues.bioKeywordsError}
              // onBlur={handleChange('bioKeywords')}
              variant="outlined"
              fullWidth
            />
          </form>
          <StyledButton onClick={handleSave}>
            <Label label={"save-profile"} />
          </StyledButton>
        </StyledCard>
      </StyledWrapper>
      {message}
    </StyledBackgroundDiv>
  );

  // show the form values
  if (formValues && formValues.receivedFromUserDetails) {
    return <div>{profileForm}</div>;
    // set receivedFromUserDetails true if values are received.
  } else if (userDetails) {
    setFormValues({
      ...formValues,
      receivedFromUserDetails: true,
      firstname: userDetails.firstName,
      lastname: userDetails.lastName,
      bioKeywords: userDetails.bioKeywords
    });
    return <div></div>;
    // nothing to show.
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    userDetails: state.user.userDetails
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserDetails: (userDetails: any) => dispatch(getUserDetails(userDetails)),
    saveUserDetails: (newUserDetails: any) =>
      dispatch(saveUserDetails(newUserDetails))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
  `;
const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  ${buttonStyle}
`;

const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.secundary};
  ${cardStyle}
`;
const StyledDiv = styled("div")`
  margin-top: 5px;
  margin-bottom: 15px;
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;
