import React from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import { tileParticipationStyle } from "../../config/styles";
import { timestampToMMMDD, timestampToHHMM } from "../../utils/index";

import { getTileSettings } from "../../config/settings";
import { logoStyle } from "../../config/styles";

export default function ParticipationsEventHeaderTile(props: any): JSX.Element {
  const { participation, tileStyle } = props;
  let tileSettings: any = getTileSettings(tileStyle);

  const StyledTile = styled("div")`
    ${tileParticipationStyle}
    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    border-radius: ${tileSettings.tileRadius};
    font-size: ${tileSettings.fontSize};
    color: ${(props: any) => props.theme.colors.tileColor};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  const MMMDD = timestampToMMMDD(participation.startDateTime);
  const HHMM = timestampToHHMM(participation.startDateTime);
  const EventDetailsUrl = `/event-details/${participation.eventId}/${participation.activityId}/pp`;

  const StyledNavLink = styled(NavLink)`
    color: ${(props: any) => props.theme.colors.onBackground};
    ${logoStyle};
  `;

  // const StyledParticipationsEventHearderTile = styled("div")`
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  // `;

  if (tileStyle === "wide" || tileStyle === "flat") {
    return (
      <div>
        <StyledNavLink to={EventDetailsUrl}>
          <StyledTile>
            <text>{participation.eventName}</text>
            <text>
              {MMMDD} {HHMM}
            </text>
          </StyledTile>
        </StyledNavLink>
      </div>
    );
  } else {
    return (
      <div>
        <StyledNavLink to={EventDetailsUrl}>
          <StyledTile>
            <text>{MMMDD}</text>
            <text>{HHMM}</text>
          </StyledTile>
        </StyledNavLink>
      </div>
    );
  }
}
