import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { getText } from '../../config/labels';
import { getNavigatorLanguage } from '../../language'
import { backgroundDivStyle, wrapperStyle } from '../../config/styles';

function EmptyScreen(props: any) {
  const { text, profile } = props;

  let userLanguage = getNavigatorLanguage();
  if (profile && profile.settings && profile.settings.language) {
    userLanguage = profile.settings.language;
  }
  const boxText = getText(text, userLanguage);

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle};
    background-color: ${(props: any) => props.theme.colors.background};
  `;

  const StyledH = styled("h2")`
    margin-bottom: 0px;
    margin-top: 60px;
    font-size: 70px;
    @media (max-width: 1280px) {
      font-size: 50px;
    }
    @media (max-width: 960px) {
      font-size: 40px;
    }
    @media (max-width: 640px) {
      margin-top: 80px;
      font-size: 35px;
    }
    background-color: ${(props: any) => props.theme.colors.background};
    color: ${(props: any) => props.theme.colors.onBackground};
    opacity: 0.15;
  `;

  const StyledWrapper = styled("div")`
    ${wrapperStyle}
  `;

  return (
    <div>
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledH>{boxText}</StyledH>
        </StyledWrapper>
      </StyledBackgroundDiv>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(EmptyScreen);
