import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import { imageStyle } from "../../config/styles";

import devicesImageDark from "../../img/devices-dark.png";
import devicesImageLight from "../../img/devices-light.png";
// import { ReactComponent as Devices } from '../../img/devicesDark.svg';

const StyledImage = styled("img")`
  ${imageStyle}
  border-radius: 2%
`;

const Devices = (props: any) => {
  const { profile } = props;

  let image = devicesImageLight;
  if (profile && profile.settings && profile.settings.theme) {
    switch (profile.settings.theme) {
      case "dark":
        image = devicesImageDark;
        break;
      default:
        break;
    }
  }
  console.log("showing image");
  return (
    <div>
      <StyledImage src={image} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Devices);
