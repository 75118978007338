export const enGettingStartedTexts = [
  {
    header: "Activities",
    text: "First create your activity.",
    icon: "activities",
    image: "Activity"
  },
  {
    header: "Participants",
    text:
      "You can then invite participants using their emailaddress or add them if they are already signed up.",
    icon: "participants",
    image: ""
  },
  {
    header: "Events",
    text: "Create events for your activity.",
    icon: "events",
    image: ""
  },
  {
    header: "Next",
    text:
      "Now you can easily report your absence or presence, see your upcoming events, send messages and have an overview of the presence accross activities. You can add, change, remove additional participants and events. ",
    icon: "next",
    image: ""
  },
  {
    header: "Do you like it?",
    text: "Share it with others!",
    icon: "share",
    image: ""
  }
];
