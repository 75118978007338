export const langNL = {
  "LANGUAGE": "NL",
  "activities": "Activiteiten",
  "activity-description": "Beschrijving",
  "activity-description-PH": "Korte beschrijving",
  "activity-main-location": "Standaard Locatie",
  "activity-name-PH": "Korte naam van de activiteit",
  "activity-name": "Naam activiteit",
  "add-activity": "Maak activiteit",
  "add-default-presence": "Standaard aanwezig",
  "add-event": "Voeg event toe",
  "add-user": "Voeg deelnemer toe",
  "address": "Adres",
  "address-PH": "Het volledige adres",
  "admin": "Activiteit beheerder",
  "bio-keywords": "Bio-Trefwoorden",
  "bio-keywords-to-find": "Wat trefwoorden zodat men je kan herkennen",
  "cancel": "Annuleer",
  "clear": "Maak leeg",
  "complete-profile": "Vul uw profielgegevens aan! Deze zijn nodig.",
  "confirm-delete": "Bevestig verwijderen",
  "confirm-delete-description": "Zeker weten?",
  "cookies":
    "Presyes gebruikt alleen cookies die absoluut noodzakelijk zijn voor zijn goede werking. Er wordt geen gebruiks- of verkeersinformatie opgeslagen. Door Presyes te gebruiken, accepteert u het gebruik van deze cookies.",
  "days": "dagen",
  "default-absent": "Standaard afwezig",
  "default-address-PH": "Het volledige adres",
  "default-address": "Standaard adres",
  "default-duration-hhmm": "Standaard duur van de activiteit in uu:mm",
  "default-duration": "Standaard duur",
  "default-location-PH":
    "Plek waar de activiteit meestal plaatsvind zoals een gebouw, kamer, park, veld...",
  "default-location": "Standaard locatie",
  "default-presence": "Standaard aanwezig",
  "default-presence-PH": "Gewenst minimum aantal deelnemers",
  "default-presence-limit": "Presentie minimum",
  "default-presence-limit-PH": "Gewenst minimum aantal deelnemers",
  "default-present": "Standaard aanwezig",
  "delete": "Verwijder",
  "delete-activity": "Verwijder activiteit",
  "delete-event": "Verwijder event uit activiteit",
  "delete-participant": "Verwijder deelnemer uit activiteit",
  "email": "Emailadres",
  "email-invalid": "Incorrect emailadres",
  "end": "Einde",
  "end-date": "Eind datum",
  "end-date-time-PH": "Eind datum en tijd",
  "end-date-time": "Eind datum en tijd",
  "end-time": "Eind tijd",
  "enter-email": "Emailadres invoeren aub",
  "enter-email-invite":
    "Niet gevonden - Voer nieuwe email in om uit te nodigen",
  "enter-firstname": "Voornaam invoeren",
  "enter-lastname": "Achternaam invoeren",
  "enter-password": "Wachtwoord invoeren aub",
  "enter-password-again": "Wachtwoord nog een keer invoeren",
  "event-details": "Event details",
  "event-name-PH": "Korte naam voor het event",
  "event-name": "Naam event",
  "event-name-please": "Voeg een event naam toe",
  "features": "Kenmerken en voordelen",
  "firstname": "Voornaam",
  "forgot-password": "Wachtwoord vergeten",
  "get-started": "Ga aan de slag",
  "hello": "Hallo",
  "how-to": "En hoe...",
  "incorrect-email": "Incorrect emailadres",
  "initials": "Initialen",
  "invite-to-presyes": "Nodig uit",
  "invited-to-presyes": "Je bent uitgenodigd voor Presyes",
  "is-absent": "is afwezig",
  "is-present": "is aanwezig",
  "lastname": "Achternaam",
  "location-PH":
    "Iets wat de locatie aanduid, zoals een gebouw, kamer, veld...",
  "location": "Locatie",
  "logout": "Uitloggen",
  "monitor-attendees": "Monitor aanwezigheid",
  "name": "Naam",
  "need-verification": "Bevestig je account met de toegezonden mail. Herstuur de verificatiemail of check je spambox als je de mail niet ontvangen hebt.",
  "new-activity": "Maak nieuwe activiteit",
  "new-event": "Voeg event toe",
  "new-participant": "Voeg deelnemer toe",
  "no-activities": "Nog geen activiteiten",
  "no-events": "Nog geen events",
  "no-messages": "Nog geen berichten",
  "no-users": "Geen gebruikers gevonden",
  "note-PH": "Korte notitie",
  "note": "Notitie",
  "notifications": "Notificaties",
  "or": "- of -",
  "participants": "Deelnemers",
  "password": "Wachtwoord",
  "password-minimum": "Wachtwoorden zijn minimaal 6 karakters",
  "presence": "Presentie",
  "presence-limit-PH": "Gewenst minimum aantal deelnemers",
  "presence-limit": "Presentie minimum",
  "presence-management": "Presentie Management",
  "presence-manager": "Presentie Manager",
  "presence-total": "Presentie totaal",
  "profile": "Profiel",
  "repeat-password": "Herhaal wachtwoord",
  "password-same": "Wachtwoorden zijn niet identiek",
  "resend-verification": "Verstuur nieuwe verificatie email",
  "reset-password": "Wijzig wachtwoord",
  "reset-password-request": "Stuur mij een wijzig wachtwoord email",
  "reset?": "Nieuw wachtwoord aanmaken?",
  "role": "Rol",
  "role-function-task": "Rol, functie, taak",
  "same-passwords": "Wachtwoorden zijn niet identiek",
  "saved": "Opgeslagen",
  "save-profile": "Bewaar profiel",
  "save-settings": "Bewaar instellingen",
  "search-user-PH": "Zoek op voornaam, achternaam of voer email in",
  "search-user": "Zoek deelnemer",
  "see-how": "Bekijk hier hoe",
  "select-enter-invite": "Selecteer of geef nieuwe email om uit te nodigen",
  "settings": "Instellingen",
  "signin": "Log In",
  "signin-google": "Log In met Google",
  "signup": "Registreer",
  "signup-google": "Registreer met Google",
  "start": "Start",
  "start-date-time-PH": "Start datum en tijd *",
  "start-date-time": "Start datum en tijd *",
  "submit": "Submit",
  "support-only": "Supporter, volger, staf, anders ",
  "this-week": "deze week",
  "today": "vandaag",
  "tomorrow": "morgen",
  "upcoming": "Aankomend",
  "update-activity": "Wijzig activiteit",
  "update-event": "Wijzig event",
  "update-participant": "Wijzig deelnemer",
  "user-added": "Deelnemer toegevoegd",
  "user-added-invited": "Deelnemer toegevoegd of uitgenodigd",
  "using-presyes": "Starten met presYes",
  "within-one-week": "binnen een week"
};
