import { endOfYesterdayTimestamp } from "../../utils";
import {
  createOccurrenceForParticipation,
  createOccurrenceForEvent
} from "../../utils/history";
import { addOccurrence } from "./historyActions";

export const addEvent = (auth, profile, event) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection("events")
      .doc(event.eventId)
      .set({
        ...event,
        createdAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        const type = "add";
        const occurrence = createOccurrenceForEvent(
          type,
          auth,
          profile,
          event
        );
        // dispatch something to history
        dispatch(addOccurrence(occurrence));
      })
      .then(() => {
        dispatch({
          type: "ADD_EVENT_SUCCESS"
        });
      })
      .catch(err => {
        dispatch(
          {
            type: "ADD_EVENT_ERROR"
          },
          err
        );
      });
  };
};

export const getEvent = eventId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundEvents = [];
    firestore
      .collection("events")
      .where("eventId", "==", `${eventId}`)
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const event = JSON.parse(JSON.stringify(doc.data()));
          foundEvents.push(event);
        });
      })
      .then(() => {
        dispatch({
          event: foundEvents[0],
          type: "GET_EVENT_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_EVENT_ERROR" }, err);
      });
  };
};

export const updateEvent = (auth, profile, event) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection("events")
      .doc(`${event.eventId}`)
      .update({
        ...event,
        changedAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        const type = "update";
        const occurrence = createOccurrenceForEvent(type, auth, profile, event);
        // dispatch something to history
        dispatch(addOccurrence(occurrence));
      })
      .then(() => {
        dispatch({ type: "UPDATE_EVENT_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_EVENT_ERROR" }, err);
      });
  };
};

export const deleteEvent = eventId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("events")
      .doc(`${eventId}`)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_EVENT_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "DELETE_EVENT_ERROR" }, err);
      });
  };
};

export const getEventParticipations = eventId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundParticipations = [];

    firestore
      // get informations of the (future) events of an activity
      .collectionGroup("participations")
      .where("eventId", "==", `${eventId}`)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const participation = JSON.parse(JSON.stringify(doc.data()));
          foundParticipations.push(participation);
        });
      })
      .then(() => {
        dispatch({
          participations: foundParticipations,
          type: "GET_EVENT_PARTICIPATIONS_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_EVENT_PARTICIPATIONS_ERROR" }, err);
      });
  };
};

export const getEventParticipation = (eventId, userId) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundParticipations = [];

    firestore
      // get informations of the (future) events of an activity
      .collectionGroup("participations")
      .where("eventId", "==", `${eventId}`)
      .where("userId", "==", `${userId}`)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const participation = doc.data();
          foundParticipations.push(participation);
        });
      })
      .then(() => {
        dispatch({
          participation: foundParticipations[0],
          type: "GET_PARTICIPATION_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_PARTICIPATION_ERROR" }, err);
      });
  };
};

export const updateParticipation = (auth, profile, participation) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection("events")
      .doc(`${participation.eventId}`)
      .collection("participations")
      .doc(`${participation.participationId}`)
      .update({
        ...participation,
        changedAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        const type = "update";
        const occurrence = createOccurrenceForParticipation(
          type,
          auth,
          profile,
          participation
        );
        // dispatch something to history
        dispatch(addOccurrence(occurrence));
      })
      .then(() => {
        dispatch({ type: "UPDATE_PARTICIPATION_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_PARTICIPATION_ERROR" }, err);
      });
  };
};

export const getUpcomingEvents = userId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let foundEvents = [];

    firestore
      // get informations of the participations of the user
      .collectionGroup("participations")
      .where("userId", "==", `${userId}`)
      .where("startDateTime", ">", endOfYesterdayTimestamp())
      .orderBy("startDateTime")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const myParticipation = doc.data();
          const myEventId = myParticipation.eventId;
          firestore
            .collection("events")
            .where("eventId", "==", `${myEventId}`)
            .get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                const event = JSON.parse(JSON.stringify(doc.data()));
                foundEvents.push(event);
              });
            })
            .then(() => {
              dispatch({
                events: foundEvents,
                type: "GET_UPCOMING_EVENTS_SUCCESS"
              });
            });
        });
      })
      .catch(err => {
        dispatch({ type: "GET_UPCOMING_EVENTS_ERROR" }, err);
      });
  };
};

export const clearEventState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_EVENT_STATE"
    });
  };
};
