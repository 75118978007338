export const textsNL = {
  LANGUAGE: "NL",
  addEvent: "Voeg events toe aan jouw activiteit.",
  addNewActivity: "Ben je nieuw? Ga naar ACTIVITEITEN en maak een nieuwe activiteit aan.",
  addParticipant: "Voeg dan deelnemers toe aan jouw activiteit.",
  ftCommunicate: "Communiceer met andere deelnemers over het event",
  ftDevices: "Gebruik het toestel waarover je op dat moment beschikt of die je het makkelijkst vind.",
  ftKnow:
    "Iedereen weet van te voren wie aanwezig en afwezig is bij alle events.",
  ftManageEvents: "Beheer de aanwezigheid bij jouw sociale groepsactiviteiten.",
  ftNext: "Bekijk snel je volgende events.",
  ftNotify: "Meld je afwezigheid of aanwezigheid met een druk op de knop.",
  inviteParticipant:
    "Of nodig ze uit via email te registreren bij presYes en toegevoegd te worden aan de activiteit.",
  presOverview: "Krijg nu inzicht in de opkomst.",
  reportAbsence: "Laat deelnemers hun aanwezigheid of afwezigheid doorgeven."
};
