import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

// import ParticipationTile from "../participations/ParticipationTile";

import Typography from "@material-ui/core/Typography";
// import More from "@material-ui/icons/More";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";

import styled from "styled-components";
import {
  logoStyle,
  listItemHeaderTextStyle,
  listItemIconStyle,
  listItemFieldTextStyle
} from "../../config/styles";

// import Label from '../layout/Label';
import { getEventParticipation } from "../../store/actions/eventActions";
import { getLabel } from "../../config/labels";
import {
  timestampToDateString,
  getDaysToGoFromTimestamp,
  getDayStringFromTimestamp,
  getDayLabel
} from "../../utils";

const StyledField = styled(Typography)`
  ${listItemFieldTextStyle};
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const StyledListItemText = styled(ListItemText)`
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const StyledDayField = styled(Typography)`
  ${listItemFieldTextStyle};
  float: right;
  margin-top: 0px;
  margin-bottom: -10px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${(props: any) => props.theme.colors.background};
  margin-bottom: 1px;
  opacity: 1;
`;

const StyledHeader = styled(Typography)`
  ${listItemHeaderTextStyle};
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.onBackground};
  ${logoStyle};
`;

// const StyledEditIcon = styled(EditIcon)`
//   ${listItemIconStyle}
// `;

const StyledMoreIcon = styled(ArrowForwardIosIcon)`
  ${listItemIconStyle}
  margin-top: 20px;
`;

// const StyledActionEdit = styled(ListItemSecondaryAction)`
//   margin-top: 5px;
//   width: 90px;
// `;
const StyledActionArrow = styled(ListItemSecondaryAction)`
  width: 50px;
`;

function UpcomingEventListItem(props: any) {
  const { profile, event } = props;
  const EventDetailsUrl = `/event-details/${event.eventId}/${event.activityId}`;

  if (event) {
    let userLanguage = window.navigator.language;
    if (profile && profile.settings && profile.settings.language) {
      userLanguage = profile.settings.language;
    }
    const start = timestampToDateString(event.startDateTime)

    const daysToGo = getDaysToGoFromTimestamp(event.startDateTime);
    let dayString = `+ ${daysToGo} ${getLabel("days", userLanguage)}`;
    const day = getDayStringFromTimestamp(event.startDateTime);
    if (day !== "-") {
      dayString = getDayLabel(day, userLanguage);
    }

    const location = event.eventLocation ? (
      <StyledField>
        {getLabel("location", userLanguage)}
        {": "}
        {event.eventLocation}
      </StyledField>
    ) : (
      <div></div>
    );

    const startDateTime = <StyledField>{`${start}`}</StyledField>;

    const listDetails = (
      <div>
        <StyledHeader>{event.eventName}</StyledHeader>
        {startDateTime}
        {location}
      </div>
    );

    return (
      <div>
        <StyledDayField> {dayString}</StyledDayField>
        <StyledNavLink to={EventDetailsUrl}>
          <ListItem>
            <StyledListItemText children={listDetails} />
            <StyledActionArrow>
              <StyledMoreIcon />
            </StyledActionArrow>
          </ListItem>
        </StyledNavLink>
        <StyledDivider />
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    participation: state.event.participation
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventParticipation: (eventId: string, uid: string) =>
      dispatch(getEventParticipation(eventId, uid))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingEventListItem);
