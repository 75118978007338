const initState = {
  activities: null,
  activity: null,
  activityAdmins: null,
  activityError: null,
  events: null,
  eventsError: null,
  participants: null,
  participantsError: null,
  participations: null,
  participationsError: null
};

const activityReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ACTIVITIES_FROM_USER_ERROR":
      return {
        ...state,
        activityError: "Getting activities from user failed"
      };

    case "GET_ACTIVITIES_FROM_USER_SUCCESS":
      return {
        ...state,
        activities: action.activities,
        activityError: null
      };

    case "GET_ACTIVITY_ERROR":
      return {
        ...state,
        activityError: "Getting activity failed"
      };

    case "GET_ACTIVITY_SUCCESS":
      return {
        ...state,
        activity: action.activity,
        activityError: null
      };

    case "ADD_ACTIVITY_ERROR":
      return {
        ...state,
        activityError: "Adding activity failed"
      };

    case "ADD_USER_TO_ACTIVITY_ERROR":
      return {
        ...state,
        activityError: "Adding user to activity failed"
      };

    case "ADD_USER_TO_ACTIVITY_SUCCESS":
      return {
        ...state,
        activityError: null
      };

    case "UPDATE_ACTIVITY_ERROR":
      return {
        ...state,
        activityError: "Updating activity failed"
      };

    case "UPDATE_ACTIVITY_SUCCESS":
      return {
        ...state,
        activityError: null
      };

    case "DELETE_ACTIVITY_ERROR":
      return {
        ...state,
        activityError: "Deleting activity failed"
      };

    case "DELETE_ACTIVITY_SUCCESS":
      return {
        ...state,
        activityError: null
      };

    case "GET_ACTIVITY_EVENTS_ERROR":
      return {
        ...state,
        eventsError: "Getting events from activity failed"
      };

    case "GET_ACTIVITY_EVENTS_SUCCESS":
      return {
        ...state,
        events: action.events,
        eventsError: null
      };

    case "GET_ACTIVITY_PARTICIPANTS_ERROR":
      return {
        ...state,
        participantsError: "Getting participants from activity failed"
      };

    case "GET_ACTIVITY_PARTICIPANTS_SUCCESS":
      return {
        ...state,
        participants: action.participants,
        activityAdmins: action.activityAdmins,
        participantsError: null
      };

    case "GET_ACTIVITY_PARTICIPATIONS_ERROR":
      return {
        ...state,
        participationsError: "Getting participations from activity failed"
      };

    case "GET_ACTIVITY_PARTICIPATIONS_SUCCESS":
      return {
        ...state,
        participations: action.participations,
        participationsError: null
      };

    case "CLEAR_ACTIVITY_STATE":
      return {
        ...state,
        activities: null,
        activity: null,
        activityAdmins: null,
        activityError: null,
        events: null,
        eventsError: null,
        participants: null,
        participantsError: null,
        participations: null,
        participationsError: null
      };

    default:
      return state;
  }
};

export default activityReducer;
