import React from "react";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import {
  getEventMessages,
  clearMessageState
} from "../../store/actions/messageActions";
import Message from "./Message";
import AddMessageBar from "./AddMessageBar";

import styled from "styled-components";
// import Label from "../layout/Label";
// import { getLabel } from "../../config/labels";
import {
  cardStyle,
  wrapperStyle
} from "../../config/styles";
// import { devLog } from "../../utils";
// import { getValueFromField, setValueOfField } from "../../utils/htmlUtils";
// import { devLog } from "../../utils";

function MessagesCard(props: any) {
  const {
    event,
    auth,
    profile,
    eventMessages,
    getEventMessages,
    clearMessageState
  } = props;
  const { uid } = auth;
  const { eventId } = event;
  const [getMessages, setGetMessages] = React.useState(true);

  React.useEffect(() => {
    if (uid && eventId && getMessages) {
      getEventMessages(eventId);
      setGetMessages(false);
    }
    return () => {
      clearMessageState();
    };
  }, [
    uid,
    eventId,
    getEventMessages,
    getMessages,
    setGetMessages,
    event,
    clearMessageState
  ]);

  if (auth && profile) {
    const messages =
      eventMessages && eventMessages.length > 0 ? (
        <StyledWrapper>
          <StyledMessagesCard>
            <AddMessageBar event={event} setGetMessages={setGetMessages} />
            <List>
              {eventMessages.map((message: any) => (
                <div>
                  <Message message={message} />
                  <StyledDivider />
                </div>
              ))}
            </List>
          </StyledMessagesCard>
        </StyledWrapper>
      ) : (
        <StyledWrapper>
          <StyledMessagesCard>
            <AddMessageBar event={event} setGetMessages={setGetMessages} />
          </StyledMessagesCard>
        </StyledWrapper>
      );

    return <div>{messages}</div>;
  } else return <div />;
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    eventMessages: state.message.eventMessages
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventMessages: (eventId: string) => dispatch(getEventMessages(eventId)),
    clearMessageState: () => dispatch(clearMessageState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesCard);

//// Styled Components ****************
const StyledBaseCard = styled(Card)`
  ${cardStyle};
  padding-left: 60px;
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
  &&&&& {
    padding-top: 15px;
  }
`;
const StyledDivider = styled(Divider)`
  background-color: ${(props: any) => props.theme.colors.onBackground};
  margin-bottom: 0px;
  opacity: 0.1;
`;
const StyledMessagesCard = styled(StyledBaseCard)`
  @media (min-width: 641px) {
    margin-bottom: 20px;
  }
  @media (max-width: 640px) {
    margin-bottom: 60px;
  }
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;
