import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import { cardStyle, wrapperStyle } from '../../config/styles';

export default function StyledBaseCard(props: any) {
  const BaseCard = styled(Card)`
    ${cardStyle}
    color: ${(props: any) => props.theme.colors.onBackground};
  `;

  const StyledWrapper = styled('div')`
    ${wrapperStyle}
  `;

  return (
    <div>
      <StyledWrapper>
        <BaseCard>{props.node}</BaseCard>
      </StyledWrapper>
    </div>
  );
}
