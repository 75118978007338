import axios from "axios";
import { FIREBASE } from "../../config/settings";
import { getEmailBody } from "../../utils/mail";
import { testLog } from "../../utils";

export const sendTestMail = email => {
  return async dispatch => {
    await axios
      .get(
        `https://europe-west1-presyes-dev.cloudfunctions.net/reqSendTestMail?email=${email}`
      )
      .then(response => {
        testLog("sendTestMail - result ", response);
        dispatch({ type: "SENDMAIL_SUCCESS" });
      })
      .catch(function(err) {
        testLog("sendTestMail - error ", err);
        dispatch({ type: "SENDMAIL_ERROR", err });
      });
  };
};

export const reqInvitationToActivityMail = (participant, inviter) => {
  testLog("reqInvitationToActivityMail - inviter ", inviter);
  const url = `https://europe-west1-presyes-${FIREBASE}.cloudfunctions.net/reqSendInvitationMail`;

  const body = getEmailBody(participant, inviter);

  testLog("reqInvitationToActivityMail - body ", body);

  return async dispatch => {
    await axios
      .post(url, body)
      .then(response => {
        testLog("send Invitation Mail - result ", response);
        dispatch({ type: "SENDMAIL_SUCCESS" });
      })
      .catch(function(err) {
        testLog("sendInvitation Mail - error ", err);
        dispatch({ type: "SENDMAIL_ERROR", err });
      });
  };
};
