import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import Lens from "@material-ui/icons/Lens";
import ReplyIcon from "@material-ui/icons/Reply";

import styled from "styled-components";
import {
  cardIconStyle,
  messageHeaderStyle,
  messageTextStyle,
  navLinkStyle
} from "../../config/styles";

// import Label from '../layout/Label';
import { getEventParticipation } from "../../store/actions/eventActions";
// import { getLabel } from "../../config/labels";
import { timestampToDateStringShort } from "../../utils";

function Message(props: any) {
  const { auth, profile, message, showReply, setShowAddMessageBar } = props;

  if (auth && profile && message) {

    const handleReply = () => {
      setShowAddMessageBar(true)
    }

    let dateTime = "";
    if (message.messageDateTime) {
      dateTime = timestampToDateStringShort(message.messageDateTime.seconds);
    }

    let newMessage = false;
    if (profile && profile.previousMessageRead) {
      const diff =
        message.messageDateTime.seconds - profile.previousMessageRead.seconds;
      const othersMessage = message.senderId !== auth.uid ? true : false;
      newMessage = diff > 0 && othersMessage ? true : false;
    }
    const EventDetailsUrl = `/event-details/${message.eventId}/${message.activityId}`;

    const newIcon = newMessage ? <StyledLensIcon /> : <div />;

    const avatar =
      message.senderId === auth.uid ? (
        <StyledMyAvatar>{message.senderInitials}</StyledMyAvatar>
      ) : (
        <StyledAvatar>{message.senderInitials}</StyledAvatar>
      );

    const replyIcon = showReply ? (
      <StyledReplyIcon onClick={handleReply} />
    ) : (
      <div />
    );

    const messageDetails = (
      <div>
        <StyledHeaderField>
          {message.senderDisplayName}
          {" - "}
          {dateTime}
        </StyledHeaderField>
        <StyledSubHeaderField>
          {message.eventName} {" - "}
          {message.activityName}
        </StyledSubHeaderField>
        <StyledField>{message.messageText}</StyledField>
      </div>
    );

    return (
      <div>
        <StyledDisplayInline>
          <ListItem>
            {avatar}
            {newIcon}
            <StyledNavLink to={EventDetailsUrl}>
              <StyledListItemText children={messageDetails} />
            </StyledNavLink>
          </ListItem>
          {replyIcon}
        </StyledDisplayInline>
      </div>
    );
  } else {
    return <div />;
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    participation: state.event.participation
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventParticipation: (eventId: string, uid: string) =>
      dispatch(getEventParticipation(eventId, uid))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);

const messageAvatarStyle = `
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 800;
`;

const StyledAvatar = styled(Avatar)`
  ${messageAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.messageAvatar};
`;
const StyledLensIcon = styled(Lens)`
  color: red;
  width: 10px;
  height: 10px;
  margin-bottom: 20px;
`;

const StyledMyAvatar = styled(Avatar)`
  ${messageAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.messageMyAvatar};
`;
const StyledField = styled(Typography)`
  ${messageTextStyle};
  margin-left: 25px;
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledHeaderField = styled(Typography)`
  ${messageHeaderStyle};
  margin-left: 25px;
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledReplyIcon = styled(ReplyIcon)`
  ${cardIconStyle}
  margin-top: 10px;
  margin-left: 15px;
  opacity: 0.3;
`;
const StyledSubHeaderField = styled(Typography)`
  ${messageHeaderStyle};
  font-style: italic;
  margin-left: 25px;
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledListItemText = styled(ListItemText)`
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledDisplayInline = styled("span")`
  display: inline-flex;
  cursor: pointer;
  cursor: hand;
`;
const StyledNavLink = styled(NavLink)`
  ${navLinkStyle};
  cursor: pointer;
  cursor: hand;
`;