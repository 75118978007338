import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ViewIcon from "@material-ui/icons/ViewComfy";

import PresOverviewImage from "../../img/pres-overview.png";
import PresOverviewImageMobile from "../../img/pres-overview-mobile.png";

import {
  iconGettingStartedStyle,
  instructionAvatarStyle,
  instructionImageStyle,
  instructionTextStyle,
  instructionTextWrapper
} from "../../config/styles";

import { getInstructionText } from "../../language";

const StyledAvatar = styled(Avatar)`
  ${instructionAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledTextWrapper = styled("div")`
  ${instructionTextWrapper}
`;
const StyledImage = styled("img")`
  ${instructionImageStyle}
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledViewIcon = styled(ViewIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;

export default function PresOverview(props: any) {
  const { language, mobileSize } = props;

  if (language) {
    const txt = getInstructionText("presOverview", language);

    const txtBlock = (
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledViewIcon />
        </StyledAvatar>
        <StyledText>{txt}</StyledText>
      </StyledTextWrapper>
    );

    const blockToShow = mobileSize ? (
      <div>
        {txtBlock}
        <StyledImage src={PresOverviewImageMobile} />
      </div>
    ) : (
      <div>
        {txtBlock}
        <StyledImage src={PresOverviewImage} />
      </div>
    );

    return <div>{blockToShow}</div>;
  } else return null;
}
