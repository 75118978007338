import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/EditOutlined";
// import More from "@material-ui/icons/More";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import styled from "styled-components";
import {
  logoStyle,
  cardDayFieldStyle,
  cardFieldTextStyle,
  cardHeaderTextStyle,
  cardIconStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

// import Label from '../layout/Label';
import { getLabel } from "../../config/labels";
import {
  timestampToDateString,
  getDayStringFromTimestamp,
  getDaysToGoFromTimestamp,
  getDayLabel
} from "../../utils";

const StyledBaseCard = styled(Card)`
  ${cardStyle};
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
  &&& {
    padding-top: 0px;
    padding-bottom: 10px;
  }
`;

// const StyledButton = styled(Button)`
//   float: right;
//   color: ${(props: any) => props.theme.colors.onBackground};
// `;

const StyledEditIcon = styled(EditIcon)`
  ${cardIconStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  padding-left: 30px;
`;

const StyledMoreIcon = styled(ArrowForwardIosIcon)`
  ${cardIconStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  padding-right; 20px;
  float: right;
`;

const StyledField = styled(Typography)`
  ${cardFieldTextStyle};
`;

const StyledDayField = styled(Typography)`
  ${cardDayFieldStyle};
  float: right;
`;

const StyledHeader = styled(Typography)`
  ${cardHeaderTextStyle};
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.onBackground};
  ${logoStyle};
`;

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;

function EventCard(props: any) {
  const { auth, profile, activityAdmins, event } = props;
  const EditEventUrl = `/edit-event/${event.eventId}/${event.activityId}`;
  const EventDetailsUrl = `/event-details/${event.eventId}/${event.activityId}`;

  let userLanguage = window.navigator.language;
  if (profile && profile.settings && profile.settings.language) {
    userLanguage = profile.settings.language;
  }

  if (event) {
    const start = timestampToDateString(event.startDateTime);
    const end = timestampToDateString(event.endDateTime);

    const daysToGo = getDaysToGoFromTimestamp(event.startDateTime);
    let dayString = `+ ${daysToGo} ${getLabel("days", userLanguage)}`;
    const day = getDayStringFromTimestamp(event.startDateTime);
    if (day !== "-") {
      dayString = getDayLabel(day, userLanguage);
    }

    let activityAdmin = false;
    if (activityAdmins && activityAdmins.includes(auth.uid)) {
      activityAdmin = true;
    }

    const nav = activityAdmin ? (
      <div>
        <StyledNavLink to={EditEventUrl}>
          <StyledEditIcon />
        </StyledNavLink>
      </div>
    ) : (
      <div></div>
    );

    const endsAt = event.endDateTime ? (
      <div>
        <StyledField>
          {getLabel("end", userLanguage)}
          {": "}
          {end}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );

    const location = event.eventLocation ? (
      <div>
        <StyledField>
          {getLabel("location", userLanguage)}
          {": "}
          {event.eventLocation}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );

    const address = event.eventAddress ? (
      <div>
        <StyledField>
          {getLabel("address", userLanguage)}
          {": "}
          {event.eventAddress}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );
    const presenceLimit = event.presenceLimit ? (
      <div>
        <StyledField>
          {getLabel("presence-limit", userLanguage)}
          {": "}
          {event.presenceLimit}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );
    const presenceTotal = event.presenceTotal ? (
      <div>
        <StyledField>
          {getLabel("presence-total", userLanguage)}
          {": "}
          {event.presenceTotal}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );
    const note = event.note ? (
      <div>
        <StyledField component="h2">Note: {event.note}</StyledField>
      </div>
    ) : (
      <div></div>
    );

    return (
      <div>
        <StyledWrapper>
          <StyledBaseCard>
            <StyledDayField>{dayString}</StyledDayField>
            <StyledNavLink to={EventDetailsUrl}>
              <CardContent>
                <StyledHeader>{event.eventName}</StyledHeader>
                <StyledField>
                  {getLabel("start", userLanguage)}
                  {": "}
                  {start}
                </StyledField>
                <StyledMoreIcon />
                {endsAt}
                {location}
                {address}
                {presenceLimit}
                {presenceTotal}
                {note}
              </CardContent>
            </StyledNavLink>
            {nav}
          </StyledBaseCard>
        </StyledWrapper>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activityAdmins: state.activity.activityAdmins
  };
};

export default connect(mapStateToProps)(EventCard);
