import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  getActivityParticipants,
  clearActivityState
} from "../../store/actions/activityActions";
import ParticipantCard from "../participants/ParticipantCard";
import StyledAddFab from "../layout/StyledAddFab";
import CreateNewCard from "../layout/CreateNewCard";

import styled from "styled-components";
import { getHeight, backgroundDivStyle } from "../../config/styles";

import { Participant } from "../../config/types";
// import { consLog } from ;

function ActivityParticipants(props: any): JSX.Element {
  const {
    auth,
    activityAdmins,
    participants,
    match,
    getActivityParticipants,
    clearActivityState
  } = props;
  const { uid } = auth;
  const { activityId } = match.params;

  const addParticipantUrl = `/add-participant/${match.params.activityId}`;

  useEffect(() => {
    if (uid && activityId) {
      getActivityParticipants(activityId);
    }
    return () => {
      clearActivityState();
    };
  }, [uid, activityId, getActivityParticipants, clearActivityState]);

  let activityAdmin = false;
  if (auth.uid && activityAdmins && activityAdmins.includes(auth.uid)) {
    activityAdmin = true;
  }
  const fab = activityAdmin ? (
    <div>
      <StyledAddFab url={addParticipantUrl} />;
    </div>
  ) : (
    <div></div>
  );
  const newCard = activityAdmin ? (
    <div>
      <CreateNewCard label="new-participant" url={addParticipantUrl} />
    </div>
  ) : (
    <div></div>
  );

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  } else if (participants && participants.length > 0) {
    return (
      <StyledBackgroundDiv>
        {participants.map((participant: Participant) => (
          <ParticipantCard
            key={participant.participantId}
            participant={participant}
          />
        ))}
        {newCard}
        {fab}
      </StyledBackgroundDiv>
    );
  } else {
    return (
      <StyledBackgroundDiv>
        {newCard}
        {fab}
      </StyledBackgroundDiv>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    activityAdmins: state.activity.activityAdmins,
    participants: state.activity.participants
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivityParticipants: (activityId: string) =>
      dispatch(getActivityParticipants(activityId)),
    clearActivityState: () => dispatch(clearActivityState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParticipants);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px
    & {
      margin-bottom: 58px;
    }
  `;
