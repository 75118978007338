import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

import AddActivityMobileImage from "../../img/add-new-activity-mobile.png";
import AddActivityImage from "../../img/add-new-activity.png";

import {
  iconGettingStartedStyle,
  instructionAvatarStyle,
  instructionImageStyle,
  instructionTextStyle,
  instructionTextWrapper
} from "../../config/styles";

import { getInstructionText } from "../../language";

const StyledAvatar = styled(Avatar)`
  ${instructionAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledImage = styled("img")`
  ${instructionImageStyle}
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledIcon = styled(GroupWorkIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledTextWrapper = styled("div")`
  ${instructionTextWrapper}
  margin-top: 40px;
`;

export default function AddActivity(props: any) {
  const { language, mobileSize } = props;

  const imageToShow = mobileSize ? (
    <StyledImage src={AddActivityMobileImage} />
  ) : (
    <StyledImage src={AddActivityImage} />
  );

  const instruction = "addNewActivity";
  if (language) {
    const txt = getInstructionText(instruction, language);
    const txtBlock = (
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledIcon />
        </StyledAvatar>
        <StyledText>{txt}</StyledText>
      </StyledTextWrapper>
    );

    return (
      <div>
        {txtBlock}
        {imageToShow}
      </div>
    );
  } else return null;
}
