import uuidv1 from "uuid/v1";
import {
  getUnixTime,
  fromUnixTime,
  parse,
  format,
  differenceInCalendarDays,
  endOfYesterday,
  isToday,
  isTomorrow,
  isThisWeek
} from "date-fns";
import { getValueFromField, setValueOfField } from "./htmlUtils";
import { getLabel } from "../config/labels";
// import _ from 'lodash';
import {
  DEV,
  PROD,
  TEST,
} from "../config/settings";

export const getId = (prefix: string): string => {
  if (prefix) return prefix + uuidv1();
  else return uuidv1();
};

export const consLog = (
  traceReason: string,
  callingFunction: any,
  object: any,
  borders: boolean
): void => {
  if (DEV) {
    if (borders) {
      console.log(`------------start--${new Date()}------------------------`);
    }
    console.log(
      "=>",
      traceReason,
      "(",
      new Date(),
      ") - ",
      `From ${callingFunction}: `,
      object
    );
    if (borders) {
      console.log(
        `--------------end--${new Date()}---------------------------`
      );
    }
  } else if (PROD) {
    console.log(
      "=>",
      traceReason,
      "(",
      new Date(),
      ") - ",
      `From ${callingFunction}: `,
      object
    );
  }
};

export const devLog = (...params: any) => {
  if (DEV) {
    if (params[4]) {
      console.log(params[0], params[1], params[2], params[3], params[4]);
    } else if (params[3]) {
      console.log(params[0], params[1], params[2], params[3]);
    } else if (params[2]) {
      console.log(params[0], params[1], params[2]);
    } else if (params[1]) {
      console.log(params[0], params[1]);
    } else if (params[0]) {
      console.log(params[0]);
    }
  }
};

export const testLog = (...params: any) => {
  if (TEST) {
    if (params[4]) {
      console.log(params[0], params[1], params[2], params[3], params[4]);
    } else if (params[3]) {
      console.log(params[0], params[1], params[2], params[3]);
    } else if (params[2]) {
      console.log(params[0], params[1], params[2]);
    } else if (params[1]) {
      console.log(params[0], params[1]);
    } else if (params[0]) {
      console.log(params[0]);
    }
  }
};

// ************ DATE FUNCTIONS **************************************************

export const logCurrentDateTime = (...params: any) => {
  const now = format(new Date(), "MMM-dd yyyy HH:mm");

  if (params[4]) {
    console.log(params[0], params[1], params[2], params[3], params[4], now);
  } else if (params[3]) {
    console.log(params[0], params[1], params[2], params[3], now);
  } else if (params[2]) {
    console.log(params[0], params[1], params[2], now);
  } else if (params[1]) {
    console.log(params[0], params[1], now);
  } else if (params[0]) {
    console.log(params[0], now);
  }
};

export const dateStringToTimestamp = (dateString: string): number => {
  if (dateString) {
    const date = parse(dateString, "MM-dd-yyyy HH:mm", new Date());
    const timestamp = getUnixTime(date);
    return timestamp;
  } else {
    return 0;
  }
};

export const endOfYesterdayTimestamp = (): number => {
  return getUnixTime(endOfYesterday());
};

export const dateStringToDate = (dateString: string): any => {
  if (dateString) {
    const date = parse(dateString, "MM-dd-yyyy HH:mm", new Date());
    return date;
  } else {
    return 0;
  }
};

export const timestampToDateString = (timestamp: number): string => {
  if (typeof timestamp === "number" && !isNaN(timestamp)) {
    const dateTime = timestamp ? fromUnixTime(timestamp) : 0;
    const dateString = format(dateTime, "MMM-dd yyyy HH:mm");
    return dateString;
  } else return "";
};

export const timestampToDateStringShort = (timestamp: number): string => {
  if (typeof timestamp === "number" && !isNaN(timestamp)) {
    const dateTime = timestamp ? fromUnixTime(timestamp) : 0;
    const dateString = format(dateTime, "MMM-dd HH:mm");
    return dateString;
  } else return "";
};

export const timestampToDate = (timestamp: number): any => {
  const dateTime = fromUnixTime(timestamp);
  return dateTime;
};

export const getDaysToGoFromTimestamp = (timestamp: number): number => {
  const start = fromUnixTime(timestamp);
  const now = new Date();
  const days = differenceInCalendarDays(start, now);
  return days;
};

export const getDayStringFromTimestamp = (timestamp: number): string => {
  const start = fromUnixTime(timestamp);

  let dayString = "dayString";
  if (isToday(start)) {
    dayString = "today";
  } else if (isTomorrow(start)) {
    dayString = "tomorrow";
  } else if (isThisWeek(start)) {
    dayString = "this-week";
  } else {
    dayString = "-";
  }
  return dayString;
};

export const getDayLabel = (day: string, language: string): string => {
  let dayLabel: string = "";
  switch (day) {
    case "today":
      dayLabel = `${getLabel("today", language)}`;
      break;
    case "tomorrow":
      dayLabel = `${getLabel("tomorrow", language)}`;
      break;
    case "one-week":
      dayLabel = `${getLabel("within-one-week", language)}`;
      break;
    case "this-week":
      dayLabel = `${getLabel("this-week", language)}`;
      break;
    case "yesterday":
      dayLabel = `${getLabel("yesterday", language)}`;
      break;
    case "last-week":
      dayLabel = `${getLabel("last-week", language)}`;
      break;
    default:
      break;
  }
  return dayLabel;
};

export const timestampToMMMDD = (timestamp: number): string => {
  if (typeof timestamp === "number" && !isNaN(timestamp)) {
    const dateTime = fromUnixTime(timestamp);
    const MDString = format(dateTime, "MMM-dd");
    return MDString;
  } else return "";
};

export const timestampToHHMM = (timestamp: number): string => {
  if (typeof timestamp === "number" && !isNaN(timestamp)) {
    const dateTime = fromUnixTime(timestamp);
    const HHMMString = format(dateTime, "HH-mm");
    return HHMMString;
  } else return "";
};

export const getIdDatePart = (): string => {
  const now = new Date();
  const datePart = format(now, "yyyy-MMdd-HHmm-ssSSSS");
  return datePart;
};

// ****************************************************************************

export const objToArrayOfObjects = (obj: any) =>
  Object.keys(obj).map(i => obj[i]);

export const sortArrayOfObjectsByProperty = (arr: any, property: any) => {
  arr.sort((a: any, b: any) => (a[property] > b[property] ? 1 : -1));
  return arr;
};

export const convertParticipationsToParticipationsPerEvent = (
  participations: any
) => {
  if (participations) {
    let twoDimArray: any = [];
    let nr = -1;
    let id = "";
    participations.forEach((participation: any) => {
      if (participation.eventId !== id) {
        twoDimArray.push([]);
        nr++;
      }
      twoDimArray[nr].push(participation);
      id = participation.eventId;
    });

    return twoDimArray;
  }
};

export const convertParticipationsToParticipationsPerParticipant = (
  participations: any
) => {
  if (participations) {
    participations.sort((a: any, b: any) =>
      a.participantId > b.participantId ? 1 : -1
    );

    let twoDimArray: any = [];
    let nr = -1;
    let id = "";
    participations.forEach((participation: any) => {
      if (participation.participantId !== id) {
        twoDimArray.push([]);
        nr++;
      }
      twoDimArray[nr].push(participation);
      id = participation.participantId;
    });

    return twoDimArray;
  }
};

export const createInitials = (
  firstname: string,
  lastname: string,
  initials: string
): string => {
  const firstPart: string = getValueFromField(firstname).charAt(0);
  const lastPart: string = getValueFromField(lastname)
    .split(" ")
    .pop()
    .charAt(0);
  const initialsValue = firstPart + lastPart;
  setValueOfField(initials, initialsValue);
  return initialsValue;
};

export const createInitialsFromNames = (
  firstName: string,
  lastName: any
): string => {
  const firstPart: string = firstName.charAt(0);
  const lastPart: string = lastName
    .split(" ")
    .pop()
    .charAt(0);
  const initials = firstPart + lastPart;
  return initials;
};

export const createDisplayName = (
  firstName: string,
  lastName: string
): string => {
  let displayName = "";
  if (firstName && lastName) {
    displayName = `${firstName} ${lastName}`;
  } else if (firstName && !lastName) {
    displayName = `${firstName}`;
  } else if (!firstName && lastName) {
    displayName = `${lastName}`;
  }
  return displayName;
};

// export function removeFromArray(value: any, index: any, arr: any[], ) {
//   if (arr && value) {
//     return arr.filter(function(element: any) {
//       return element !== value;
//     });
//   }
// }

// export function removeFromArray(array: any[], valueToRemove: string) {

//   const newArray = array.filter(value => {
//     return value !== valueToRemove;
//   });
//   return newArray;
// }

export function addToArray(arr: Array<any>, value: any) {
  return arr.push(value);
}

const createUserSearchKeys = (name: string) => {
  let arrName: any = [];
  let curName = "";
  name.split("").forEach(letter => {
    curName += letter;
    if (curName.length > 2) {
      arrName.push(curName.toLowerCase());
    }
  });
  return arrName;
};

export const generateUserSearchKeys = (
  email: string,
  firstName: string,
  lastName: string
) => {
  const emailName = createUserSearchKeys(`${email}`);
  const firstlast = createUserSearchKeys(`${firstName} ${lastName}`);
  const lastfirst = createUserSearchKeys(`${lastName} ${firstName}`);
  const lastPart = lastName.split(" ").pop();
  const lastPartfirst = createUserSearchKeys(`${lastPart} ${firstName}`);
  return [...firstlast, ...lastfirst, ...lastPartfirst, ...emailName];
};
