// import { isMobile } from "ismobilejs";
import device from "current-device";

export const isMobile = () => {
  return device.mobile();
};

export function checkDeviceOS() {
  const useragent = navigator.userAgent;

  if (useragent.match(/Android/i)) {
    return "android";
  } else if (useragent.match(/webOS/i)) {
    return "webos";
  } else if (useragent.match(/iPhone/i)) {
    return "iphone";
  } else if (useragent.match(/iPod/i)) {
    return "ipod";
  } else if (useragent.match(/iPad/i)) {
    return "ipad";
  } else if (useragent.match(/Windows Phone/i)) {
    return "windows phone";
  } else if (useragent.match(/SymbianOS/i)) {
    return "symbian";
  } else if (useragent.match(/RIM/i) || useragent.match(/BB/i)) {
    return "blackberry";
  } else {
    return false;
  }
}

export const checkDevice = () => {
  return device;
}
