import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { signOut, sendVerificationMail } from "../../store/actions/authActions";
import TextBox from "../fields/TextBox";
// import { testLog } from "../../utils";
// import { getValueFromField } from '../../utils/htmlUtils';
import Label from "../layout/Label";
// import { getText } from '../../config/labels';
import {
  // textFieldStyle,
  getHeight,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const NeedVerification = (props: any) => {
  const { auth, signOut, sendVerificationMail } = props;

  const handleCancel = () => {
    signOut(auth);
    return <Redirect to="/signin" />;
  };

  const handleResend = async () => {
    await sendVerificationMail();
    await signOut(auth);
  };

  if (auth && auth.uid && !auth.emailVerified) {
    setTimeout(handleCancel, 8000);
    return (
      <div>
        <StyledBackgroundDiv>
          <StyledWrapper>
            <StyledCard>
              <StyledTextBox text="need-verification" />
              <StyledButton onClick={handleCancel}>
                <Label label={"cancel"} />
              </StyledButton>
              <StyledGoogleButton onClick={handleResend}>
                <Label label={"resend-verification"} />
              </StyledGoogleButton>
            </StyledCard>
          </StyledWrapper>
        </StyledBackgroundDiv>
      </div>
    );
  } else {
    return (
      <div>
        <Redirect to="/signin" />
      </div>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signOut: (auth: any) => dispatch(signOut(auth)),
    sendVerificationMail: () => dispatch(sendVerificationMail())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NeedVerification);

// Styles
const StyledTextBox = styled(TextBox)`
  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primary};
  ${buttonStyle}
`;

const StyledGoogleButton = styled(Button)`
  background: #d50000;
  ${buttonStyle}
`;

const StyledCard = styled(Card)`
    ${cardStyle}
    background: ${(props: any) => props.theme.colors.secundary};
  `;

const StyledWrapper = styled("div")`
  ${wrapperStyle}
  padding-top: 100px;
`;

const StyledBackgroundDiv = styled("div")`
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px;
`;
