// import axios from "axios";
// import { FIREBASE } from "../../config/settings";
import { PROJECT_ID } from "../../config/settings";
import { devLog } from "../../utils";

// export const getTranslation = (text, langCode) => {
//   devLog("reqTranslation - text ", text);
//   const url = `https://europe-west1-presyes-${FIREBASE}.cloudfunctions.net/reqTranslation`;

//   const body = {text, langCode}

//   devLog("reqTranslation - body ", body);
//   return async dispatch => {
//     await axios
//       .post(url, body)
//       .then(response => {
//         devLog("Translate - result ", response);
//         dispatch({ type: "TRANSLATION_SUCCESS" });
//       })
//       .catch(function(err) {
//         devLog("Translation - error ", err);
//         dispatch({ type: "TRANSLATION_ERROR", err });
//       });
//   };
// };

// const cors = require("cors")("Access-Control-Allow-Origin", "*");
const { Translate } = require("@google-cloud/translate").v2;

const translate = new Translate({ PROJECT_ID });

async function googleTranslate(text, langCode) {
  // const value = "Hello world";
  devLog("googleTranslate - text", text);
  const target = "ru";
  const [translation] = await translate.translate(text, target);
  devLog("googleTranslate ", translation);
  return translation;
}

export const getTranslation = (langObj, langCode) => {
  return (dispatch, getState) => {
    devLog("getTranslation - langObj ", langObj);
    const newLangObject = googleTranslate("the cat is wet", "nl");
    devLog("getTranslation - newLangObject ", newLangObject);
    dispatch({ type: "TRANSLATE_SUCCESS" });
  };
};

