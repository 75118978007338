import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  getActivityEvents,
  getActivityParticipants,
  clearActivityState
} from "../../store/actions/activityActions";
import EventCard from "../events/EventCard";
import StyledAddFab from "../layout/StyledAddFab";
import CreateNewCard from "../layout/CreateNewCard";

import styled from "styled-components";
import { getHeight, backgroundDivStyle } from "../../config/styles";
import { Event } from "../../config/types";

function ActivityEvents(props: any): JSX.Element {
  const {
    auth,
    activityAdmins,
    events,
    match,
    getActivityEvents,
    getActivityParticipants,
    clearActivityState
  } = props;
  const { uid } = auth;
  const { activityId } = match.params;
  useEffect(() => {
    if (uid && activityId) {
      getActivityEvents(activityId);
      getActivityParticipants(activityId);
    }
    return () => {
      clearActivityState();
    };
  }, [
    uid,
    activityId,
    getActivityEvents,
    getActivityParticipants,
    clearActivityState,
  ]);

  const addEventUrl = `/add-event/${activityId}`;

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px
    & {
      margin-bottom: 58px;
    }
  `;
  let activityAdmin = false;
  if (auth.uid && activityAdmins && activityAdmins.includes(auth.uid)) {
    activityAdmin = true;
  }
  const fab = activityAdmin ? (
    <div>
      <StyledAddFab url={addEventUrl} />
    </div>
  ) : (
    <div></div>
  );

  const newCard = activityAdmin ? (
    <div>
      <CreateNewCard label="new-event" url={addEventUrl} />
    </div>
  ) : (
    <div></div>
  );

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  } else if (events && events.length > 0) {
    return (
      <StyledBackgroundDiv>
        {events.map((event: Event) => (
          <EventCard event={event} />
        ))}
        {newCard}
        {fab}
      </StyledBackgroundDiv>
    );
  } else {
    return (
      <StyledBackgroundDiv>
        {newCard}
        {fab}
      </StyledBackgroundDiv>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    activityAdmins: state.activity.activityAdmins,
    events: state.activity.events
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivityEvents: (activityId: string) =>
      dispatch(getActivityEvents(activityId)),
    getActivityParticipants: (activityId: string) =>
      dispatch(getActivityParticipants(activityId)),
    clearActivityState: () => dispatch(clearActivityState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityEvents);
