import { DEFAULT_USER_SETTINGS, getLanguageCode } from "../../config/settings";
import {
  getId,
  createDisplayName,
  createInitialsFromNames,
  generateUserSearchKeys,
  testLog,
  devLog
} from "../../utils";
import { saveCurrentVersionInLocalStorage } from "../../utils/serviceWorker";

export const signIn = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .signInWithEmailAndPassword(
        credentials.email.toLowerCase(),
        credentials.password
      )
      .then(response => {
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .update({
            lastSignIn: firebase.firestore.Timestamp.now()
          });
      })
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNIN_ERROR", err });
      });
  };
};


export const rudeSignOut = auth => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const signOut = auth => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection("users")
      .doc(auth.uid)
      .update({
        lastSignOut: firebase.firestore.Timestamp.now()
      })
      .then(() =>
        firebase
          .auth()
          .signOut()
          .then(() => {
            saveCurrentVersionInLocalStorage();
            dispatch({ type: "SIGNOUT_SUCCESS" });
          })
      );
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const userSettings = DEFAULT_USER_SETTINGS;
    userSettings.language = getLanguageCode(window.navigator.language);
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      userSettings.theme = "dark";
    }
    const displayName = newUser.firstName
      ? `${newUser.firstName}${" "}${newUser.lastName}`
      : `${newUser.lastName}`;
    const initials =
      newUser.firstName.charAt(0) +
      newUser.lastName
        .split(" ")
        .pop()
        .charAt(0);

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(response => {
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            displayName: displayName,
            initials: initials,
            bioKeywords: newUser.bioKeywords,
            keys: generateUserSearchKeys(
              newUser.email,
              newUser.firstName,
              newUser.lastName
            ),
            settings: userSettings,
            createdAt: firebase.firestore.Timestamp.now()
          });
      })
      .then(() => {
        const user = firebase.auth().currentUser;
        user
          .sendEmailVerification()
          .then(() => {})
          .catch(err => {
            testLog("Verification email error ", err);
          });
      })
      .then(() => {
        saveCurrentVersionInLocalStorage();
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const signInWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.GoogleAuthProvider();

    const userSettings = DEFAULT_USER_SETTINGS;
    userSettings.language = getLanguageCode(window.navigator.language);

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      userSettings.theme = "dark";
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(response => {
        // ---> first check is the google user is known as user.
        const email = response.additionalUserInfo.profile.email;
        devLog("signInWithGoogle - result ", response);
        const foundUsers = [];
        firestore
          .collection("users")
          .where("email", "==", email)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              const user = JSON.parse(JSON.stringify(doc.data()));
              foundUsers.push(user);
            });
          })
          .then(() => {
            // ---> do a signup if not found.
            if (foundUsers.length === 0) {
              const initials =
                response.additionalUserInfo.profile.given_name.charAt(0) +
                response.additionalUserInfo.profile.family_name
                  .split(" ")
                  .pop()
                  .charAt(0);

              return firestore
                .collection("users")
                .doc(response.user.uid)
                .set({
                  email: response.user.email,
                  firstName: response.additionalUserInfo.profile.given_name,
                  lastName: response.additionalUserInfo.profile.family_name,
                  displayName: response.user.displayName,
                  initials: initials,
                  bioKeywords: "",
                  keys: generateUserSearchKeys(
                    response.user.email,
                    response.additionalUserInfo.profile.given_name,
                    response.additionalUserInfo.profile.family_name
                  ),
                  settings: userSettings,
                  createdAt: firebase.firestore.Timestamp.now()
                });
              // ---> if the user is found update the signin date
            } else if (foundUsers.length === 1) {
              return firestore
                .collection("users")
                .doc(response.user.uid)
                .update({
                  lastSignIn: firebase.firestore.Timestamp.now()
                });
              // ---> else dispatch an error.
            } else {
              dispatch({
                type: "SIGNIN_ERROR"
              });
            }
          });
      })
      .then(() => {
        dispatch({
          type: "SIGNIN_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({
          type: "SIGNIN_ERROR",
          err
        });
      });
  };
};

export const signUpWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.GoogleAuthProvider();

    const userSettings = DEFAULT_USER_SETTINGS;
    userSettings.language = getLanguageCode(window.navigator.language);

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      userSettings.theme = "dark";
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(response => {
        const initials =
          response.additionalUserInfo.profile.given_name.charAt(0) +
          response.additionalUserInfo.profile.family_name
            .split(" ")
            .pop()
            .charAt(0);

        return firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            email: response.user.email,
            firstName: response.additionalUserInfo.profile.given_name,
            lastName: response.additionalUserInfo.profile.family_name,
            displayName: response.user.displayName,
            initials: initials,
            bioKeywords: "",
            keys: generateUserSearchKeys(
              response.user.email,
              response.additionalUserInfo.profile.given_name,
              response.additionalUserInfo.profile.family_name
            ),
            settings: userSettings,
            createdAt: firebase.firestore.Timestamp.now()
          });
      })
      .then(() => {
        saveCurrentVersionInLocalStorage();
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const signInWithFacebook = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(response => {
        testLog("signInWithFacebook - response ", response);
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .update({
            lastSignIn: firebase.firestore.Timestamp.now()
          });
      })
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNIN_ERROR", err });
      });
  };
};

export const signUpWithFacebook = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.FacebookAuthProvider();

    const userSettings = DEFAULT_USER_SETTINGS;
    userSettings.language = getLanguageCode(window.navigator.language);

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      userSettings.theme = "dark";
    }

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(response => {
        testLog("signUpWithFacebook - response ", response);
        return firestore
          .collection("users")
          .doc(response.user.uid)
          .set({
            email: response.user.email,
            firstName: response.additionalUserInfo.profile.given_name,
            lastName: response.additionalUserInfo.profile.family_name,
            displayName: response.user.displayName,
            initials: "",
            bioKeywords: "",
            keys: generateUserSearchKeys(
              response.user.email,
              response.additionalUserInfo.profile.given_name,
              response.additionalUserInfo.profile.family_name
            ),
            settings: userSettings,
            createdAt: firebase.firestore.Timestamp.now()
          });
      })
      .then(() => {
        saveCurrentVersionInLocalStorage();
        dispatch({
          type: "SIGNIN_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({
          type: "SIGNIN_ERROR",
          err
        });
      });
  };
};

export const getCurrentUser = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch({ currentUser: user, type: "GET_USER_SUCCESS" });
        // devLog('getCurrentUser - user ', user);
      } else {
        dispatch({ currentUser: user, type: "GET_USER_ERROR" });
      }
    });
  };
};

export const sendVerificationMail = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const user = firebase.auth().currentUser;
    user
      .sendEmailVerification()
      .then(() => {
        testLog("Verification email send to currentUser ", user);
        dispatch({ type: "EMAIL_SEND_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "EMAIL_SEND_ERROR", err });
      });
  };
};

export const sendResetPasswordRequest = email => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(result => {
        devLog("sendResetPasswordRequest - result ", result);
      })
      .catch(error => {
        devLog("sendResetPasswordRequest - error ", error);
      });
  };
};

export const verifyAndAddUserToActivity = activityId => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser;

    // first check if the user exists
    firestore
      .collection("users")
      .doc(`${currentUser.uid}`)
      .get()
      .then(doc => {
        const user = doc.data();
        const userId = doc.id;

        if (user) {
          // then check if user is already added to activity.
          // if not add to activity
          let foundParticipants = [];
          firestore
            .collectionGroup("participants")
            .where("activityId", "==", `${activityId}`)
            .where("userId", "==", `${userId}`)
            .get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                const fetchedParticipant = doc.data();
                foundParticipants.push(fetchedParticipant);
              });
            })
            .then(() => {
              if (foundParticipants.length === 0) {
                const initials = createInitialsFromNames(
                  user.firstName,
                  user.lastName
                );
                const participantId = getId(user.initials);
                const participant = {
                  participantId: participantId,
                  userId: userId,
                  initials: initials,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  activityId: activityId,
                  defaultPresence: true,
                  supportOnly: false,
                  admin: false
                };

                firestore
                  .collection("activities")
                  .doc(participant.activityId)
                  .collection("participants")
                  .doc(participant.participantId)
                  .set({
                    ...participant,
                    changedAt: firebase.firestore.Timestamp.now()
                  });

                currentUser.updateProfile({
                  displayName: createDisplayName(user.firstName, user.lastName),
                  emailVerified: true
                });
              } else {
                dispatch({
                  type: "ADD_USER_TO_ACTIVITY_ERROR"
                });
              }
            });
        } else {
          dispatch({
            type: "ADD_USER_TO_ACTIVITY_ERROR"
          });
        }
      })
      .then(() => {
        dispatch({ type: "SIGNUP_AND_ADDED_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_AND_ADDED_ERROR", err });
      });
  };
};
