const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNIN_ERROR":
      return {
        ...state,
        authError: "Signin failed"
      };

    case "SIGNIN_SUCCESS":
      return {
        ...state,
        authError: null
      };

    case "SIGNOUT_SUCCESS":
      return state;

    case "SIGNUP_SUCCESS":
      return {
        ...state,
        authError: null
      };

    case "SIGNUP_ERROR":
      return {
        ...state,
        authError: "Signup Error"
      };

    case "ALREADY_SIGNED_UP":
      return {
        ...state,
        authError: "Already signed up"
      };

    case "SIGNUP_AND_ADDED_ERROR":
      return {
        ...state,
        authError: action.err.message
      };

    case "SIGNUP_AND_ADDED_SUCCESS":
      return {
        ...state,
        authError: null
      };

    case "GET_USER_SUCCESS":
      return {
        ...state,
        currentUser: action.currentUser,
        authError: null
      };

    case "GET_USER_ERROR":
      return {
        ...state,
        currentUser: action.currentUser,
        authError: "No current user"
      };

    default:
      return state;
  }
};

export default authReducer;
