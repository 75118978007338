import { getId, endOfYesterdayTimestamp } from "../../utils";
import { reqInvitationToActivityMail } from "./mailActions";

export const addActivity = (activity, user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const participantId = getId(user.initials);

    firestore
      .collection("activities")
      .doc(activity.activityId)
      .set({
        ...activity,
        createdAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        firestore
          .collection("activities")
          .doc(activity.activityId)
          .collection("participants")
          .doc(participantId)
          .set({
            ...user,
            participantId: participantId,
            defaultPresence: true,
            supportOnly: false,
            createdAt: firebase.firestore.Timestamp.now()
          });
      })
      .then(() => {
        dispatch({ type: "ADD_ACTIVITY_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "ADD_ACTIVITY_ERROR" }, err);
      });
  };
};

export const addParticipant = (newParticipant, inviter) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    let foundUsers = [];

    firestore
      .collection("users")
      .where("email", "==", newParticipant.email)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const fetchedUser = doc.data();
          fetchedUser.userId = doc.id;
          foundUsers.push(fetchedUser);
        });
      })
      .then(() => {
        // add user to activity if found
        if (foundUsers.length === 0) {
          dispatch(reqInvitationToActivityMail(newParticipant, inviter));
        } else if (foundUsers.length === 1) {
          // check if the found user is already participant in actitity
          firestore
            .collectionGroup("participants")
            .where("activityId", "==", `${newParticipant.activityId}`)
            .where("userId", "==", `${foundUsers[0].userId}`)
            .get()
            .then(snapshot => {
              let foundParticipants = [];
              snapshot.forEach(doc => {
                foundParticipants.push(doc.data());
              });
              // if no participant found add new participant
              if (foundParticipants.length === 0) {
                const participantId = getId(foundUsers[0].initials);
                const participant = {
                  participantId: participantId,
                  userId: foundUsers[0].userId,
                  initials: foundUsers[0].initials,
                  firstName: foundUsers[0].firstName,
                  lastName: foundUsers[0].lastName,
                  activityId: newParticipant.activityId,
                  defaultPresence: newParticipant.defaultPresence,
                  supportOnly: false,
                  admin: newParticipant.admin
                };
                firestore
                  .collection("activities")
                  .doc(participant.activityId)
                  .collection("participants")
                  .doc(participant.participantId)
                  .set({
                    ...participant,
                    changedAt: firebase.firestore.Timestamp.now()
                  });
              } else {
                dispatch({
                  type: "ADD_USER_TO_ACTIVITY_ERROR"
                });
              }
            });
        } else {
          dispatch({
            type: "ADD_USER_TO_ACTIVITY_ERROR"
          });
        }
      })
      .then(() => {
        dispatch({ type: "ADD_USER_TO_ACTIVITY_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "ADD_USER_TO_ACTIVITY_ERROR" }, err);
      });
  };
};

export const updateActivity = activity => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection("activities")
      .doc(`${activity.activityId}`)
      .update({
        ...activity,
        changedAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        dispatch({ type: "UPDATE_ACTIVITY_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_ACTIVITY_ERROR" }, err);
      });
  };
};

export const deleteActivity = activityId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("activities")
      .doc(`${activityId}`)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_ACTIVITY_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "DELETE_ACTIVITY_ERROR" }, err);
      });
  };
};

export const getActivity = activityId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundActivities = [];
    firestore
      .collection("activities")
      .where("activityId", "==", `${activityId}`)
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const activity = JSON.parse(JSON.stringify(doc.data()));
          foundActivities.push(activity);
        });
      })
      .then(() => {
        dispatch({
          activity: foundActivities[0],
          type: "GET_ACTIVITY_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_ACTIVITY_ERROR" }, err);
      });
  };
};

export const getActivitiesFromUser = userId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundActivities = [];

    // select all activities where user is participant.
    firestore
      .collectionGroup("participants")
      .where("userId", "==", userId)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const myActivity = doc.data();
          const myActivityId = myActivity.activityId;
          // get information of the activities
          firestore
            .collection("activities")
            .where("activityId", "==", `${myActivityId}`)
            .get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                const activity = doc.data();
                foundActivities.push(activity);
              });
              // sort activities based on activityName
              foundActivities.sort((a, b) =>
                a.activityName > b.activityName ? 1 : -1
              );
            })
            .then(() => {
              dispatch({
                activities: foundActivities,
                type: "GET_ACTIVITIES_FROM_USER_SUCCESS"
              });
            });
        });
      })
      .catch(err => {
        dispatch(
          {
            type: "GET_ACTIVITIES_FROM_USER_ERROR"
          },
          err
        );
      });
  };
};

export const getActivityEvents = activityId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let foundEvents = [];

    firestore
      // get informations of the (future) events of an activity
      .collection("events")
      .where("activityId", "==", `${activityId}`)
      .where("startDateTime", ">", endOfYesterdayTimestamp())
      .orderBy("startDateTime")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const event = doc.data();
          foundEvents.push(event);
        });
      })
      .then(() => {
        dispatch({
          events: foundEvents,
          type: "GET_ACTIVITY_EVENTS_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_ACTIVITY_EVENTS_ERROR" }, err);
      });
  };
};

export const getActivityParticipants = activityId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundParticipants = [];
    let foundActivityAdmins = [];

    firestore
      // get informations of the (future) events of an activity
      .collectionGroup("participants")
      .where("activityId", "==", `${activityId}`)
      .orderBy("supportOnly")
      .orderBy("participantId")
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const participant = JSON.parse(JSON.stringify(doc.data()));
          foundParticipants.push(participant);
          if (participant.admin) {
            foundActivityAdmins.push(participant.userId);
          }
        });
      })
      .then(() => {
        dispatch({
          activityAdmins: foundActivityAdmins,
          participants: foundParticipants,
          type: "GET_ACTIVITY_PARTICIPANTS_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_ACTIVITY_PARTICIPANTS_ERROR" }, err);
      });
  };
};

export const getActivityParticipations = activityId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundParticipations = [];

    firestore
      // get informations of the (future) events of an activity
      .collectionGroup("participations")
      .where("activityId", "==", `${activityId}`)
      .where("startDateTime", ">", endOfYesterdayTimestamp())
      .orderBy("startDateTime")
      .orderBy("eventId")
      .orderBy("supportOnly")
      .orderBy("participantId")
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const participation = JSON.parse(JSON.stringify(doc.data()));
          foundParticipations.push(participation);
        });
      })
      .then(() => {
        dispatch({
          participations: foundParticipations,
          type: "GET_ACTIVITY_PARTICIPATIONS_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_ACTIVITY_PARTICIPATIONS_ERROR" }, err);
      });
  };
};

export const clearActivityState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_ACTIVITY_STATE"
    });
  };
};
