import { langEN } from "./en";
import { langNL } from "./nl";
import { textsEN } from "./enTexts";
import { textsNL } from "./nlTexts";
import { nlHomeText, nlLineTexts } from "./nlHome";
import { enHomeText, enLineTexts } from "./enHome";
import { nlGettingStartedTexts } from "./nlGetStarted";
import { enGettingStartedTexts } from "./enGetStarted";
import { nlHowToTexts } from "./nlHowTo";
import { enHowToTexts } from "./enHowTo";

export const getLanguageObject = language => {
  let languageObject = {};
  switch (language) {
    case "EN":
      languageObject = langEN;
      break;
    case "NL":
      languageObject = langNL;
      break;
    default:
      languageObject = langEN;
      break;
  }
  return languageObject;
};

const getLanguageTextObject = language => {
  let languageTextObject = {};
  switch (language) {
    case "EN":
      languageTextObject = textsEN;
      break;
    case "NL":
      languageTextObject = textsNL;
      break;
    default:
      languageTextObject = textsEN;
      break;
  }
  return languageTextObject;
};

export const getInstructionText = (instruction, language) => {
  const browserLanguage = window.navigator.language
    .toUpperCase()
    .substring(0, 2);
  const lang = language ? language : browserLanguage;

  const texts = getLanguageTextObject(lang);

  console.log(texts);
  console.log(texts[instruction]);
  console.log(instruction)

  if (texts[instruction]) {
    return texts[instruction];
  } else if (instruction) {
    return instruction;
  } else {
    return "no-instruction";
  }
};


export const getText = (label, language) => {
  const browserLanguage = window.navigator.language
    .toUpperCase()
    .substring(0, 2);
  const lang = language ? language : browserLanguage;

  const labels = getLanguageObject(lang);
  const key = label;

  if (labels[key]) {
    return labels[key];
  } else if (label) {
    return label;
  } else {
    return "no-label";
  }
};

export const getNavigatorLanguage = () => {
  return window.navigator.language.toUpperCase().substring(0, 2);
};

export const getHomeText = language => {
  let homeText = enHomeText;
  switch (language) {
    case "NL":
      homeText = nlHomeText;
      break;
    default:
      break;
  }
  return homeText;
};

export const getLineTexts = language => {
  let lineTexts = enLineTexts;
  switch (language) {
    case "NL":
      lineTexts = nlLineTexts;
      break;
    default:
      break;
  }
  return lineTexts;
};

export const getGettingStartedTexts = language => {
  let gettingStartedTexts = enGettingStartedTexts;
  switch (language) {
    case "NL":
      gettingStartedTexts = nlGettingStartedTexts;
      break;
    default:
      break;
  }
  return gettingStartedTexts;
};

export const getHowToTexts = language => {
  let howToTexts = enHowToTexts;
  switch (language) {
    case "NL":
      howToTexts = nlHowToTexts;
      break;
    default:
      break;
  }
  return howToTexts;
};
