import React from 'react';
import { connect } from 'react-redux';

import ShortTextIcon from '@material-ui/icons/ShortText';

import styled from 'styled-components';
import { tileParticipantStyle } from "../../config/styles";

import { updateUserSettings } from '../../store/actions/userActions';
import { DEFAULT_USER_SETTINGS, getLanguageCode } from '../../config/settings';

import { getTileSettings } from '../../config/settings';

function ChangeHeaderWidthTile(props: any): JSX.Element {
  const { profile, tileStyle } = props;
  let tileSettings: any = getTileSettings(tileStyle);

  const handleClick = () => {
    if (profile && profile.settings) {
      let newSettings = profile.settings;
      switch (newSettings.tileStyle) {
        case 'wide':
          newSettings.tileStyle = 'square';
          break;
        case 'square':
          newSettings.tileStyle = 'flat';
          break;
        case 'flat':
          newSettings.tileStyle = 'tiny';
          break;
        case 'tiny':
          newSettings.tileStyle = 'wide';
          break;
        default:
          newSettings.tileStyle = 'wide';
          break;
      }
      props.updateUserSettings(newSettings);
    } else if (profile) {
      const newSettings = DEFAULT_USER_SETTINGS;
      newSettings.language = getLanguageCode(window.navigator.language);
      props.updateUserSettings(newSettings);
    }
  };

  const StyledTile = styled("div")`
    ${tileParticipantStyle}
    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    font-size: 12px;
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    border-radius: ${tileSettings.tileRadius};
    font-weight: bold;
    color: ${(props: any) => props.theme.colors.tileColor};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  const StyledShortTextIcon = styled(ShortTextIcon)`
    color: ${(props: any) => props.theme.colors.tileColor};
    width: ${tileSettings.iconWidth};
    height: ${tileSettings.iconHeight};
    cursor: pointer;
    cursor: hand;
  `;

  return (
    <StyledTile>
      <StyledShortTextIcon onClick={handleClick} />
    </StyledTile>
  );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserSettings: (newSetttings: any) =>
      dispatch(updateUserSettings(newSetttings))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeHeaderWidthTile);
