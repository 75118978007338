import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import PartyModeIcon from "@material-ui/icons/PartyMode";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ShareIcon from "@material-ui/icons/Share";
import History from "@material-ui/icons/History";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EventNoteIcon from "@material-ui/icons/EventNote";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import {
  iconGettingStartedStyle,
  instructionTextStyle,
  instructionHeaderStyle
} from "../../config/styles";

const BasePaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const BaseTextPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;

const StyledGroupWorkIcon = styled(GroupWorkIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledEventOutlinedIcon = styled(EventOutlinedIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledGroupAddOutlinedIcon = styled(GroupAddOutlinedIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledPartyModeIcon = styled(PartyModeIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledHistoryIcon = styled(History)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledShareIcon = styled(ShareIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledEventNoteIcon = styled(EventNoteIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledEventAvailableIcon = styled(EventAvailableIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledTouchAppIcon = styled(TouchAppIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;

const StyledHeader = styled(Typography)`
  ${instructionHeaderStyle}
  margin-top: 10px;
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
`;
const StyledAvatar = styled(Avatar)`
  height: 70px;
  width: 70px;
  margin-top: -5px;
  margin-right: 20px;
  background-color: ${(props: any) => props.theme.colors.background};
`;

export default function InstructionPaper(props: any) {
  const { text, language } = props;

  if (text && language) {
    let icon: any;
    switch (text.icon) {
      case "activities":
        icon = <StyledGroupWorkIcon />;
        break;
      case "events":
        icon = <StyledEventOutlinedIcon />;
        break;
      case "event":
        icon = <StyledEventNoteIcon />;
        break;
      case "eventAvailable":
        icon = <StyledEventAvailableIcon />;
        break;
      case "participants":
        icon = <StyledGroupAddOutlinedIcon />;
        break;
      case "next":
        icon = <StyledPartyModeIcon />;
        break;
      case "share":
        icon = <StyledShareIcon />;
        break;
      case "history":
        icon = <StyledHistoryIcon />;
        break;
      case "messages":
        icon = <StyledMailOutlineIcon />;
        break;
      case "touch":
        icon = <StyledTouchAppIcon />;
        break;
      default:
        icon = <StyledHelpOutlineIcon />;
        break;
    }

    return (
      <div>
        <BasePaper elevation={0}>
          <StyledAvatar>{icon}</StyledAvatar>
          <BaseTextPaper elevation={0}>
            <StyledHeader>{text.header}</StyledHeader>
            <StyledText>{text.text}</StyledText>
          </BaseTextPaper>
        </BasePaper>
      </div>
    );
  } else return null;
}
