// import { FIREBASE } from "../config/settings";
// import { testLog } from "./index";

// const getUrl = () => {
//   let url;
//   switch (FIREBASE) {
//     case "dev":
//       url = "https://presyes-dev.web.app";
//       break;
//     case "test":
//       url = "https://presyes-test.web.app";
//       break;
//     case "prod":
//       url = "https://presyes.com";
//       break;
//     default:
//       url = "http://localhost:3000";
//       break;
//   }
//   return url;
// };

export const createOccurrenceForParticipation = (
  type: string,
  auth: any,
  profile: any,
  participation: any
) => {
  if (type && auth && profile && participation) {
    const occurrence = {
      occurrenceTarget: "participation",
      occurrenceType: type,
      occurrenceText: "",
      changedBy: auth.uid,
      changedByName: profile.displayName,
      changedByInitials: profile.initials,
      changedFor: participation.userId,
      changedForName: "",
      changedForInitials: participation.initials,
      activityId: participation.activityId,
      activityName: "",
      eventId: participation.eventId,
      eventName: participation.eventName,
      participantId: participation.participantId,
      participationId: participation.participationId,
      changedParticipationTo: participation.presence
    };
    return occurrence;
  } else return "Error";
};

export const createOccurrenceForEvent = (
  type: string,
  auth: any,
  profile: any,
  event: any
) => {
  if (type && auth && profile && event) {
    const occurrence = {
      occurrenceTarget: 'event',
      occurrenceType: type,
      occurrenceText: "",
      changedBy: auth.uid,
      changedByName: profile.displayName,
      changedByInitials: profile.initials,
      changedFor: "",
      changedForName: "",
      changedForInitials: "",
      activityId: event.activityId,
      activityName: "",
      eventId: event.eventId,
      eventName: event.eventName,
      participantId: "",
      participationId: "",
      changedParticipationTo: false
    };
    return occurrence;
  } else return "Error";
};
