import React, { useState, useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
// styling and related components
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import History from "@material-ui/icons/History";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
// Presyes screens and components and actions
// import DateTimePickerField from '../fields/DateTimePickerField';
import ParticipantsCard from "../participations/ParticipantsCard";
import HistoryCard from "../history/HistoryCard";
import MessagesCard from "../messages/MessagesCard";
// import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import {
  getActivityParticipants,
  getActivity,
  clearActivityState
} from "../../store/actions/activityActions";
import {
  getEvent,
  getEventParticipations,
  clearEventState
} from "../../store/actions/eventActions";

import Typography from "@material-ui/core/Typography";

// configs and utility functions
import { getLabel } from "../../config/labels";
// import { getValueFromField } from '../../utils/htmlUtils';
import {
  // devLog,
  timestampToDateString,
  getDaysToGoFromTimestamp,
  getDayStringFromTimestamp,
  getDayLabel
} from "../../utils";

import {
  getHeight,
  backgroundDivStyle,
  cardIconStyle,
  cardDayFieldStyle,
  cardFieldTextStyle,
  cardIconWrappeStyle,
  cardHeaderTextStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const EventDetails = (props: any) => {
  const {
    auth,
    profile,
    event,
    activity,
    participants,
    participations,
    getEvent,
    getActivity,
    getActivityParticipants,
    getEventParticipations,
    clearActivityState,
    clearEventState,
    match
  } = props;
  const { eventId, activityId } = match.params;
  const { uid } = auth;

  // const [showParticipants, setShowParticipants] = useState(true);
  // const [showHistory, setShowHistory] = useState(false);
  // const [showMessages, setShowMessages] = useState(false);
  const [showCard, setShowCard] = useState("pp");

  // const [count, setCount] = useState(1);

  useEffect(() => {
    if (uid && eventId && activityId) {
      getActivity(activityId);
      getEvent(eventId);
      getActivityParticipants(activityId);
      getEventParticipations(eventId);
      // setCount(count - 1);
    }
    return () => {
      clearActivityState();
      clearEventState();
      // devLog("EventDetails unmounts");
    };
  }, [
    uid,
    eventId,
    activityId,
    getActivity,
    getEvent,
    getActivityParticipants,
    getEventParticipations,
    clearActivityState,
    clearEventState
  ]);

  if (!auth.uid) return <Redirect to="/signin" />;

  if (event && participants && participations && activity && activity.admins) {
    let userLanguage = window.navigator.language.toUpperCase().substring(0, 2);
    if (profile && profile.settings && profile.settings.language) {
      userLanguage = profile.settings.language;
    }

    const EditEventUrl = `/edit-event/${event.eventId}/${event.activityId}`;

    const start = timestampToDateString(event.startDateTime);
    const end = timestampToDateString(event.endDateTime);

    const daysToGo = getDaysToGoFromTimestamp(event.startDateTime);
    let dayString = `+ ${daysToGo} ${getLabel("days", userLanguage)}`;
    const day = getDayStringFromTimestamp(event.startDateTime);
    if (day !== "-") {
      dayString = getDayLabel(day, userLanguage);
    }

    let activityAdmin = false;
    if (activity && activity.admins.includes(auth.uid)) {
      activityAdmin = true;
    }

    const nav = activityAdmin ? (
      <div>
        <StyledNavLink to={EditEventUrl}>
          <StyledButton size="small">
            <StyledEditIcon />
          </StyledButton>
        </StyledNavLink>
      </div>
    ) : null;

    const endsAt = event.endDateTime ? (
      <div>
        <StyledField>
          {getLabel("end", userLanguage)}
          {": "}
          {end}
        </StyledField>
      </div>
    ) : null;

    const location = event.eventLocation ? (
      <div>
        <StyledField>
          {getLabel("location", userLanguage)}
          {": "}
          {event.eventLocation}
        </StyledField>
      </div>
    ) : null;

    const address = event.eventAddress ? (
      <div>
        <StyledField>
          {getLabel("address", userLanguage)}
          {": "}
          {event.eventAddress}
        </StyledField>
      </div>
    ) : null;
    const presenceLimit = event.presenceLimit ? (
      <div>
        <StyledField>
          {getLabel("presence-limit", userLanguage)}
          {": "}
          {event.presenceLimit}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );
    const note = event.note ? (
      <div>
        <StyledField>
          {" "}
          {getLabel("note", userLanguage)}: {event.note}
        </StyledField>
      </div>
    ) : (
      <div></div>
    );

    let sum: number = 0;
    participations.forEach((participation: any) => {
      if (
        participation &&
        participation.presence &&
        !participation.supportOnly
      ) {
        sum = sum + 1;
      }
    });

    const total = (
      <div>
        <StyledField>
          {getLabel("presence-total", userLanguage)}: {sum}
        </StyledField>
      </div>
    );

    const handleClickParticipants = () => {
      setShowCard("pp");
      // setShowParticipants(true);
      // setShowMessages(false);
      // setShowHistory(false);
    };
    const handleClickMessage = () => {
      setShowCard("msg");
      // setShowParticipants(false);
      // setShowMessages(true);
      // setShowHistory(false);
    };
    const handleClickHistory = () => {
      setShowCard("his");
      // setShowParticipants(false);
      // setShowMessages(false);
      // setShowHistory(true);
    };

    let cardToShow;
    switch (showCard) {
      case "pp":
        cardToShow = (
          <ParticipantsCard
            activityAdmins={activity.admins}
            participants={participants}
            participations={participations}
          />
        );
        break;
      case "msg":
        cardToShow = <MessagesCard event={event} />;
        break;
      case "his":
        cardToShow = <HistoryCard eventId={event.eventId} />;
        break;
      default:
        break;
    }

    // const eventParticipants = showParticipants ? (
    //   <ParticipantsCard
    //     activityAdmins={activity.admins}
    //     participants={participants}
    //     participations={participations}
    //   />
    // ) : (
    //   <div />
    // );

    // const messages = showMessages ? <MessagesCard event={event} /> : <div />;

    // const history = showHistory ? (
    //   <HistoryCard eventId={event.eventId} />
    // ) : (
    //   <div />
    // );
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledBaseCard>
            <StyledDayField>{dayString}</StyledDayField>
            <CardContent>
              <StyledHeader>{event.eventName}</StyledHeader>
              <StyledField>
                {getLabel("start", userLanguage)}
                {": "}
                {start}
              </StyledField>
              {endsAt}
              {location}
              {address}
              {presenceLimit}
              {total}
              {note}
            </CardContent>
            <StyledIconWrapper>
              <StyledPeopleIcon onClick={handleClickParticipants} />
              <StyledMailOutlineIcon onClick={handleClickMessage} />
              <StyledHistoryIcon onClick={handleClickHistory} />
              {nav}
            </StyledIconWrapper>
          </StyledBaseCard>
        </StyledWrapper>
        {cardToShow}
      </StyledBackgroundDiv>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activity: state.activity.activity,
    participants: state.activity.participants,
    event: state.event.event,
    participations: state.event.participations
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEvent: (eventId: string) => dispatch(getEvent(eventId)),
    getActivity: (activityId: string) => dispatch(getActivity(activityId)),
    getActivityParticipants: (activityId: string) =>
      dispatch(getActivityParticipants(activityId)),
    getEventParticipations: (eventId: string) =>
      dispatch(getEventParticipations(eventId)),
    clearActivityState: () => dispatch(clearActivityState()),
    clearEventState: () => dispatch(clearEventState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);

// styled components
const StyledBaseCard = styled(Card)`
  ${cardStyle};
  padding-left: 60px;
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
`;
const StyledDayField = styled(Typography)`
  ${cardDayFieldStyle};
  float: right;
`;
const StyledField = styled(Typography)`
  ${cardFieldTextStyle};
`;
const StyledHeader = styled(Typography)`
  ${cardHeaderTextStyle};
`;
const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px;
`;
const StyledButton = styled(Button)`
  float: right;
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledEditIcon = styled(EditIcon)`
  ${cardIconStyle}
`;
const StyledHistoryIcon = styled(History)`
  ${cardIconStyle}
  margin-top: 6px;
`;
const StyledIconWrapper = styled("div")`
  ${cardIconWrappeStyle}
  background-color: ${(props: any) => props.theme.colors.surface};
`;
const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  ${cardIconStyle}
  margin-top: 6px;
`;
const StyledPeopleIcon = styled(PeopleOutline)`
  ${cardIconStyle}
  margin-top: 6px;
`;
const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
