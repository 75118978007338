const initState = {
  translateError: null
};

const translateReducer = (state = initState, action) => {
  switch (action.type) {
    case "TRANSLATION_ERROR":
      return {
        ...state,
        translateError: "Translation failed"
      };

    case "TRANSLATION_SUCCESS":
      return {
        ...state,
        translate: action.translation,
        translateError: null
      };

    default:
      return state;
  }
};

export default translateReducer;