import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// import { ACTIVITIES } from "../../config/links";

import styled from "styled-components";

import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import SpecialField from "../fields/SpecialField";

import {
  signIn,
  signInWithGoogle,
  // signInWithFacebook,
  signOut
} from "../../store/actions/authActions";
import { getValueFromField } from "../../utils/htmlUtils";
import Label from "../layout/Label";
import {
  getHeight,
  tinyTextButtonStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle,
  backgroundDivStyle
} from "../../config/styles";

const SignIn = (props: any) => {
  const { auth, signIn, signInWithGoogle } = props;
  const [showResetRequest, setShowResetRequest] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    emailError: false,
    password: "",
    passwordError: false
  });

  const handleSignIn = async () => {
    let newFormValues = {
      email: "",
      emailError: false,
      password: "",
      passwordError: false
    };

    let formError = false;
    newFormValues.email = getValueFromField("email");
    if (!newFormValues.email) {
      newFormValues.emailError = true;
      formError = true;
    }
    newFormValues.password = getValueFromField("password");
    if (!newFormValues.password) {
      newFormValues.passwordError = true;
      formError = true;
    }

    if (formError) {
      setFormValues(newFormValues);
    } else {
      const credentials = {
        email: newFormValues.email.toLowerCase(),
        password: newFormValues.password
      };
      await signIn(credentials);
      return <Redirect to="/" />;
    }
  };

  const handleSignInWithGoogle = () => {
    setShowProgress(true);
    signInWithGoogle();
    return <Redirect to="/" />;
  };

  const handleReset = async () => {
    setShowResetRequest(true);
  };

  // const handleSignInWithFacebook = async () => {
  //   await signInWithFacebook();
  // };

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle};
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
  `;

  const StyledButton = styled(Button)`
    background: ${(props: any) => props.theme.colors.primary};
    ${buttonStyle}
    margin-bottom: 10px;
  `;
  const StyledGoogleButton = styled(Button)`
    ${buttonStyle}
    background: #d50000;
    margin-bottom: 30px;
  `;
  const StyledDiv = styled("div")`
    ${wrapperStyle}
  `;
  const StyledCard = styled(Card)`
    ${cardStyle}
    background: ${(props: any) => props.theme.colors.secundary};
  `;

  const StyledText = styled("text")`
    ${tinyTextButtonStyle}
  `;
  const StyledWrapper = styled("div")`
    ${wrapperStyle}
    text-align: rght;
  `;

  if (showResetRequest) {
    return <Redirect to="/reset-password-request" />;
  }
  // if (currentUser && currentUser.emailVerified) {
  //   const url = `${'/activities/'}${auth.uid}`;
  //   return <Redirect to={url} />;
  // }

  if (auth && auth.uid && auth.emailVerified) {
    // const url = `${ACTIVITIES}/${auth.uid}`;
    const url = `/myhome`;
    return <Redirect to={url} />;
    } else if (auth && auth.uid && !auth.emailVerified) {
      return <Redirect to="/need-verification" />;
  }

  if (showProgress) {
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <CircularProgress />
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  } else {
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard raised>
            <form>
              <SpecialField
                name="email"
                defaultValue={formValues.email}
                error={formValues.emailError}
              />
              <SpecialField
                name="password"
                defaultValue={formValues.password}
                error={formValues.passwordError}
              />
            </form>
            <StyledButton onClick={handleSignIn}>
              <Label label={"signin"} />
            </StyledButton>
            <StyledText onClick={handleReset}>
              <Label label="reset-password" />
            </StyledText>
            <StyledDiv>
              <Label label="or" />
            </StyledDiv>
            <StyledGoogleButton onClick={handleSignInWithGoogle}>
              <Label label={"signin-google"} />
            </StyledGoogleButton>
          </StyledCard>
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    currentUser: state.auth.currentUser,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signIn: (signinValues: any) => dispatch(signIn(signinValues)),
    signOut: () => dispatch(signOut()),
    signInWithGoogle: () => dispatch(signInWithGoogle())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
