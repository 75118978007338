import React from 'react';
import styled from 'styled-components';
import { textFieldStyle } from '../../config/styles';

import TextField from '@material-ui/core/TextField';

export default function BaseInputField(props: any) {

  const StyledTextField = styled(TextField)`
    ${textFieldStyle}
  `;

  return (
    <div>
      <StyledTextField
        autoFocus={props.autoFocus}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        error={props.error}
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        id={props.name}
        label={props.label}
        margin={props.margin}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        placeholder={props.placeholder}
        required={props.required}
        type={props.type}
        value={props.value}
        variant={props.variant}
      />
    </div>
  );
}
