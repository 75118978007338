import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import InstructionPaper from "../instructions/InstructionPaper";
import CookieBeam from "../dialogs/CookieBeam";
// import { ReactComponent as Logo } from "../../img/presyes.svg";
// import { devLog } from '../../utils'
import {
  getNavigatorLanguage,
  getHomeText,
  getLineTexts
} from "../../language";
import { getLabel } from "../../config/labels";
import styled from "styled-components";
import {
  getHeight,
  backgroundDivStyle,
  h2Style,
  homeTextStyle,
  logoStyle
} from "../../config/styles";
import { TITLE } from "../../config/settings";
import DevicesImage from "../images/Devices";

const Home = (props: any) => {
  const { auth, profile } = props;
  const { uid } = auth;
  const [showMessageBeam, setShowMessageBeam] = React.useState(true);
  // const [approved, setApproved] = React.useState(true);

  React.useEffect(() => {
    if (uid) {
      setShowMessageBeam(false);
    }
  }, [uid, setShowMessageBeam]);

  // determine language
  let language = getNavigatorLanguage();
  let features = "";
  if (profile && profile.settings && profile.settings.language) {
    language = profile.settings.language;
  }
  features = `${getLabel("features", language)}`;
  const homeText = getHomeText(language);
  const lineTexts = getLineTexts(language);

  const beam = showMessageBeam ? (
    <CookieBeam message={"cookies"} setShowMessage={setShowMessageBeam} />
  ) : (
    <div></div>
  );

  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <Typography>
          <StyledH>{TITLE}</StyledH>
          <StyledP>{homeText.line1}</StyledP>
          {lineTexts.map((text: any) => {
            return (
              <div>
                <InstructionPaper text={text} language={language} />
              </div>
            );
          })}
          <StyledP>{homeText.line6}</StyledP>
          <StyledP>{homeText.line7}</StyledP>
          <StyledP>{homeText.line8}</StyledP>
        </Typography>
        <DevicesImage />
        {/* <Logo /> */}
        <StyledNavLink to="/features">
          <StyledH3>{features}</StyledH3>
        </StyledNavLink>
      </StyledWrapper>

      {beam}
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Home);

// Styles
const StyledH = styled("h2")`
  ${h2Style}
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onSurface};
`;
const StyledH3 = styled(Typography)`
  font-size: 30px;
  font-weigth: 500;
  margin-top: 30px;
  margin-bottom: 150px;
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledP = styled("p")`
  ${homeTextStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onSurface};
`;
const StyledNavLink = styled(NavLink)`
  ${logoStyle};
  color: ${(props: any) => props.theme.colors.logo};
  text-decoration: underline grey;
`;
const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${getHeight()}px
`;
const StyledWrapper = styled("div")`
  width: 60%;
  @media (max-width: 640px) {
    width: 80%;
  }
`;
