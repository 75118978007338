import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";

import AddParticipantMobileImage from "../../img/add-participant-mobile.png";
import FindInviteParticipantMobileImage from "../../img/find-invite-participant-mobile.png";
import AddParticipantImage from "../../img/add-participant.png";
import FindParticipantImage from "../../img/find-participant.png";
import InviteParticipantImage from "../../img/invite-participant.png";

import {
  iconGettingStartedStyle,
  instructionAvatarStyle,
  instructionImageStyle,
  instructionTextStyle,
  instructionTextWrapper
} from "../../config/styles";

import { getInstructionText } from "../../language";

const StyledAvatar = styled(Avatar)`
  ${instructionAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledImage = styled("img")`
  ${instructionImageStyle}
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledIcon = styled(GroupAddOutlinedIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledTextWrapper = styled("div")`
  ${instructionTextWrapper}
`;

export default function AddParticipant(props: any) {
  const { language, mobileSize } = props;

  if (language) {
    const txt = getInstructionText("addParticipant", language);
    const txt2 = getInstructionText("inviteParticipant", language);

    const txtBlock1 = (
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledIcon />
        </StyledAvatar>
        <StyledText>{txt}</StyledText>
      </StyledTextWrapper>
    );
    const txtBlock2 = (
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledIcon />
        </StyledAvatar>
        <StyledText>{txt2}</StyledText>
      </StyledTextWrapper>
    );

    const blockToShow = mobileSize ? (
      <div>
        {txtBlock1}
        <StyledImage src={AddParticipantMobileImage} />
        {txtBlock2}
        <StyledImage src={FindInviteParticipantMobileImage} />
      </div>
    ) : (
      <div>
        {txtBlock1}
        <StyledImage src={AddParticipantImage} />
        <StyledImage src={FindParticipantImage} />
        {txtBlock2}
        <StyledImage src={InviteParticipantImage} />
      </div>
    );

    return <div>{blockToShow}</div>;
  } else return null;
}
