import { getIdDatePart, devLog } from "../../utils";

export const addOccurrence = occurrence => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    let occurrenceId = `${getIdDatePart()}`;
    if (
      occurrence &&
      occurrence.changedByInitials &&
      occurrence.changedForInitials
    ) {
      occurrenceId = `${getIdDatePart()}-${occurrence.changedByInitials}-${
        occurrence.changedForInitials
      }`;
    } else if (occurrence && occurrence.changedByInitials) {
      occurrenceId = `${getIdDatePart()}-${occurrence.changedByInitials}`;
    }

    firestore
      .collection("history")
      .doc(occurrenceId)
      .set({
        ...occurrence,
        occurrenceId: occurrenceId,
        occurrenceDateTime: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        devLog("Occurrence dispatch success ", occurrence);
        dispatch({
          type: "ADD_OCCURRENCE_SUCCESS"
        });
      })
      .catch(err => {
        dispatch(
          {
            type: "ADD_OCCURRENCE_ERROR"
          },
          err
        );
      });
  };
};

export const getEventHistory = eventId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundOccurrences = [];
    firestore
      .collection("history")
      .where("eventId", "==", `${eventId}`)
      .orderBy("occurrenceDateTime", "desc")
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const occurrence = doc.data();
          foundOccurrences.push(occurrence);
        });
      })
      .then(() => {
        dispatch({
          eventHistory: foundOccurrences,
          type: "GET_EVENT_HISTORY_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_EVENT_HISTORY_ERROR" }, err);
      });
  };
};

export const clearHistoryState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_HISTORY_STATE"
    });
  };
};