import { VERSION } from "../config/settings";
import { forceSWupdate } from "../serviceWorker";

export const saveCurrentVersionInLocalStorage = () => {
  localStorage.setItem("presyesCurrentVersion", VERSION);
};

export const getCurrentVersion = () => {
  return getCurrentVersionFromLocalStorage();
};
const getCurrentVersionFromLocalStorage = () => {
  return localStorage.getItem("presyesCurrentVersion");
};

const checkVersion = () => {
  const currentVersion = getCurrentVersionFromLocalStorage();
  const latestVersion = VERSION;
  console.log("current version is ", currentVersion);
  console.log("latest version is ", latestVersion);
  if (currentVersion === latestVersion) {
    return true;
  } else {
    return false;
  }
};

export const setCurrentVersion = () => {
  const oldVersion = !checkVersion();
  if (oldVersion) {
    forceSWupdate();
  }
};
