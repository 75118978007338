import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { dark, light, teal, indigo } from "./config/themes";
import { testLog } from "./utils/index";
// import { TESTRUN } from './config/settings';

import { StylesProvider } from "@material-ui/styles";

import { getCurrentUser } from "./store/actions/authActions";
// import { updateUserSettings } from './store/actions/userActions';
import MainBar from "./components/layout/MainBar";
import NavBar from "./components/layout/NavBar";

import Home from "./components/screens/Home";
import MyHome from "./components/screens/MyHome";
import MyInstructions from "./components/screens/MyInstructions";
import Profile from "./components/screens/Profile";
import Settings from "./components/screens/Settings";
import Messages from "./components/screens/Messages";
import Notifications from "./components/screens/Notifications";
import Upcoming from "./components/screens/Upcoming";
import Activities from "./components/screens/Activities";
import AddActivity from "./components/screens/AddActivity";
import EditActivity from "./components/screens/EditActivity";
import EventDetails from "./components/screens/EventDetails";
import AddParticipant from "./components/screens/AddParticipantList";
import EditParticipant from "./components/screens/EditParticipant";
import ActivityEvents from "./components/screens/ActivityEvents";
import ActivityParticipants from "./components/screens/ActivityParticipants";
import ActivityParticipations from "./components/screens/ActivityParticipations";
import AddEvent from "./components/screens/AddEvent";
import EditEvent from "./components/screens/EditEvent";
import Features from "./components/screens/Features";
import UsingPresYes from "./components/screens/UsingPresYes";
import LogIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import RudeSignOut from "./components/auth/RudeSignOut";
import SignUpAndAddMe from "./components/auth/SignUpAndAddMe";
import NeedVerification from "./components/auth/NeedVerification";
import AddUserDetails from "./components/auth/AddUserDetails";
import ResetPasswordRequest from "./components/auth/ResetPasswordRequest";

/// some test routes
import ShowLanguage from "./components/screens/ShowLanguage";
import GetLanguage from "./components/screens/GetLanguage";
// import FirebaseTest from "./components/screens/FirebaseTest";
// import SnackBarTest from "./components/tests/SnackBarTest";
// import InviteUser from "./components/tests/InviteUser";
// import GenerateKeys from "./components/tests/GenerateKeys";
// import MailTest from "./components/tests/MailTest";

import { ACTIVITIES, NOTIFICATIONS, UPCOMING } from "./config/links";

// import { DEFAULT_USER_SETTINGS } from './config/settings';

function App(props: any) {
  const { auth, profile, getCurrentUser } = props;
  // const [userSettings, setUserSettings] = useState(DEFAULT_USER_SETTINGS);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  let selectedTheme = light;
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    selectedTheme = dark;
  }

  if (profile) {
    if (profile.settings && profile.settings.theme) {
      switch (profile.settings.theme) {
        case "dark":
          selectedTheme = dark;
          break;
        case "light":
          selectedTheme = light;
          break;
        case "indigo":
          selectedTheme = indigo;
          break;
        case "teal":
          selectedTheme = teal;
          break;

        default:
          selectedTheme = light;
          break;
      }
    }
    // if (!profile.settings) {
    //   updateUserSettings(userSettings);
    // }
  }

  const navBar =
    auth && auth.uid && auth.emailVerified ? (
      <div>
        <NavBar />
      </div>
    ) : (
      <div></div>
    );

  // const verification =
  //   auth && auth.uid && auth.emailVerified === false ? (
  //     <div>
  //       <NeedVerification />{" "}
  //     </div>
  //   ) : (
  //     <div></div>
  //   );

  return (
    <BrowserRouter>
      <StylesProvider injectFirst>
        <ThemeProvider theme={selectedTheme}>
          <BrowserRouter>
            <div id="app">
              <MainBar />
              {navBar}
              {/* {verification} */}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/myhome" component={MyHome} />
                <Route path="/myinstructions" component={MyInstructions} />
                <Route path={ACTIVITIES} component={Activities} />
                <Route path={UPCOMING} component={Upcoming} />
                <Route path="/messages" component={Messages} />
                <Route path="/signin" component={LogIn} />
                <Route path="/signup/:activityId" component={SignUpAndAddMe} />
                <Route path="/signup" component={SignUp} />
                <Route path="/profile" component={Profile} />
                <Route path="/settings" component={Settings} />
                <Route path="/need-verification" component={NeedVerification} />
                <Route path="/add-user-details" component={AddUserDetails} />
                <Route
                  path="/reset-password-request"
                  component={ResetPasswordRequest}
                />
                <Route path={ACTIVITIES} component={Activities} />
                <Route path={NOTIFICATIONS} component={Notifications} />
                <Route path={UPCOMING} component={Upcoming} />
                <Route path="/add-activity" component={AddActivity} />
                <Route
                  path="/add-participant/:activityId"
                  component={AddParticipant}
                />
                <Route
                  path="/edit-participant/:participantId/:activityId"
                  component={EditParticipant}
                />
                <Route
                  path="/activity-events/:activityId"
                  component={ActivityEvents}
                />
                <Route
                  path="/activity-participants/:activityId"
                  component={ActivityParticipants}
                />
                <Route
                  path="/activity-participations/:activityId"
                  component={ActivityParticipations}
                />
                <Route
                  path="/edit-activity/:activityId"
                  component={EditActivity}
                />
                <Route path="/add-event/:activityId" component={AddEvent} />
                <Route
                  path="/edit-event/:eventId/:activityId"
                  component={EditEvent}
                />
                <Route
                  path="/event-details/:eventId/:activityId"
                  component={EventDetails}
                />
                <Route path="/features" component={Features} />
                <Route path="/using-presyes" component={UsingPresYes} />
                <Route path="/notifications" component={Notifications} />
                <Route
                  path="/show-language/:langCode"
                  component={ShowLanguage}
                />
                <Route path="/show-language" component={ShowLanguage} />
                <Route path="/get-language/:langCode" component={GetLanguage} />
                <Route path="/rude-signout" component={RudeSignOut} />
                {/* <Route path="/firebase-test" component={FirebaseTest} />
                <Route path="/snackbar" component={SnackBarTest} />
                <Route path="/invite-user" component={InviteUser} />
                <Route path="/keys" component={GenerateKeys} />
                <Route path="/mailtest" component={MailTest} /> */}
              </Switch>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </StylesProvider>
    </BrowserRouter>
  );
}

const mapStateToProps = (state: any) => {
  // devLog("App.tsx - state - ", state);
  testLog("App.tsx - state - ", state);
  testLog("device => ", navigator.userAgent);
  testLog("platform => ", navigator.platform);
  return {
    currentUser: state.auth.currentUser,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    state: state
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCurrentUser: () => dispatch(getCurrentUser())
    // updateUserSettings: (userSettings: any) =>
    //   dispatch(updateUserSettings(userSettings))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
