import React, { useState } from 'react';
import { connect } from 'react-redux';

// styling and components
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// configs and utility function
// import Label from '../layout/Label';
import { modalButtonStyle } from '../../config/styles';
import { getLabel } from '../../config/labels';

function ConfirmDeleteDialog(props: any) {
  const {
    profile,
    open,
    handleClose,
    handleConfirm,
    name,
    description
  } = props;
  const [language, setLanguage] = useState(window.navigator.language);
  const [count, setCount] = useState(0);

  if (count < 1) {
    let userLanguage = window.navigator.language;
    if (profile && profile.settings && profile.settings.language) {
      userLanguage = profile.settings.language;
      setLanguage(userLanguage);
    }
    setCount(count + 1);
  }

  const StyledButton = styled(Button)`
    background: ${(props: any) => props.theme.colors.primaryVariant};
    color: ${(props: any) => props.theme.colors.onBackground};
    ${modalButtonStyle}
  `;

  const StyledBackgroundDiv = styled('div')`
    background-color: ${(props: any) => props.theme.colors.primaryLight};
  `;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="title"
      aria-describedby="description"
    >
      <StyledBackgroundDiv>
        <DialogTitle id="title">{getLabel(name, language)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {getLabel(description, language)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleClose} autoFocus>
            {getLabel('cancel', language)}
          </StyledButton>
          <StyledButton onClick={handleConfirm}>
            {getLabel('delete', language)}
          </StyledButton>
        </DialogActions>
      </StyledBackgroundDiv>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(ConfirmDeleteDialog);
