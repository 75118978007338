import React from 'react';
import styled from 'styled-components';
import { tileParticipantStyle } from "../../config/styles";
import { getTileSettings } from '../../config/settings';

export default function ParticipationsAmountTile(props: any): JSX.Element {
  const { amount, tileStyle } = props;
  let tileSettings: any = getTileSettings(tileStyle);

  const StyledTile = styled("div")`
    ${tileParticipantStyle}

    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    font-size: ${tileSettings.fontSize};
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    border-radius: ${tileSettings.tileRadius};
    color: ${(props: any) => props.theme.colors.tileColor};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  return (
    <StyledTile>
      <text>{amount}</text>
    </StyledTile>
  );
}
