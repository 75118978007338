export const nlGettingStartedTexts = [
         {
           header: "Activiteiten",
           text: "Maak een activiteit aan.",
           icon: "activities",
           image: ""
         },
         {
           header: "Deelnemers",
           text:
             "Nodig deelnemers uit met hun mailadres of voeg ze toe als ze al zijn geregistreerd.",
           icon: "participants",
           image: ""
         },
         {
           header: "Events",
           text: "Voeg events toe aan je activiteit.",
           icon: "events",
           image: ""
         },
         {
           header: "En dan...",
           text:
             "Kan iedereen afwezigheid of aanwezigheid melden met een druk op de knop. Heb je overzicht over wie wanneer aanwezig is. Zien wanneer deelnemers zich af- of aanmelden. Berichten voor deelnemers aan een event insturen. Zien wat je volgende events zijn. En je kunt deelnemers, events toevoegen, wijzigen of verwijderen. ",
           icon: "next",
           image: ""
         },
         {
           header: "Vind je dit leuk?",
           text: "Deel het met anderen!",
           icon: "share",
           image: ""
         }
       ];

