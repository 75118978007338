import React from 'react';
import { connect } from 'react-redux';

import TransformIcon from '@material-ui/icons/Transform';

import styled from 'styled-components';
import { tileParticipantStyle } from "../../config/styles";

import { updateUserSettings  } from '../../store/actions/userActions';
// import { DEFAULT_USER_SETTINGS, getLanguageCode } from '../../config/settings';

import { getTileSettings } from '../../config/settings';

function ChangeAxisTile(props: any): JSX.Element {
  // const { profile, tileStyle } = props;
    const { tileStyle } = props;
  let tileSettings: any = getTileSettings(tileStyle);

  // const handleClick = () => {
  //   if (profile && profile.settings && profile.settings.xy) {
  //     let newSettings = profile.settings;
  //     if (newSettings.xy.x === 'row') {
  //       newSettings.xy = { x: 'column', y: 'row' };
  //     } else {
  //       newSettings.xy = { x: 'row', y: 'column' };
  //     }
  //     props.updateUserSettings(newSettings);
  //   } else if (profile) {
  //     const newSettings = DEFAULT_USER_SETTINGS;
  //     newSettings.language = getLanguageCode(window.navigator.language);
  //     newSettings.xy = { x: 'column', y: 'row' };
  //     props.updateUserSettings(newSettings);
  //   }
  // };

  const StyledTile = styled("div")`
    ${tileParticipantStyle}
    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    font-size: 12px;
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    border-radius: ${tileSettings.tileRadius};
    font-weight: bold;
    color: ${(props: any) => props.theme.colors.tileColor};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  const StyledTransformIcon = styled(TransformIcon)`
    color: ${(props: any) => props.theme.colors.tileColor};
    width: ${tileSettings.iconWidth};
    height: ${tileSettings.iconHeight};
    cursor: pointer;
    cursor: hand;
  `;

  return (
    <StyledTile>
      <StyledTransformIcon />
    </StyledTile>
  );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserSettings: (newSetttings: any) =>
      dispatch(updateUserSettings(newSetttings))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeAxisTile);
