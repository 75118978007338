import React, { useState } from "react";
// import { connect } from "react-redux";

import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { getText } from "../../config/labels";

const StyledSnackbar = styled(Snackbar)``;

const MessageSnackBar = (props: any) => {
  const { message, duration, language, setShowMessage } = props;
  const [open, setOpen] = useState(true);

  let messageText = message;
  if (language) {
    messageText = getText(message, language);
  }

  let autoHideDuration = 3000;
  if (duration) {
    autoHideDuration = duration;
  }

  const handleClose = () => {
    setShowMessage(false);
    setOpen(false);
  };

  if (open) {
    return (
      <StyledSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{messageText}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  } else {
    return <div></div>;
  }
};

export default MessageSnackBar;
