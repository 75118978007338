import React from "react";
// styling and related components
import styled from "styled-components";
import Card from "@material-ui/core/Card";
// Presyes screens and components and actions
import ParticipationParticipantTile from "../participations/ParticipationParticipantTile";
// import { devLog } from '../../utils'

import {
  cardStyle,
  wrapperStyle
} from "../../config/styles";
const StyledBaseCard = styled(Card)`
  ${cardStyle};
  padding-left: 60px;
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
  &&&&& {
    padding-top: 15px;
  }
`;
const StyledFlexCard = styled(StyledBaseCard)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
  padding-top: 15px;
  padding-bottom: 15px;
`;
const StyledWrapper = styled("div")`
    ${wrapperStyle}
    background-color: ${(props: any) => props.theme.colors.background};
  `;

const ParticipantsCard = (props: any) => {
  const {
    activityAdmins,
    participants,
    participations,
  } = props;

    // React.useEffect(() => {
    //   devLog("ParticipantsCard mounts");
    //   return () => {
    //     devLog("ParticipantsCard unmounts ");
    //   };
    // }, []);

  if (participants && participations) {
    // let sum: number = 0;
    // participations.forEach((participation: any) => {
    //   if (
    //     participation &&
    //     participation.presence &&
    //     !participation.supportOnly
    //   ) {
    //     sum = sum + 1;
    //   }
    // });

    const filteredParticipants = participants.filter((participant: any) => {
      return !participant.supportOnly;
    });

    const filteredSupporters = participants.filter((participant: any) => {
      return participant.supportOnly;
    });

    const tileStyle = window.innerWidth < 640 ? "narrow" : "wide";

    const supporters =
      filteredSupporters.length > 0 ? (
        <StyledFlexCard>
          {filteredSupporters.map((participant: any) => {
            const participationOfParticipant: any = participations.filter(
              (participation: any) => {
                return (
                  participation.participantId === participant.participantId
                );
              }
            );
            return (
              <span>
                <ParticipationParticipantTile
                  participation={participationOfParticipant[0]}
                  participant={participant}
                  activityAdmins={activityAdmins}
                  tileStyle={tileStyle}
                />
              </span>
            );
          })}
        </StyledFlexCard>
      ) : (
        <div />
      );

    return (
        <StyledWrapper>
          <StyledFlexCard>
            {filteredParticipants.map((participant: any) => {
              const participationOfParticipant: any = participations.filter(
                (participation: any) => {
                  return (
                    participation.participantId === participant.participantId
                  );
                }
              );
              return (
                <span>
                  <ParticipationParticipantTile
                    participation={participationOfParticipant[0]}
                    participant={participant}
                    activityAdmins={activityAdmins}
                    tileStyle={tileStyle}
                  />
                </span>
              );
            })}
          </StyledFlexCard>
          {supporters}
        </StyledWrapper>
    );
  } else {
    return <div></div>;
  }
};
export default ParticipantsCard;
