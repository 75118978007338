import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { rudeSignOut } from "../../store/actions/authActions";

const RudeSignOut = (props: any) => {
  const { auth, rudeSignOut } = props;

  React.useEffect(() => {
    rudeSignOut(auth);
  });

  return <Redirect to="/" />;
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    rudeSignOut: () => dispatch(rudeSignOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RudeSignOut);
