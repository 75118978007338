import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
// import MaskedInput from 'react-text-mask'

import { addActivity } from "../../store/actions/activityActions";
import SpecialField from "../fields/SpecialField";
import { getId, testLog } from "../../utils/index";
import { getValueFromField } from "../../utils/htmlUtils";
import Label from "../layout/Label";
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";
import { Activity, Participant } from "../../config/types";
import { HISTORY_BACK_INTERVAL } from "../../config/settings";

const AddActivity = (props: any): JSX.Element => {
  const { auth, profile, addActivity } = props;

  if (!auth.uid) return <Redirect to="/signin" />;

  const handleAdd = async () => {
    const activityName = getValueFromField("activity-name");
    const activityDescription = getValueFromField("activity-description");
    const defaultLocation = getValueFromField("default-location");
    const defaultAddress = getValueFromField("default-address");
    // const defaultDuration = getValueFromField('default-duration');
    const defaultPresenceLimit = parseInt(
      getValueFromField("default-presence-limit")
    );
    const admins = [];
    admins.push(auth.uid);

    if (activityName) {
      const activityId = getId("");
      const activity = {
        activityId: activityId,
        activityName: activityName,
        admins: admins,
        description: activityDescription,
        defaultLocation: defaultLocation,
        defaultAddress: defaultAddress,
        defaultDuration: 60,
        defaultPresenceLimit: defaultPresenceLimit
      };
      const participant = {
        userId: auth.uid,
        participantId: `${profile.initials}${auth.uid}`,
        activityId: activityId,
        admin: true,
        initials: profile.initials,
        firstName: profile.firstName,
        lastName: profile.lastName
      };
      addActivity(activity, participant);
      setTimeout(() => window.history.back(), HISTORY_BACK_INTERVAL);
    } else {
      testLog("Activity data not correct");
    }
  };

  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledCard raised>
          <form>
            <SpecialField name="activity-name" />
            <SpecialField name="activity-description" />
            <SpecialField name="default-location" />
            <SpecialField name="default-address" />
            <SpecialField name="default-presence-limit" />
            <StyledButton onClick={handleAdd}>
              <Label label={"add-activity"} />
            </StyledButton>
          </form>
        </StyledCard>
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activityError: state.activity.activityError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addActivity: (activity: Activity, participant: Participant) =>
      dispatch(addActivity(activity, participant))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddActivity);

//  Styles

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;

const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  color: ${(props: any) => props.theme.colors.onBackground};
  ${buttonStyle}
`;

const StyledCard = styled(Card)`
  ${cardStyle}
  background: ${(props: any) => props.theme.colors.primaryLight};
`;

const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px
`;
