//************************************************************** */
// *******SETUP FOR DEV - PROD *********************************
export const FIREBASE: string = "prod"; // values are dev - prod - test
export const PROJECT_ID = "presyes-prod"; // values presyes-dev, presyes-test, presyes-prod

// logging
export const PROD: boolean = true;
export const TEST: boolean = false;
export const DEV: boolean = false;

export const VERSION = "FR28";

// NOTES 
// Also check the config in the server functions
// Also update the version number in the serviceWorker.ts

// **************************************************************

// ****** LIMITS **********************************************

export const MESSAGE_LIMIT = 500;

// export const TESTRUN = false; // true or false

// ****** DEFAULTS **********************************************

export const TITLE = "presYes";

export const DEFAULT_LANGUAGE = "EN";
export const DEFAULT_THEME = "light";
export const DEFAULT_XY = { x: "row", y: "column" };
export const DEFAULT_ALLOW_NOTIFICATIONS = true;
export const HISTORY_BACK_INTERVAL = 150;
export const SNACKBAR_INTERVAL = 3000;

// ------------ USER -------------

export const DEFAULT_USER_SETTINGS = {
  language: "EN",
  xy: { x: "row", y: "column" },
  theme: "light",
  allowNotifications: true,
  history: true,
  subscription: "free",
  tileStyle: "wide", // wide or square
  nameDisplay: "full" // initials, first, full
};

// ------------ LANGUAGE -------------

export const getLanguageCode = (code: string) => {
  let languageCode;
  switch (code) {
    case "de":
      languageCode = "DE";
      break;
    case "en":
      languageCode = "EN";
      break;
    case "fr":
      languageCode = "FR";
      break;
    case "it":
      languageCode = "IT";
      break;
    case "nl":
      languageCode = "NL";
      break;
    default:
      languageCode = "EN";
      break;
  }
  return languageCode;
};

// ------------ Tile size settings -------------

const tileSettingsNarrow = {
  lineUpWidth: "100px",
  lineUpHeight: "50px",
  tileWidth: "95px",
  tileHeight: "45px",
  fontSize: "10px",
  tileRadius: "3%",
  buttonWidth: "40px",
  buttonHeight: "40px",
  iconWidth: "25x",
  iconHeight: "25px"
};

const tileSettingsWide = {
  lineUpWidth: "110px",
  lineUpHeight: "60px",
  tileWidth: "100px",
  tileHeight: "50px",
  fontSize: "11px",
  tileRadius: "3%",
  buttonWidth: "100px",
  buttonHeight: "50px",
  iconWidth: "35x",
  iconHeight: "35px"
};

const tileSettingsSquare = {
  lineUpWidth: "60px",
  lineUpHeight: "60px",
  tileWidth: "50px",
  tileHeight: "50px",
  fontSize: "11px",
  tileRadius: "10%",
  buttonWidth: "50px",
  buttonHeight: "50px",
  iconWidth: "30px",
  iconHeight: "30px"
};

const tileSettingsFlat = {
  lineUpWidth: "120px",
  lineUpHeight: "25px",
  tileWidth: "115px",
  tileHeight: "23px",
  fontSize: "9px",
  tileRadius: "3%",
  buttonWidth: "115px",
  buttonHeight: "23px",
  iconWidth: "20x",
  iconHeight: "20px"
};

const tileSettingsTiny = {
  lineUpWidth: "37px",
  lineUpHeight: "20px",
  tileWidth: "37px",
  tileHeight: "18px",
  fontSize: "8px",
  tileRadius: "3%",
  buttonWidth: "37px",
  buttonHeight: "18px",
  iconWidth: "15x",
  iconHeight: "15px"
};

export const getTileSettings = (tileStyle: string) => {
  switch (tileStyle) {
    case "wide":
      return tileSettingsWide;
    case "flat":
      return tileSettingsFlat;
    case "square":
      return tileSettingsSquare;
    case "tiny":
      return tileSettingsTiny;
    case "narrow":
      return tileSettingsNarrow;
    default:
      return tileSettingsWide;
  }
};
