import React from "react";
import { connect } from "react-redux";

// import ParticipationTile from "../participations/ParticipationTile";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";

import styled from "styled-components";
import { listItemFieldTextStyle } from "../../config/styles";

// import Label from '../layout/Label';
import { getEventParticipation } from "../../store/actions/eventActions";
import { getLabel } from "../../config/labels";
import { timestampToDateString } from "../../utils";
import { getNavigatorLanguage } from "../../language";

const StyledField = styled(Typography)`
  ${listItemFieldTextStyle};
  margin-left: 25px;
  color: ${(props: any) => props.theme.colors.onBackground};
`;

// const StyledListItemText = styled(ListItemText)`
//   color: ${(props: any) => props.theme.colors.onBackground};
// `;

const StyledDateField = styled(Typography)`
  ${listItemFieldTextStyle};
  margin-left: 20px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${(props: any) => props.theme.colors.background};
  margin-bottom: 2px;
  opacity: 1;
`;

const StyledAvatar = styled(Avatar)`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 800;
`;

function Occurrence(props: any) {
  const { profile, occurrence } = props;

  if (occurrence) {
    let userLanguage = getNavigatorLanguage();
    if (profile && profile.settings && profile.settings.language) {
      userLanguage = profile.settings.language;
    }

    let dateTime = "";
    if (occurrence.occurrenceDateTime) {
      dateTime = timestampToDateString(occurrence.occurrenceDateTime.seconds);
    }

    let text = "";

    if (
      occurrence.occurrenceTarget &&
      occurrence.occurrenceTarget === "participation" &&
      occurrence.occurrenceType &&
      occurrence.occurrenceType === "update"
    ) {
      const person: string = occurrence.changedForInitials
        ? occurrence.changedForInitials
        : null;
      const action: string = getLabel("update", userLanguage);
      const result: string = occurrence.changedParticipationTo
        ? getLabel("is-present", userLanguage)
        : getLabel("is-absent", userLanguage);

      text = `${action} - ${person} ${result}`;
    }

    if (
      occurrence.occurrenceTarget &&
      occurrence.occurrenceTarget === "event" &&
      occurrence.occurrenceType &&
      occurrence.occurrenceType === "add"
    ) {
      const event: string = occurrence.eventName ? occurrence.eventName : null;
      const action: string = getLabel("added", userLanguage);
      text = `${action} - ${event}`;
    }

        if (
          occurrence.occurrenceTarget &&
          occurrence.occurrenceTarget === "event" &&
          occurrence.occurrenceType &&
          occurrence.occurrenceType === "update"
        ) {
          const event: string = occurrence.eventName
            ? occurrence.eventName
            : null;
          const action: string = getLabel("update", userLanguage);
          text = `${action} - ${event}`;
        }

    return (
      <div>
        {/* <StyledDayField> {dayString}</StyledDayField> */}
        <ListItem>
          <StyledAvatar>{occurrence.changedByInitials}</StyledAvatar>
          <StyledDateField>{dateTime}</StyledDateField>
          <StyledField>{text}</StyledField>
          {/* <StyledListItemText children={listDetails} /> */}
        </ListItem>
        <StyledDivider />
      </div>
    );
  } else {
    return null;
  }

  // const listDetails = (
  //   <div>
  //     <StyledHeader>{event.eventName}</StyledHeader>
  //     {startDateTime}
  //     {location}
  //   </div>
  // );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    participation: state.event.participation
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventParticipation: (eventId: string, uid: string) =>
      dispatch(getEventParticipation(eventId, uid))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Occurrence);
