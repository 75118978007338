import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import {
  getActivity,
  updateActivity,
  deleteActivity
} from "../../store/actions/activityActions";
import SpecialField from "../fields/SpecialField";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";

import { getValueFromField } from "../../utils/htmlUtils";
import { testLog } from "../../utils";
import { HISTORY_BACK_INTERVAL } from "../../config/settings";
import Label from "../layout/Label";
// import { getLabel } from '../../config/labels';
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const EditActivity = (props: any) => {
  const {
    auth,
    activity,
    match,
    getActivity,
    deleteActivity,
    updateActivity
  } = props;
  const { activityId } = match.params;
  const { uid } = auth;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (uid && activityId) {
      getActivity(activityId);
    }
  }, [uid, activityId, getActivity]);

  if (!auth.uid) return <Redirect to="/signin" />;

  const handleUpdate = async () => {
    const activityName = getValueFromField("activity-name");
    const description = getValueFromField("activity-description");
    const defaultLocation = getValueFromField("default-location");
    const defaultAddress = getValueFromField("default-address");
    const defaultPresenceLimit = getValueFromField("default-presence-limit");
    const defaultDuration = getValueFromField("default-duration");

    if (activityName) {
      const newActivity = {
        ...activity,
        activityName: activityName,
        description: description,
        defaultLocation: defaultLocation,
        defaultAddress: defaultAddress,
        defaultPresenceLimit: defaultPresenceLimit,
        defaultDuration: defaultDuration
      };
      await updateActivity(newActivity);
      setTimeout(() => window.history.back(), HISTORY_BACK_INTERVAL);
    } else {
      testLog("Activity data not correct");
    }
  };

  const handleDeleteActivity = async () => {
    if (activity && activity.activityId) {
      await deleteActivity(activity.activityId);
      window.history.back();
    } else {
      testLog("Activity data not correct");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleDeleteActivity();
    setOpen(false);
  };

  let enableIt = false;
  if (auth && auth.uid && activity && activity.admins) {
    if (activity.admins.includes(auth.uid)) {
      enableIt = true;
    }
  }

  const removeButton = enableIt ? (
    <div>
      <StyledButton onClick={handleClickOpen}>
        <Label label={"delete-activity"} />
      </StyledButton>
      <ConfirmDeleteDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        name={"confirm-delete"}
        description={"confirm-delete-description"}
      />
    </div>
  ) : (
    <div></div>
  );

  if (activity) {
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard raised>
            <form>
              <SpecialField
                name="activity-name"
                defaultValue={activity.activityName}
              />
              <SpecialField
                name="activity-description"
                defaultValue={activity.description}
              />
              <SpecialField
                name="default-location"
                defaultValue={activity.defaultLocation}
              />
              <SpecialField
                name="default-address"
                defaultValue={activity.defaultAddress}
              />
              <SpecialField
                name="default-presence-limit"
                defaultValue={activity.defaultPresenceLimit}
              />
              <SpecialField
                name="default-duration"
                defaultValue={activity.defaultDuration}
              />
              <StyledButton onClick={handleUpdate}>
                <Label label={"update-activity"} />
              </StyledButton>
              {removeButton}
            </form>
          </StyledCard>
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    activity: state.activity.activity
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivity: (activityId: string) => dispatch(getActivity(activityId)),
    updateActivity: (activity: any) => dispatch(updateActivity(activity)),
    deleteActivity: (activityId: string) => dispatch(deleteActivity(activityId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditActivity);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px
  `;
const StyledWrapper = styled("div")`
    ${wrapperStyle}
    background-color: ${(props: any) => props.theme.colors.background};
  `;
const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  color: ${(props: any) => props.theme.colors.button};
  ${buttonStyle}
`;
const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.secundary};
  ${cardStyle}
`;
