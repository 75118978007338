import React from "react";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import {
  getEventHistory,
  clearHistoryState
} from "../../store/actions/historyActions";
import Occurrence from "./Occurrence";

import styled from "styled-components";
import Label from "../layout/Label";
// import { devLog } from "../../utils";
import {
  cardStyle,
  cardFieldTextStyle,
  wrapperStyle
} from "../../config/styles";

const StyledBaseCard = styled(Card)`
  ${cardStyle};
  padding-left: 60px;
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
`;

const StyledHistoryCard = styled(StyledBaseCard)`
  @media (min-width: 641px) {
    margin-bottom: 20px;
  }
  @media (max-width: 640px) {
    margin-bottom: 60px;
  }
`;

const StyledField = styled(Typography)`
  ${cardFieldTextStyle};
  margin-left: 20px;
`;

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;

function HistoryCard(props: any) {
  const {
    eventId,
    auth,
    eventHistory,
    getEventHistory,
    clearHistoryState
  } = props;
  const { uid } = auth;

  React.useEffect(() => {
    if (uid && eventId) {
      getEventHistory(eventId);
    }
    return () => {
      clearHistoryState();
    };
  }, [uid, eventId, getEventHistory, clearHistoryState]);

  if (eventHistory) {
    const history =
      eventHistory && eventHistory.length > 0 ? (
        <StyledWrapper>
          <StyledHistoryCard>
            <List>
              {eventHistory.map((occurrence: any) => (
                <Occurrence occurrence={occurrence} />
              ))}
            </List>
          </StyledHistoryCard>
        </StyledWrapper>
      ) : (
        <StyledWrapper>
          <StyledHistoryCard>
            {" "}
            <StyledField>
              <Label label="no-history" />
            </StyledField>{" "}
          </StyledHistoryCard>
        </StyledWrapper>
      );

    return <div>{history}</div>;
  } else {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    eventHistory: state.history.eventHistory
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventHistory: (eventId: string) => dispatch(getEventHistory(eventId)),
    clearHistoryState: () => dispatch(clearHistoryState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCard);
