import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import Avatar from "@material-ui/core/Avatar";

import AddEventImage from "../../img/add-event.png";
import AddEventImageMobile from "../../img/add-event-mobile.png";

import {
  iconGettingStartedStyle,
  instructionAvatarStyle,
  instructionImageStyle,
  instructionTextStyle,
  instructionTextWrapper
} from "../../config/styles";

import { getInstructionText } from "../../language";

const StyledAvatar = styled(Avatar)`
  ${instructionAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledImage = styled("img")`
  ${instructionImageStyle}
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledIcon = styled(EventOutlinedIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledTextWrapper = styled("div")`
  ${instructionTextWrapper}
`;

export default function AddEvent(props: any) {
  const { language, mobileSize } = props;

  if (language) {
    const txt = getInstructionText("addEvent", language);

        const txtBlock = (
          <StyledTextWrapper>
            <StyledAvatar>
              <StyledIcon />
            </StyledAvatar>
            <StyledText>{txt}</StyledText>
          </StyledTextWrapper>
        );

    const blockToShow = mobileSize ? (
      <div>
        {txtBlock}
        <StyledImage src={AddEventImageMobile} />
      </div>
    ) : (
      <div>
        {txtBlock}
        <StyledImage src={AddEventImage} />
      </div>
    );

    return <div>{blockToShow}</div>;
  } else return null;
}
