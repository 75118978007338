import React from "react";

// import styled from "styled-components";
// import { cardStyle, logoStyle, wrapperStyle } from '../../config/styles';

// import { consLog } from ;
import ParticipationsEventHeaderTile from "./ParticipationsEventHeaderTile";
import ParticipationTile from "./ParticipationTile";
import ParticipationsAmountTile from "./ParticipationsAmountTile";
// import { tileSettingsWide, tileSettingsSquare } from '../../config/settings';

export default function ParticipationsLineUp(props: any): JSX.Element {
  const { participations, tileStyle } = props;

  let sum: number = 0;
  participations.forEach((participation: any) => {
    if (participation && participation.presence && !participation.supportOnly) {
      sum = sum + 1;
    }
  });

  // const StyledSnapContainer = styled("div")`
  //   scroll-snap-type: y mandatory;
  // `;

  // const StyledSnapColumn = styled("div")`
  //   scroll-snap-align: start;
  // `;

  // const StyledEventHeader = styled(ParticipationsEventHeaderTile)`
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  // `;
  return (
    <div>
      <ParticipationsEventHeaderTile
        participation={participations[0]}
        tileStyle={tileStyle}
      />
      <ParticipationsAmountTile amount={sum} tileStyle={tileStyle} />
      {participations.map((participation: any) => {
        return (
          <ParticipationTile
            participation={participation}
            tileStyle={tileStyle}
          />
        );
      })}
    </div>
  );
}
