import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import { getText } from "../../config/labels";
import Label from "../layout/Label";

const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
  margin-left: 50px;
`;

const StyledDiv = styled("div")`
  background-color: ${(props: any) => props.theme.colors.surface};
  color: ${(props: any) => props.theme.colors.onSurface};

  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 15px;
  }
  @media (max-width: 960px) {
    font-size: 14px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
  @media (max-width: 412px) {
    font-size: 13px;
  }

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 40px;
`;

function CookieBeam(props: any) {
  const { message, profile, setShowMessage } = props;
  const [open, setOpen] = useState(true);

  let userLanguage = window.navigator.language.toUpperCase().substring(0,2);
  if (profile && profile.settings && profile.settings.language) {
    userLanguage = profile.settings.language;
  }
  const boxText = getText(message, userLanguage);

  const handleOkay = () => {
    setOpen(false);
    setShowMessage(false);
  };

  if (open) {
    return (
      <StyledDiv>
        <div>{boxText}</div>
        <StyledButton onClick={handleOkay}>
          <Label label={"ok"} />
        </StyledButton>
      </StyledDiv>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(CookieBeam);
