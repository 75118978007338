import { FIREBASE } from "../config/settings";
import { getText } from "../config/labels";
import { testLog } from "./index";

const getUrl = () => {
  let url;
  switch (FIREBASE) {
    case "dev":
      url = "https://presyes-dev.web.app";
      break;
    case "test":
      url = "https://presyes-test.web.app";
      break;
    case "prod":
      url = "https://presyes.com";
      break;
    default:
      url = "http://localhost:3000";
      break;
  }
  return url;
};

const textEN = {
  invite: "wants to add you to activity",
  signup: "Please signup at"
};

const textNL = {
  invite: "wil je uitnodigen voor activiteit",
  signup: "Registreer aub bij"
};

const getTextLine = (property: string, language: string) => {
  let textObject: any = textEN;
  switch (language) {
    case "NL":
      textObject = textNL;
      break;
    default:
      textObject = textEN;
      break;
  }
  const textLine = `${textObject[property]}`;
  return textLine;
};

const getEmailText = (participant: any, inviter: any): string => {
  const { activityId } = participant;
  const { inviterDisplayName, language, activityName } = inviter;
  const url = `${getUrl()}/signup/${activityId}`;

  const line1 = `${inviterDisplayName} ${getTextLine(
    "invite",
    language
  )} ${activityName.toUpperCase()}.`;
  const line2 = `${getTextLine("signup", language)} ${url}`;
  const emailText = `${line1} ${line2}`;
  return emailText;
};

const getEmailHtml = (participant: any, inviter: any): string => {
  const { activityId } = participant;
  const { inviterDisplayName, language, activityName } = inviter;
  const url = `${getUrl()}/signup/${activityId}`;

  const line1 = `${inviterDisplayName} ${getTextLine(
    "invite",
    language
  )} ${activityName.toUpperCase()}.<br/>`;
  const line2 = `${getTextLine(
    "signup",
    language
  )} <a href='${url}'>${url}</a>`;
  const emailText = `${line1} ${line2}.`;
  return emailText;
};

export const getEmailBody = (participant: any, inviter: any): any => {
  testLog("getEmailBody - inviter ", inviter);
  const { email } = participant;
  const { language } = inviter;

  const from = "messenger@presyes.com";
  const bcc = "messenger@presyes.com";
  const to = email;

  const subject = `${getText("invited-to-presyes", language)}`;
  const emailText = `${getEmailText(participant, inviter)}`;
  const emailHtml = `${getEmailHtml(participant, inviter)}`;

  const body = {
    from: from,
    to: to,
    bcc: bcc,
    subject: subject,
    text: emailText,
    html: emailHtml
  };
  testLog("getEmailBody - body ", body);
  return body;
};
