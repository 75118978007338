const initState = {
  eventHistory: null,
  historyError: null
};

const historyReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_EVENT_HISTORY_ERROR":
      return {
        ...state,
        historyError: "Getting history failed"
      };

    case "GET_EVENT_HISTORY_SUCCESS":
      return {
        ...state,
        eventHistory: action.eventHistory,
        historyError: null
      };

    case "ADD_OCCURRENCE_ERROR":
      return {
        ...state,
        historyError: "Adding occurrence failed"
      };

    case "ADD_OCCURRENCE_SUCCESS":
      return {
        ...state,
        historyError: null
      };

    case "CLEAR_HISTORY_STATE":
      return {
        ...state,
        eventHistory: null,
        historyError: null
      };

    default:
      return state;
  }
};

export default historyReducer;
