import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import { sendResetPasswordRequest } from "../../store/actions/authActions";
import SpecialField from "../fields/SpecialField";
import TextBox from "../fields/TextBox";

import { devLog } from "../../utils";
import { getValueFromField } from "../../utils/htmlUtils";
import Label from "../layout/Label";
// import { getText } from '../../config/labels';
import {
  // textFieldStyle,
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const ResetPasswordRequest = (props: any) => {
  const { sendResetPasswordRequest } = props;
  const [showSignIn, setShowSignIn] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    emailError: false
  });

  const handleRequest = async () => {
    devLog("ResetPassword started");
    let newFormValues = {
      email: "",
      emailError: false
    };

    let formError = false;
    newFormValues.email = getValueFromField("email");
    if (!newFormValues.email) {
      newFormValues.emailError = true;
      formError = true;
    }

    if (formError) {
      setFormValues(newFormValues);
    } else {
      const email = newFormValues.email;
      // alert(email);
      if (email) {
        await sendResetPasswordRequest(email);
        setTimeout(() => {
          setShowSignIn(true);
        }, 500);
      }
    }
  };

  if (showSignIn) {
    return <Redirect to="/signin" />;
  }

  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledCard raised>
          <StyledTextBox text="reset-password-request" />
          <form>
            <SpecialField
              name="email"
              defaultValue={formValues.email}
              error={formValues.emailError}
            />
          </form>
          <StyledButton onClick={handleRequest}>
            <Label label={"submit"} />
          </StyledButton>
        </StyledCard>
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    currentUser: state.auth.currentUser,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendResetPasswordRequest: (email: string) =>
      dispatch(sendResetPasswordRequest(email))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordRequest);

// Styles

const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle};
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px;
`;
const StyledTextBox = styled(TextBox)`
  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primary};
  ${buttonStyle}
`;

const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.secundary};
  ${cardStyle}
`;

const StyledWrapper = styled("div")`
  ${wrapperStyle}
  margin-top: 20px;
`;
