const initState = {
  eventMessages: null,
  userMessages: null,
  nrNewMessages: null,
  messageError: null
};

const messageReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_EVENT_MESSAGES_ERROR":
      return {
        ...state,
        messageError: "Getting messages from event failed"
      };

    case "GET_EVENT_MESSAGES_SUCCESS":
      return {
        ...state,
        eventMessages: action.eventMessages,
        messageError: null
      };

    case "GET_USER_MESSAGES_ERROR":
      return {
        ...state,
        messageError: "Getting messages from user failed"
      };

    case "GET_USER_MESSAGES_SUCCESS":
      return {
        ...state,
        userMessages: action.userMessages,
        messageError: null
      };

    case "ADD_MESSAGE_ERROR":
      return {
        ...state,
        messageError: "Adding message failed"
      };

    case "ADD_MESSAGE_SUCCESS":
      return {
        ...state,
        messageError: null
      };
    case "MESSAGE_CHECK_ERROR":
      return {
        ...state,
        messageError: "Message failed"
      };

    case "MESSAGE_CHECK_SUCCESS":
      return {
        ...state,
        nrNewMessages: action.nrNewMessages
      };

    case "CLEAR_MESSAGE_STATE":
      return {
        ...state,
        eventMessages: null,
        userMessages: null,
        nrNewMessages: null,
        messageError: null
      };

    default:
      return state;
  }
};

export default messageReducer;
