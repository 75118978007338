import React from 'react';
import styled from 'styled-components';
import StyledCard from '../layout/StyledBaseCard';
import Label from '../layout/Label';
import { backgroundDivStyle, h1Style } from "../../config/styles";

const StyledH1 = styled('h1')`
  ${h1Style}
`;

const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: 900px;
`;

const Notifications = () => {

  
  return (
    <StyledBackgroundDiv>
      <StyledCard
        node={
          <StyledH1>
            <Label label={'notifications'} />
          </StyledH1>
        }
      ></StyledCard>
    </StyledBackgroundDiv>
  );
};

export default Notifications;
