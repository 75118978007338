import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import JSONPretty from "react-json-pretty";
import styled from "styled-components";
import { getLanguageObject, getNavigatorLanguage } from "../../language";

const StyledDiv = styled("div")`
  padding-top: 110px;
  padding-bottom: 30px;
  width: 100%;
  background: #ffffff;
  color: #000000;
`;

function ShowLanguage(props: any) {
  const { auth, match } = props;
  const { uid } = auth;
  const { langCode } = match.params;

  if (!uid) return <Redirect to="/signin" />;

  // determine target language
  let language = "EN";
  if (langCode) {
    language = langCode.toUpperCase();
  } else {
    language = getNavigatorLanguage();
  }
  // get language object
  const targetLanguageObject = getLanguageObject(language);
  const sourceLanguageObject = getLanguageObject("EN");

  return (
    <StyledDiv>
      <JSONPretty id="target-language" data={targetLanguageObject} />
      <JSONPretty id="source-language" data={sourceLanguageObject} />
    </StyledDiv>
  );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(ShowLanguage);
