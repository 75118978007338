import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import GetStarted from "../instructions/GetStarted";
import HowTo from "../instructions/HowTo";
import styled from "styled-components";
import { backgroundDivStyle, logoStyle, getHeight } from "../../config/styles";
import { getLabel } from "../../config/labels";
// import { ReactComponent as Logo } from "../../img/presyes.svg";

const MyInstructions = (props: any) => {
  const { auth, profile } = props;

  if (!auth || !auth.uid) return <Redirect to="/signin" />;

  let language = window.navigator.language;
  let how = "";
  let features = "";
  if (profile && profile.settings && profile.settings.language) {
    language = profile.settings.language;
    how = `${getLabel("see-how", language)}`;
    features = `${getLabel("features", language)}`;
  }

  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledNavLink to="/features">
          <StyledH3>{features}</StyledH3>
        </StyledNavLink>
        <GetStarted language={language} />
        <StyledNavLink to="/using-presyes">
          <StyledH3>{how}</StyledH3>
        </StyledNavLink>
        <HowTo language={language} />
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(MyInstructions);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    min-height: ${getHeight()}px;
    getHeight()
  `;
const StyledH3 = styled(Typography)`
  font-size: 30px;
  font-weigth: 500;
  margin-top: 30px;
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledNavLink = styled(NavLink)`
  ${logoStyle};
  color: ${(props: any) => props.theme.colors.logo};
  text-decoration: underline grey;
`;
const StyledWrapper = styled("div")`
  width: 60%;
  @media (max-width: 640px) {
    width: 80%;
  }
`;
