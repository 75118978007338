import React from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
// import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { addFabIconStyle, wrapperStyle } from '../../config/styles';

export default function StyledAddFab(props: any) {
  // const AddFab = styled(Fab)`
  //   ${addFabStyle}
  //   background-color: ${(props: any) => props.theme.colors.secondary};
  //   color: ${(props: any) => props.theme.colors.onSecondary};
  // `;

  const StyledAddIcon = styled(AddIcon)`
    ${addFabIconStyle}
    color: ${(props: any) => props.theme.colors.onSecondary};
    background: {
      opacitiy: 0;
    }
  `;

  const StyledNavLink = styled(NavLink)`
    color: ${(props: any) => props.theme.colors.onPrimary};
  `;

  const StyledWrapper = styled('div')`
    ${wrapperStyle}
  `;

  return (
    <div>
      <StyledWrapper>
        <StyledNavLink to={props.url}>
          <StyledAddIcon />
        </StyledNavLink>
      </StyledWrapper>
    </div>
  );
}
