import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import styled from "styled-components";
import { logoStyle } from "../../config/styles";

import { updateParticipation } from "../../store/actions/eventActions";
import { getTileSettings } from "../../config/settings";

function ParticipationParticipantTile(props: any): JSX.Element {
  const {
    auth,
    profile,
    participation,
    participant,
    activityAdmins,
    tileStyle,
    updateParticipation
  } = props;
  const [presence, setPresence] = React.useState();

  let activityAdmin = false;
  if (activityAdmins && activityAdmins.includes(auth.uid)) {
    activityAdmin = true;
  }
  let myParticipation = false;
  if (participation && participation.userId === auth.uid) {
    myParticipation = true;
  }

  let tileSettings: any = getTileSettings(tileStyle);

  const handleClick = () => {
    if (participation) {
      if (participation.userId === auth.uid || activityAdmin) {
        participation.presence
          ? (participation.presence = false)
          : (participation.presence = true);
        updateParticipation(auth, profile, participation);
        // to enforce rerender of tile
        presence ? setPresence(false) : setPresence(true);
      }
    }
  };

  const StyledBackgroundTile = styled("span")`
    display: flex;
    flex-direction: row;
    width: 160px;
    margin: 2px;
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
  `;

  const StyledParticipantTile = styled("span")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: ${tileSettings.fontSize};
    font-weight: bold;

    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    color: ${(props: any) => props.theme.colors.tileColor};
    border: solid 0px;
    border-radius: ${tileSettings.tileRadius};
    border-color: black;
  `;

  const StyledButton = styled("span")`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${tileSettings.buttonWidth};
    height: ${tileSettings.buttonHeight};

    @media (max-width: 1280px) {
      margin-left: 0px;
    }
    @media (max-width: 960px) {
      margin-left: 10px;
    }
    @media (max-width: 640px) {
      margin-left: 20px;
    }
    @media (max-width: 412px) {
      margin-left: 20px;
    }
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0px;
    font-size: 14px;
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    color: ${(props: any) => props.theme.colors.tileColor};
    border: solid 0px;
    border-radius: 100%;
    border-color: black;
    font-weight: bold;
  `;

  const StyledCursorButton = styled(StyledButton)`
    cursor: pointer;
    cursor: hand;
  `;

  const StyledCheckIcon = styled(Check)`
    color: green;
    width: ${tileSettings.iconWidth};
    height: ${tileSettings.iconHeight};
  `;

  const StyledCloseIcon = styled(Close)`
    color: red;
    width: ${tileSettings.iconWidth};
    height: ${tileSettings.iconHeight};
  `;

  const StyledRoleText = styled("text")`
    font-weight: 600;
    font-style: italic;
  `;
  const StyledNameText = styled("text")`
    font-weight: 700;
  `;

  const StyledNavLink = styled(NavLink)`
    ${logoStyle};
    color: ${(props: any) => props.theme.colors.onBackground};
  `;

  const participantsUrl = `/activity-participants/${participant.activityId}`;
  const participantName = (
    <StyledNavLink to={participantsUrl}>
      <StyledParticipantTile>
        <StyledNameText>{participant.firstName}</StyledNameText>
        <StyledNameText>{participant.lastName}</StyledNameText>
        <StyledRoleText>{participant.role}</StyledRoleText>
      </StyledParticipantTile>
    </StyledNavLink>
  );

  if (participation && participant) {
    if (myParticipation || activityAdmin) {
      if (participation.presence) {
        return (
          <StyledBackgroundTile>
            {participantName}
            <StyledCursorButton onClick={handleClick}>
              <StyledCheckIcon />
            </StyledCursorButton>
          </StyledBackgroundTile>
        );
      } else {
        return (
          <StyledBackgroundTile>
            {participantName}
            <StyledCursorButton onClick={handleClick}>
              <StyledCloseIcon />
            </StyledCursorButton>
          </StyledBackgroundTile>
        );
      }
    } else {
      if (participation.presence) {
        return (
          <StyledBackgroundTile>
            {participantName}
            <StyledButton>
              <StyledCheckIcon />
            </StyledButton>
          </StyledBackgroundTile>
        );
      } else {
        return (
          <StyledBackgroundTile>
            {participantName}
            <StyledCursorButton>
              <StyledCloseIcon />
            </StyledCursorButton>
          </StyledBackgroundTile>
        );
      }
    }
  } else {
    return (
      <div>
        <StyledButton />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateParticipation: (auth: any, profile: any, participation: any) =>
      dispatch(updateParticipation(auth, profile, participation))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipationParticipantTile);
