export const nlHowToTexts = [
  {
    header: "Bekijk je alle event details?",
    text: "Druk op AANKOMEND > druk op de Event card",
    icon: "event",
    image: ""
  },
  {
    header: "Verstuur je een bericht?",
    text: "1) Druk op AANKOMEND > druk op de Event card > druk op Message icon - of 2) Druk op de Mail icon > druk op een bericht waarop je wil reageren",
    icon: "messages",
    image: ""
  },
  {
    header: "Zie je wanneer iemand zich aanwezig of aanwezig heeft gemeld?",
    text: "Druk op AANKOMEND > druk op de Event card > druk op Historie icon",
    icon: "history",
    image: ""
  }
];