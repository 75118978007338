const initState = {
  mailError: null
};

const mailReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SENDMAIL_ERROR':
      return {
        ...state,
        mailError: 'Signin failed'
      };

    case 'SENDMAIL_SUCCESS':
      return {
        ...state,
        mailError: null
      };

    default:
      return state;
  }
};

export default mailReducer;