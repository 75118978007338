import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducers";

import thunk from "redux-thunk";
import { reduxFirestore, getFirestore } from "redux-firestore";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import firebaseConfig from "./config/firebase";

import { devLog } from "./utils";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reactReduxFirebase(firebaseConfig, {
      userProfile: "users",
      useFirestoreForProfile: true,
      attachAuthIsReady: true
    }),
    reduxFirestore(firebaseConfig) // redux bindings for firestore
    // comment Redux devtoools out for support mobile browser
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

devLog("Initial store ", store.getState());

store.firebaseAuthIsReady.then(() => {
  // INITIATE FCM
  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker
  //     .register("./firebase-messaging-sw.js")
  //     .then(function(registration) {
  //       console.log("Registration successful, scope is:", registration.scope);
  //     })
  //     .catch(function(err) {
  //       console.log("Service worker registration failed, error:", err);
  //     });
  // }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
