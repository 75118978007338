export const enHowToTexts = [
  {
    header: "View event details",
    text: "Push UPCOMING > push on the Event card",
    icon: "event",
    image: ""
  },
  {
    header: "Send an message",
    text: "1) Push UPCOMING > push on the Event card > push on Message icon - or 2) Push Mail icon in Navbar > Push on the message you want to reply to",
    icon: "messages",
    image: ""
  },
  {
    header: "See user activity",
    text: "Push UPCOMING > push on the Event card > push on History icon",
    icon: "history",
    image: ""
  }
];