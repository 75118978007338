export const enHomeText = {
  line1:
    "Simple attendance tracking for group activities such as sportsgames, training sessions, lessons, hobby clubs, and also meetings with colleagues, friends, family or any recurring activity with a bunch of people.",
  line2: "Know in advance who is present and when.",
  line3: "Report your presence or absence with the touch of a button.",
  line4: "Know what your next events are and quickly view all the details.",
  line5: "Send messages to event participants",
  line6: "presYes is a webbrowser app for desktop, tablet and phone. Small, simple and easy to use.",
  line7: "Free and for nothing.",
  line8: ""
};

export const enLineTexts = [
  {
    header: "",
    text: "Know in advance who is present and when.",
    icon: "eventAvailable",
    image: ""
  },
  {
    header: "",
    text: "Notify your presence or absence with the touch of a button.",
    icon: "touch",
    image: ""
  },
  {
    header: "",
    text: "Know what your next events are and quickly view all details.",
    icon: "events",
    image: ""
  },

  {
    header: "",
    text: "Send messages to event participants.",
    icon: "messages",
    image: ""
  }
];

