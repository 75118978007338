// LABEL TEXTS FOR COMPONENTS

import { getLanguageObject} from "../language";

export const getLabel = (label, language) => {
  const browserLanguage = window.navigator.language
    .toUpperCase()
    .substring(0, 2);
  const lang = language ? language : browserLanguage;

  const labels = getLanguageObject(lang);
  const key = label;

  if (labels[key]) {
    return labels[key];
  } else if (label) {
    return label;
  } else {
    return "-L-";
  }
};

export const getPlaceholder = (label, language) => {
  const browserLanguage = window.navigator.language
    .toUpperCase()
    .substring(0, 2);
  const lang = language ? language : browserLanguage;

  const labels = getLanguageObject(lang);
  const key = `${label}-PH`;

  if (labels[key]) {
    return labels[key];
  } else if (label) {
    return label;
  } else {
    return "-PH-";
  }
};

export const getHelperText = (label, language) => {
  const browserLanguage = window.navigator.language
    .toUpperCase()
    .substring(0, 2);
  const lang = language ? language : browserLanguage;

  const labels = getLanguageObject(lang);
  const key = label;

  if (labels[key]) {
    return labels[key];
  } else if (label) {
    return label;
  } else {
    return "-H-";
  }
};


export const getText = (label, language) => {
  const browserLanguage = window.navigator.language
    .toUpperCase()
    .substring(0, 2);
  const lang = language ? language : browserLanguage;

  const labels = getLanguageObject(lang);
  const key = label;

  if (labels[key]) {
    return labels[key];
  } else if (label) {
    return label;
  } else {
    return label;
  }
};

