import React from "react";

import InstructionPaper from "./InstructionPaper";
import { checkDevice, checkDeviceOS } from "../../utils/device";
import { devLog } from "../../utils";
import { getLabel } from "../../config/labels";
import { getGettingStartedTexts } from "../../language";

import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

// import { ReactComponent as Logo } from "../../img/presyes.svg";

const StyledH3 = styled(Typography)`
  font-size: 30px;
  font-weight: 500;
  margin-top: 40px;
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const GetStarted = (props: any) => {
  const { language } = props;

  let start = "";
  if (language) {
    start = `${getLabel(
      "get-started",
      language.substring(0, 2).toUpperCase()
    )}`;
  }
  const gettingStartedTexts = getGettingStartedTexts(language);

  // devLog("Get started - language: ", language);
  devLog("userAgent => ", navigator.userAgent);
  devLog("platform => ", navigator.platform);
  devLog("checkDevice: ", checkDevice());
  devLog("checkDeviceOS: ", checkDeviceOS());
  // alert(navigator.userAgent);
  // alert(navigator.platform);

  return (
    <div>
      <StyledH3>{start}</StyledH3>
      {gettingStartedTexts.map((text: any) => {
        return (
          <div>
            <InstructionPaper text={text} language={language} />
          </div>
        );
      })}
    </div>
  );
};

export default GetStarted;
