import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions"; // <- needed if using httpsCallable
import "firebase/firestore";

import { FIREBASE } from "../config/settings";

// Replace this with your own config details

let config = {};

switch (FIREBASE) {
  case "prod":
    config = {
      apiKey: "AIzaSyCndR9g35D3MPLZ_n-iP1YxcukeTHSw_wg",
      authDomain: "presyes-prod.firebaseapp.com",
      databaseURL: "https://presyes-prod.firebaseio.com",
      projectId: "presyes-prod",
      messagingSenderId: "367662538779",
      appId: "1:367662538779:web:eae0568538c6f5a7f358be",
      measurementId: "G-L5DM1NFTCJ"
    };
    break;
  case "test":
    config = {
      apiKey: "AIzaSyCMqFEW2QOb6yBlIi0F3jZNIJBDheJhQzk",
      authDomain: "presyes-test.firebaseapp.com",
      databaseURL: "https://presyes-test.firebaseio.com",
      projectId: "presyes-test",
      storageBucket: "presyes-test.appspot.com",
      messagingSenderId: "100732377963",
      appId: "1:100732377963:web:a3395c536afec6d7270a2e",
      measurementId: "G-10TJNLFL7J"
    };
    break;
  case "dev":
    config = {
      apiKey: "AIzaSyA4e7mDSjRRF6yXXEZtXVh7pn0lqx9qASs",
      authDomain: "presyes-dev.firebaseapp.com",
      databaseURL: "https://presyes-dev.firebaseio.com",
      projectId: "presyes-dev",
      storageBucket: "presyes-dev.appspot.com",
      messagingSenderId: "207024748009",
      appId: "1:207024748009:web:3741d9882ca7ef4b7a4778",
      measurementId: "G-18NVFB7KRM"
    };
    break;
  default:
    config = {
      apiKey: "AIzaSyA4e7mDSjRRF6yXXEZtXVh7pn0lqx9qASs",
      authDomain: "presyes-dev.firebaseapp.com",
      databaseURL: "https://presyes-dev.firebaseio.com",
      projectId: "presyes-dev",
      storageBucket: "presyes-dev.appspot.com",
      messagingSenderId: "207024748009",
      appId: "1:207024748009:web:3741d9882ca7ef4b7a4778",
      measurementId: "G-18NVFB7KRM"
    };
    break;
}

firebase.initializeApp(config);
firebase.firestore().settings({});

export default firebase;
