export const textsEN = {
  LANGUAGE: "EN",
  addEvent: "Add events to your activity.",
  addNewActivity: "New? Go to ACTIVITIES and add your activity.",
  addParticipant: "Then you add the participants to your activity.",
  ftCommunicate: "Communicate with other participants about the event.",
  ftDevices: "Use the device at your disposal or the one you prefer.",  
  ftKnow: "Everybody knows in advance who is present and absent at all events.",
  ftManageEvents: "Manage the attendance of your social groupactivities.",
  ftNext: "Look quickly into your next events.",
  ftNotify: "Report your presence or absence with a touch of a button",
  inviteParticipant: "If they are not present in presYes you can invite them by email to signup.",
  presOverview: "Get overview of the attendance.",
  reportAbsence: "Let participants report their absence or presence."
};
