import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import { getUpcomingEvents } from '../../store/actions/eventActions';
import UpcomingEventListItem from '../events/UpcomingEventListItem';
import EmptyScreen from "../fields/EmptyScreen";

// import EventCard from '../events/EventCard';
import { Event } from '../../config/types';
import MessageBeam from '../dialogs/MessageBeam';

import styled from 'styled-components';
// import { devLog } from '../../utils/index';
import {
  getHeight,
  backgroundDivStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;

const StyledCard = styled(Card)`
  ${cardStyle};
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
`;

function Upcoming(props: any) {
  const { auth, events, profile, getUpcomingEvents } = props;
  const { uid } = auth;
  const [showMessageBeam, setShowMessageBeam] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (uid ) {
      getUpcomingEvents(uid);
    }
  }, [uid, getUpcomingEvents]);

  const checkProfile = (userProfile: any) => {
    if (
      userProfile &&
      userProfile.isLoaded &&
      userProfile.isEmpty === false &&
      count > 0
    ) {
      if (
        !userProfile.firstName ||
        !userProfile.initials ||
        !userProfile.lastName
      ) {
        setShowMessageBeam(true);
        setCount(count - 1);
      }
    }
  };

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
    & {
      margin-bottom: 58px;
    }
  `;

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  }

  if (profile && profile.isLoaded && profile.isEmpty === false)
    checkProfile(profile);

  const beam = showMessageBeam ? (
    <MessageBeam
      message={'complete-profile'}
      setShowMessage={setShowMessageBeam}
      stayOpen
    />
  ) : (
    <div></div>
  );

  if (events && events.length > 0) {
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard>
            <List>
              {events.map((event: Event) => (
                <UpcomingEventListItem event={event} />
              ))}
            </List>
          </StyledCard>
        </StyledWrapper>
        {beam}
      </StyledBackgroundDiv>
    );
  } else {
    return (
      <StyledBackgroundDiv>
        {" "}
        <EmptyScreen text="no-events" />
        {beam}
      </StyledBackgroundDiv>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    events: state.event.events
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUpcomingEvents: (userId: string) => dispatch(getUpcomingEvents(userId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upcoming);
