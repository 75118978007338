import React from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {
  cardStyle,
  cardFieldTextStyle,
  logoStyle,
  wrapperStyle
} from "../../config/styles";
import Label from "./Label";

export default function CreacteNewCard(props: any) {
  const { label, url } = props;

  const BaseCard = styled(Card)`
    ${cardStyle}
    color: ${(props: any) => props.theme.colors.onSurface};
    background-color: ${(props: any) => props.theme.colors.surface};
    && {
      margin-top: 5px;
    }
    padding-top: 10px;
    opacity: 0.4;
    border-style: dotted;
    border-width: thin;
  `;

  const StyledField = styled(Typography)`
    ${cardFieldTextStyle};
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 15px;
  `;

  const StyledNavLink = styled(NavLink)`
    ${logoStyle};
    color: ${(props: any) => props.theme.colors.onBackground};
  `;

  const StyledWrapper = styled("div")`
    ${wrapperStyle}
    margin-bottom: 20px;
  `;

  return (
    <div>
      <StyledWrapper>
        <BaseCard>
          <StyledNavLink to={url}>
            <StyledField>
              {"+  "}
              <Label label={label} />
            </StyledField>
          </StyledNavLink>
        </BaseCard>
      </StyledWrapper>
    </div>
  );
}
