import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/EditOutlined";
import EventsIcon from "@material-ui/icons/Event";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import ViewIcon from "@material-ui/icons/ViewComfy";

import styled from "styled-components";
import {
  logoStyle,
  cardStyle,
  wrapperStyle,
  cardHeaderTextStyle,
  cardFieldTextStyle,
  cardIconWrappeStyle,
  cardButtonStyle,
  cardIconStyle
} from "../../config/styles";
import { getLabel } from "../../config/labels";

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;

const StyledButton = styled(Button)`
  ${cardButtonStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const StyledEditIcon = styled(EditIcon)`
  ${cardIconStyle}
`;

const StyledEventsIcon = styled(EventsIcon)`
  ${cardIconStyle}
`;

const StyledPeopleIcon = styled(GroupAddOutlinedIcon)`
  ${cardIconStyle}
`;

const StyledViewIcon = styled(ViewIcon)`
  ${cardIconStyle}
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.onBackground};
  ${logoStyle};
`;

const StyledBaseCard = styled(Card)`
  ${cardStyle};
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
`;

const StyledHeader = styled(Typography)`
  ${cardHeaderTextStyle};
`;

const StyledIconWrapper = styled("div")`
  ${cardIconWrappeStyle}
  background-color: ${(props: any) => props.theme.colors.surface};
`;

const StyledField = styled(Typography)`
  ${cardFieldTextStyle};
`;
const StyledDescriptionField = styled(Typography)`
  ${cardFieldTextStyle};
  font-style: italic;
`;

function ActivityCard(props: any) {
  const { auth, profile, activity } = props;

  const [direction, setDirection] = useState("horizontal");

  const EditActivityUrl = `/edit-activity/${activity.activityId}`;
  const activityEventsUrl = `/activity-events/${activity.activityId}`;
  const activityParticipantsUrl = `/activity-participants/${activity.activityId}`;
  const activityParticipationsUrl = `/activity-participations/${activity.activityId}`;

  const determineSize = () => {
    if (window.innerWidth < 640 && direction) {
      setDirection("vertical");
    } else {
      setDirection("horizontal");
    }
  };
  window.addEventListener("resize", determineSize);

  let userLanguage = window.navigator.language;
  if (profile && profile.settings && profile.settings.language) {
    userLanguage = profile.settings.language;
  }

  let activityAdmin = false;
  if (activity && activity.admins && activity.admins.includes(auth.uid)) {
    activityAdmin = true;
  }

  // if (activityAdmins && activityAdmins.includes(auth.uid)) {
  //   activityAdmin = true;
  // }

  const nav = activityAdmin ? (
    <div>
      <StyledNavLink to={EditActivityUrl}>
        <StyledButton>
          <StyledEditIcon />
        </StyledButton>
      </StyledNavLink>
    </div>
  ) : (
    <div></div>
  );

  const description = activity.description ? (
    <div>
      <StyledDescriptionField>{activity.description}</StyledDescriptionField>
    </div>
  ) : (
    <div></div>
  );

  const location = activity.defaultLocation ? (
    <div>
      <StyledField>
        {getLabel("default-location", userLanguage)}
        {": "}
        {activity.defaultLocation}
      </StyledField>
    </div>
  ) : (
    <div></div>
  );

  const address = activity.defaultAddress ? (
    <div>
      <StyledField>
        {getLabel("default-address", userLanguage)}
        {": "}
        {activity.defaultAddress}
      </StyledField>
    </div>
  ) : (
    <div></div>
  );

  const limit = activity.defaultPresenceLimit ? (
    <div>
      <StyledField>
        {getLabel("default-presence-limit", userLanguage)}
        {": "}
        {activity.defaultPresenceLimit}
      </StyledField>
    </div>
  ) : (
    <div></div>
  );

  const card = (
    <StyledWrapper>
      <StyledBaseCard>
        <CardContent>
          <StyledHeader>{activity.activityName}</StyledHeader>
          {description}
          {location}
          {address}
          {limit}
        </CardContent>
        <StyledIconWrapper>
          <StyledNavLink to={activityParticipantsUrl}>
            <StyledPeopleIcon />
          </StyledNavLink>
          <StyledNavLink to={activityEventsUrl}>
            <StyledEventsIcon />
          </StyledNavLink>
          <StyledNavLink to={activityParticipationsUrl}>
            <StyledViewIcon />
          </StyledNavLink>
          {nav}
        </StyledIconWrapper>
      </StyledBaseCard>
    </StyledWrapper>
  );

  return <div>{card}</div>;
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activityAdmins: state.activity.activityAdmins
  };
};

export default connect(mapStateToProps)(ActivityCard);
