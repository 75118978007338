import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";

import styled from "styled-components";
import Label from "../layout/Label";
import {
  cardButtonStyle,
  cardFieldTextStyle,
  cardHeaderTextStyle,
  cardStyle,
  navLinkStyle,
  wrapperStyle
} from "../../config/styles";

const StyledBaseCard = styled(Card)`
  ${cardStyle};
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
  &&& {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  ${cardButtonStyle}
  float: right;
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const StyledEditIcon = styled(EditIcon)`
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const StyledField = styled(Typography)`
  ${cardFieldTextStyle};
`;

const StyledHeader = styled(Typography)`
  ${cardHeaderTextStyle};
`;

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.onSurface};
  ${navLinkStyle}
`;

function ParticipantCard(props: any) {
  const { auth, activityAdmins, participant } = props;
  const { uid } = auth;
  const EditParticipantUrl = `/edit-participant/${participant.participantId}/${participant.activityId}`;

  if (participant) {
    const name = `${participant.firstName} ${participant.lastName}`;

    let activityAdmin = false;
    if (activityAdmins && activityAdmins.includes(auth.uid)) {
      activityAdmin = true;
    }

    const presence = participant.defaultPresence ? (
      <div>
        <StyledField>
          <Label label="default-present" />
        </StyledField>
      </div>
    ) : (
      <div>
        <StyledField>
          <Label label="default-absent" />
        </StyledField>
      </div>
    );

    const admin = participant.admin ? (
      <div>
        <StyledField>
          <Label label="admin" />
        </StyledField>
      </div>
    ) : (
      <div></div>
    );

    const supportOnly = participant.supportOnly ? (
      <div>
        <StyledField>
          <Label label="support-only" />
        </StyledField>
      </div>
    ) : null;

    const role = participant.role ? (
      <div>
        <StyledField>{participant.role}</StyledField>
      </div>
    ) : (
      <div></div>
    );

    const note = participant.note ? (
      <div>
        <StyledField>{participant.note}</StyledField>
      </div>
    ) : (
      <div></div>
    );

    const editAction = (activityAdmin || uid === participant.userId) ? (
      <div>
          <StyledNavLink to={EditParticipantUrl}>
            <StyledButton>
              <StyledEditIcon />
            </StyledButton>
          </StyledNavLink>
      </div>
    ) : (
      <div></div>
    );

    return (
      <div>
        <StyledWrapper>
          <StyledBaseCard>
            <CardContent>
                <StyledHeader>
                  {name}
                  {"  ("}
                  {participant.initials}
                  {")"}
                </StyledHeader>
                {editAction}
                {presence}
                {admin}
                {supportOnly}
                {role}
                {note}
            </CardContent>
          </StyledBaseCard>
        </StyledWrapper>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    activityAdmins: state.activity.activityAdmins
  };
};

export default connect(mapStateToProps)(ParticipantCard);
