import React from "react";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import OverviewIcon from "@material-ui/icons/ViewComfy";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DevicesIcon from "@material-ui/icons/Devices";

import ManageActivitiesImage from "../../img/ft-manage-activities.png";
import NotifyImage from "../../img/ft-notify.png";
import KnowInAdvanceImage from "../../img/ft-know-in-advance.png";
import CommunicateImage from "../../img/ft-communicate.png";
import NextEventsImage from "../../img/ft-next-events.png";
import DifferentDevicesImage from "../../img/ft-different-devices.png";

import styled from "styled-components";
import { backgroundDivStyle, getHeight } from "../../config/styles";
import { getNavigatorLanguage, getInstructionText } from "../../language";
import { getLabel } from "../../config/labels";

import {
  iconGettingStartedStyle,
  instructionAvatarStyle,
  instructionImageStyle,
  instructionTextStyle,
  instructionTextWrapper
} from "../../config/styles";

const Features = (props: any) => {
  const { profile } = props;

  let language = getNavigatorLanguage();

  let features = "";
  if (profile && profile.settings && profile.settings.language) {
    language = profile.settings.language;
  }
  features = `${getLabel("features", language)}`;

  const txtManage = getInstructionText("ftManageEvents", language);
  const manageActivities = (
    <div>
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledActivityIcon />
        </StyledAvatar>
        <StyledText>{txtManage}</StyledText>
      </StyledTextWrapper>
      <StyledImage src={ManageActivitiesImage} />
    </div>
  );

  const txtNotify = getInstructionText("ftNotify", language);
  const notify = (
    <div>
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledTouchAppIcon />
        </StyledAvatar>
        <StyledText>{txtNotify}</StyledText>
      </StyledTextWrapper>
      <StyledImage src={NotifyImage} />
    </div>
  );
  const txtKnowInAdvance = getInstructionText("ftKnow", language);
  const knowInAdvance = (
    <div>
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledOverviewIcon />
        </StyledAvatar>
        <StyledText>{txtKnowInAdvance}</StyledText>
      </StyledTextWrapper>
      <StyledImage src={KnowInAdvanceImage} />
    </div>
  );

  const txtCommunicate = getInstructionText("ftCommunicate", language);
  const communicate = (
    <div>
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledMailOutlineIcon />
        </StyledAvatar>
        <StyledText>{txtCommunicate}</StyledText>
      </StyledTextWrapper>
      <StyledImage src={CommunicateImage} />
    </div>
  );

  const txtNextEvents = getInstructionText("ftNext", language);
  const nextEvents = (
    <div>
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledNextEventsIcon />
        </StyledAvatar>
        <StyledText>{txtNextEvents}</StyledText>
      </StyledTextWrapper>
      <StyledImage src={NextEventsImage} />
    </div>
  );

  const txtDevices = getInstructionText("ftDevices", language);
  const devices = (
    <div>
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledDevicesIcon />
        </StyledAvatar>
        <StyledText>{txtDevices}</StyledText>
      </StyledTextWrapper>
      <StyledImage src={DifferentDevicesImage} />
    </div>
  );

  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledH3>{features}</StyledH3>
        {manageActivities}
        {notify}
        {knowInAdvance}
        {communicate}
        {nextEvents}
        {devices}
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    min-height: ${getHeight()}px;
    getHeight()
  `;
const StyledH3 = styled(Typography)`
  font-size: 40px;
  font-weigth: 800;
  margin-top: 40px;
  margin-bottom: 30px;
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledWrapper = styled("div")`
  width: 60%;
  @media (max-width: 640px) {
    width: 80%;
  }
`;
const StyledAvatar = styled(Avatar)`
  ${instructionAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledImage = styled("img")`
  ${instructionImageStyle}
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledActivityIcon = styled(GroupWorkIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledTextWrapper = styled("div")`
  ${instructionTextWrapper}
  margin-top: 20px;
`;
const StyledTouchAppIcon = styled(TouchAppIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledNextEventsIcon = styled(EventAvailableIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledOverviewIcon = styled(OverviewIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledDevicesIcon = styled(DevicesIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;

export default connect(mapStateToProps)(Features);
