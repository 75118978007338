import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import {
  addParticipant,
  getActivity
} from "../../store/actions/activityActions";
import SelectUserList from "../fields/SelectUserList";
import MessageSnackBar from "../dialogs/MessageSnackBar";

import Label from "../layout/Label";
import { getLabel } from "../../config/labels";
import { validateEmail } from "../../utils/validations";
import { getValueFromField } from "../../utils/htmlUtils";

// import { Participant, User } from '../../config/types';
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  color: ${(props: any) => props.theme.colors.onBackground};
  ${buttonStyle}
`;

const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
  padding-top: 30px;
`;
const StyledCard = styled(Card)`
    ${cardStyle}
    background: ${(props: any) => props.theme.colors.primaryLight};
  `;
const StyledTextField = styled(TextField)`
  margin-top: 25px;
  margin-bottom: 18px;
`;

const AddParticipantList = (props: any) => {
  const { auth, profile, match, addParticipant, getActivity, activity } = props;
  const { activityId } = match.params;
  const [language, setLanguage] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [showInviteButton, setShowInviteButton] = React.useState(false);
  const [findUsers, setFindUsers] = React.useState(true);
  // const [newMail, setNewMail] = React.useState("");
  const [showMessage, setShowMessage] = React.useState(false);

  React.useEffect(() => {
    if (profile && profile.settings && profile.settings.language) {
      setLanguage(profile.settings.language);
    } else {
      setLanguage(window.navigator.language);
    }
    getActivity(activityId);
  }, [profile, activityId, getActivity]);

  if (!auth.uid) return <Redirect to="/signin" />;

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length > 2) {
      setFindUsers(true);
    } else {
      setFindUsers(false);
    }
    const emailSuccess = validateEmail(value);
    if (emailSuccess && searchValue) {
      setShowInviteButton(true);
      // setNewMail(value);
    } else {
      setShowInviteButton(false);
    }
  };

  const handleInvite = () => {
    const email = getValueFromField("search-user-field");

    const inviter = {
      inviterDisplayName: profile.displayName,
      language: language,
      activityName: activity.activityName
    };

    const participant = {
      email: email,
      activityId: activityId,
      admin: false,
      defaultPresence: true,
      supportOnly: false
    };
    addParticipant(participant, inviter);
    setSearchValue("");
    setShowInviteButton(false);
    // setNewMail("");
    setShowMessage(true);
  };

  const list =
    searchValue && searchValue.length > 2 ? (
      <StyledCard>
        <SelectUserList
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          findUsers={findUsers}
          setFindUsers={setFindUsers}
          setShowMessage={setShowMessage}
          activityId={activityId}
        />
      </StyledCard>
    ) : null;

  const searchField = searchValue ? (
    <StyledTextField
      id="search-user-field"
      autoFocus
      fullWidth
      onChange={handleChange}
      placeholder={getLabel("search-user-PH", language)}
      variant="outlined"
    />
  ) : (
    <StyledTextField
      id="search-user-field"
      autoFocus
      fullWidth
      onChange={handleChange}
      placeholder={getLabel("search-user-PH", language)}
      value={searchValue}
      variant="outlined"
    />
  );

  const inviteButton = showInviteButton ? (
    <div>
      <StyledButton onClick={handleInvite}>
        <Label label={"invite-to-presyes"} />
      </StyledButton>
    </div>
  ) : (
    <div />
  );

  const message = showMessage ? (
    <MessageSnackBar
      message={getLabel("user-added-invited", language)}
      duration={4000}
      language={language}
      setShowMessage={setShowMessage}
    />
  ) : (
    <div></div>
  );

  return (
    <div>
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard>
            {searchField}
            {inviteButton}
          </StyledCard>
          {list}
        </StyledWrapper>
      </StyledBackgroundDiv>
      {message}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activity: state.activity.activity
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addParticipant: (participant: any, inviter: any) =>
      dispatch(addParticipant(participant, inviter)),
    getActivity: (activityId: string) => dispatch(getActivity(activityId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParticipantList);
