const initState = {
  eventError: null,
  event: null,
  events: null,
  eventsError: null,
  eventParticipationsError: null,
  participations: null,
  participationError: null
};

const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_EVENT_ERROR":
      return {
        ...state,
        eventError: "Getting event failed"
      };

    case "GET_EVENT_SUCCESS":
      return {
        ...state,
        event: action.event,
        eventError: null
      };

    case "GET_EVENT_PARTICIPATIONS_ERROR":
      return {
        ...state,
        eventParticipationsError: "Getting event participations failed"
      };

    case "GET_EVENT_PARTICIPATIONS_SUCCESS":
      return {
        ...state,
        participations: action.participations,
        eventParticipationsError: null
      };

    case "GET_PARTICIPATION_ERROR":
      return {
        ...state,
        eventParticipationError: "Getting participation failed"
      };

    case "GET_PARTICIPATION_SUCCESS":
      return {
        ...state,
        participation: action.participation,
        eventParticipationError: null
      };

    case "ADD_EVENT_ERROR":
      return {
        ...state,
        eventError: "Adding event failed"
      };

    case "ADD_EVENT_SUCCESS":
      return {
        ...state,
        eventError: null
      };

    case "ADD_USER_TO_EVENT_ERROR":
      return {
        ...state,
        eventError: "Adding user to event failed"
      };

    case "ADD_USER_TO_EVENT_SUCCESS":
      return {
        ...state,
        eventError: null
      };

    case "UPDATE_EVENT_ERROR":
      return {
        ...state,
        eventError: "Updating event failed"
      };

    case "UPDATE_EVENT_SUCCESS":
      return {
        ...state,
        eventError: null
      };

    case "DELETE_EVENT_ERROR":
      return {
        ...state,
        eventError: "Deleting event failed"
      };

    case "DELETE_EVENT_SUCCESS":
      return {
        ...state,
        eventError: null
      };

    case "UPDATE_PARTICIPATION_ERROR":
      return {
        ...state,
        participationError: "Updating participation failed"
      };

    case "UPDATE_UPDATE_PARTICIPATION_ERROR_SUCCESS":
      return {
        ...state,
        participationError: null
      };

    case "GET_UPCOMING_EVENTS_ERROR":
      return {
        ...state,
        eventsError: "Getting upcoming events failed"
      };

    case "GET_UPCOMING_EVENTS_SUCCESS":
      return {
        ...state,
        events: action.events,
        eventsError: null
      };

    case "CLEAR_EVENT_STATE":
      return {
        ...state,
        eventError: null,
        event: null,
        events: null,
        eventsError: null,
        eventParticipationsError: null,
        participations: null,
        participationError: null
      };

    default:
      return state;
  }
};

export default eventReducer;
