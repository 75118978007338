import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";

import { updateLastMessageRead } from "../../store/actions/userActions";
import {
  getUserMessages,
  clearMessageState
} from "../../store/actions/messageActions";
import MessageWithReply from "../messages/MessageWithReply";
import EmptyScreen from "../fields/EmptyScreen";

import styled from "styled-components";
import {
  getHeight,
  backgroundDivStyle,
  cardStyle,
  iconGettingStartedStyle,
  cardIconStyle,
  wrapperStyle
} from "../../config/styles";
// import { devLog } from "../../utils";

function Messages(props: any) {
  const {
    auth,
    profile,
    userMessages,
    getUserMessages,
    updateLastMessageRead,
    clearMessageState
  } = props;
  const { uid } = auth;
  const [getMessages, setGetMessages] = React.useState(true);

  React.useEffect(() => {
    if (uid && getMessages) {
      // devLog("MESSAGES USE-EFFECT ****************************************");
      getUserMessages(uid);
      updateLastMessageRead();
      setGetMessages(false);
    }
    return () => {
      clearMessageState();
      // devLog("MESSAGES UNMOUNTS -------------------------------------------");
    };
  }, [
    uid,
    getUserMessages,
    getMessages,
    setGetMessages,
    updateLastMessageRead,
    clearMessageState
  ]);

  const handleRefresh = () => {
    setGetMessages(true);
  };

  if (!auth || !auth.uid) return <Redirect to="/signin" />;

  if (auth && profile) {
    const messages =
      userMessages && userMessages.length > 0 ? (
        <StyledBackgroundDiv>
          <StyledWrapper>
            <StyledMessagesCard>
              <StyledRefreshOutlinedIcon onClick={handleRefresh} />
              <StyledMailOutlineIcon />
              <List>
                {userMessages.map((message: any) => (
                  <MessageWithReply
                    message={message}
                    setGetMessages={setGetMessages}
                  />
                ))}
              </List>
            </StyledMessagesCard>
          </StyledWrapper>
        </StyledBackgroundDiv>
      ) : (
        <StyledBackgroundDiv>
          <EmptyScreen text="no-messages" />
        </StyledBackgroundDiv>
      );

    return <div>{messages}</div>;
  } else return <div />;
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    userMessages: state.message.userMessages
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserMessages: (uid: string) => dispatch(getUserMessages(uid)),
    clearMessageState: () => dispatch(clearMessageState()),
    updateLastMessageRead: () => dispatch(updateLastMessageRead())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

// Styles
const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle};
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px;
`;
const StyledBaseCard = styled(Card)`
  ${cardStyle};
  padding-left: 60px;
  color: ${(props: any) => props.theme.colors.onSurface};
  background-color: ${(props: any) => props.theme.colors.surface};
  &&&&& {
    padding-top: 15px;
  }
`;
const StyledMessagesCard = styled(StyledBaseCard)`
  padding-top: 30px;
  @media (min-width: 641px) {
    margin-bottom: 20px;
  }
  @media (max-width: 640px) {
    margin-bottom: 60px;
  }
`;
const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
  opacity: 0.3;
  margin-left: 15px;
`;

const StyledRefreshOutlinedIcon = styled(RefreshOutlinedIcon)`
  ${cardIconStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
  float: right;
  margin-top: 12px;
  margin-right: 15px;
  opacity: 0.8;
`;

const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;
