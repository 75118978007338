import React, { useState } from 'react';
import { connect } from 'react-redux';
// import styled from 'styled-components';
// import Label from '../layout/Label';
import { getLabel, getPlaceholder, getHelperText } from '../../config/labels';
import BaseInputField from './BaseInputField';
// import { createInitials } from '../../utils/index';

function ProfileField(props: any) {
  const { profile, name } = props;
  const [count, setCount] = useState(1);
  const [language, setLanguage] = useState(window.navigator.language);
  const [fieldProps, setFieldProps] = useState({
    id: name,
    name: name,
    autoFocus: false,
    defaultValue: props.defaultValue,
    disabled: props.disabled,
    error: props.error,
    fullWidth: false,
    helperText: props.helperText,
    label: '',
    margin: 'normal',
    onChange: props.onChange,
    placeholder: '',
    required: false,
    type: '',
    variant: 'outlined'
  });

  if (count > 0) {
    let userLanguage = window.navigator.language;
    if (profile && profile.settings && profile.settings.language) {
      userLanguage = profile.settings.language;
      setLanguage(userLanguage);
    }

    switch (name) {
      case 'email':
        fieldProps.label = getLabel(name, userLanguage);
        fieldProps.fullWidth = true;
        fieldProps.placeholder = getPlaceholder(name, userLanguage);
        fieldProps.disabled = true;
        setFieldProps(fieldProps);
        break;
      case 'firstname':
      case 'lastname':
        fieldProps.label = getLabel(name, userLanguage);
        fieldProps.required = true;
        fieldProps.fullWidth = true;
        fieldProps.placeholder = getPlaceholder(name, userLanguage);
        setFieldProps(fieldProps);
        break;
      default:
        fieldProps.label = getLabel(name, userLanguage);
        fieldProps.fullWidth = true;
        fieldProps.placeholder = getPlaceholder(name, userLanguage);
        setFieldProps(fieldProps);
        break;
    }
    setCount(count - 1);
  }

  const accept = (event: any) => {
    setFieldProps({
      ...fieldProps,
      autoFocus: false,
      defaultValue: `${event.target.value}`,
      error: false,
      helperText: ''
    });
  };

  const reject = (name: string, event: any) => {
    setFieldProps({
      ...fieldProps,
      autoFocus: true,
      defaultValue: `${event.target.value}`,
      error: true,
      helperText: getHelperText(name, language)
    });
  };

  const handleBlur = (event: any) => {
    if (event.target.value) {
      switch (name) {
        case 'lastname':
        case 'firstname':
          if (event.target.value) {
            accept(event);
          } else {
            reject(name, event);
          }
          break;
        default:
          accept(event);
          break;
      }
    }
  };

  return (
    <div>
      <BaseInputField
        autoFocus={fieldProps.autoFocus}
        defaultValue={fieldProps.defaultValue}
        disabled={fieldProps.disabled}
        error={fieldProps.error}
        fullWidth={fieldProps.fullWidth}
        helperText={fieldProps.helperText}
        id={name}
        label={fieldProps.label}
        margin={fieldProps.margin}
        name={name}
        onBlur={handleBlur}
        onChange={fieldProps.onChange}
        placeholder={fieldProps.placeholder}
        required={fieldProps.required}
        type={fieldProps.type}
        variant={fieldProps.variant}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(ProfileField);
