import { createMuiTheme } from "@material-ui/core/styles";

export const white = createMuiTheme({
  colors: {
    primary: "#FFFFFF", // Mainbar, FAB
    primaryVariant: "#FFFFFF", // Wide buttons
    primaryLight: "#FFFFFF",
    primaryDark: "#FFFFFF",
    secondary: "#FFFFFF",
    background: "#FFFFFF", // main background
    surface: "#FFFFFF",
    error: "#FFFFFF",
    onPrimary: "#FFFFFF",
    onSecundary: "#FFFFFF",
    onBackground: "#FFFFFF", // texts
    onSurface: "#FFFFFF",
    onError: "#FFFFFF",
    logo: "#FFFFFF",
    fab: "#004D40",
    fabIcon: "#e6ee9c",
    messageAvatar: "#9e9e9e",
    messageMyAvatar: "#616161",
    textIcon: "#000000",
    button: "#FFFFFF",
    navBarLink: "#FFFFFF",
    tileBackgroundColor: "#FFFFFF",
    tileSupportBackgroundColor: "#bdbdbd",
    tileColor: "#FFFFFF"
  }
});

export const teal = createMuiTheme({
  colors: {
    primary: "#004D40", // 900
    primaryVariant: "#00796b", //700
    primaryLight: "#f5f5f5",
    primaryDark: "#00675b",
    secondary: "#e6ee9c", //200
    background: "#f5f5f5",
    surface: "#fafafa",
    error: "#B00020",
    onPrimary: "#ffffff",
    onSecondary: "#000000",
    onBackground: "#212121",
    onSurface: "#212121",
    onError: "#FFFFFF",
    logo: "#FFFFFF",
    fab: "#004D40", //900
    fabIcon: "#e6ee9c", //200
    messageAvatar: "#9e9e9e",
    messageMyAvatar: "#009688",
    textIcon: "#000000",
    tileBackgroundColor: "#eeeeee",
    tileSupportBackgroundColor: "#bdbdbd",
    tileColor: "#616161"
  }
});

export const indigo = createMuiTheme({
  colors: {
    primary: "#1a237e", // 900
    primaryVariant: "#303f9f", //700
    primaryLight: "#f5f5f5",
    primaryDark: "#283593", //800
    secondary: "#c5cae9", //200
    background: "#f5f5f5",
    surface: "#fafafa",
    error: "#B00020",
    onPrimary: "#ffffff",
    onSecondary: "#000000",
    onBackground: "#212121",
    onSurface: "#212121",
    onError: "#FFFFFF",
    logo: "#FFFFFF",
    fab: "#c5cae9", //900
    fabIcon: "#1a237e", //200
    messageAvatar: "#9e9e9e",
    messageMyAvatar: "#009688",
    textIcon: "#000000",
    tileBackgroundColor: "#eeeeee",
    tileSupportBackgroundColor: "#bdbdbd",
    tileColor: "#616161"
  }
});

export const light = createMuiTheme({
  colors: {
    primary: "#000000",
    primaryVariant: "#424242",
    primaryLight: "#f5f5f5",
    primaryDark: "#000000",
    secondary: "#9e9e9e",
    background: "#e0e0e0",
    surface: "#ffffff",
    error: "#B00020",
    onPrimary: "#FFFFFF",
    onSecondary: "#000000",
    onBackground: "#212121",
    onSurface: "#212121",
    onError: "#FFFFFF",
    logo: "#FFFFFF",
    fab: "#000000",
    fabIcon: "#ffffff",
    messageAvatar: "#9e9e9e",
    messageMyAvatar: "#009688",
    textIcon: "#000000",
    tileBackgroundColor: "#eeeeee",
    tileSupportBackgroundColor: "#bdbdbd",
    tileColor: "#616161"
  }
});

export const dark = createMuiTheme({
  colors: {
    primary: "#000000",
    primaryVariant: "#424242",
    primaryLight: "#e0e0e0",
    primaryDark: "#000000",
    secondary: "#616161",
    background: "#212121",
    surface: "#424242",
    error: "#B00020",
    onPrimary: "#fafafa",
    onSecondary: "#ffffff",
    onBackground: "#fafafa",
    onSurface: "#fafafa",
    onError: "#ffffff",
    logo: "#FFFFFF",
    fab: "#fafafa",
    fabIcon: "#424242",
    textIcon: "#bdbdbd",
    messageAvatar: "#9e9e9e",
    messageMyAvatar: "#009688",
    tileBackgroundColor: "#fafafa",
    tileSupportBackgroundColor: "#bdbdbd",
    tileColor: "#616161"
  }
});
