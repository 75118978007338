import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuBookIcon from "@material-ui/icons/MenuBook";

// import NotificationPermission from "../auth/NotificationPermission"

import styled from "styled-components";

import { TITLE } from "../../config/settings";
import { iconButtonStyle, logoStyle, mainBarStyle } from "../../config/styles";

import Label from "./Label";
import { signOut } from "../../store/actions/authActions";
import { updateUserSettings } from "../../store/actions/userActions";

const StyledMainBar = styled(AppBar)`
  ${mainBarStyle}
  background: ${props => props.theme.colors.primary};
`;

const StyledSignOutIcon = styled(ExitToAppIcon)`
  ${iconButtonStyle}
`;

const StyledAccountCircle = styled(AccountCircle)`
  ${iconButtonStyle}
  margin-top: 4px;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  ${iconButtonStyle}
  margin-top: 4px;
`;

const StyledMenuBookIcon = styled(MenuBookIcon)`
  ${iconButtonStyle}
  margin-top: 4px;
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.logo};
`;

const StyledNavLink = styled(NavLink)`
  ${logoStyle};
  color: ${props => props.theme.colors.logo};
`;


function MainBar(props) {
  const { auth } = props;

  const handleSignOut = async () => {
    await props.signOut(auth);
    return <Redirect to="/" />;
  };

  const mainBar = auth.uid ? (
    <div>
      {/* <NotificationPermission /> */}
      <StyledMainBar position="static">
        <Toolbar variant="dense">
          <Typography style={{ flexGrow: 1 }} color="inherit" variant="h6">
            <StyledNavLink to="/myhome">{TITLE}</StyledNavLink>
          </Typography>
          <StyledNavLink to="/myinstructions">
            <StyledMenuBookIcon />
          </StyledNavLink>
          <StyledNavLink to="/settings">
            <StyledSettingsIcon />
          </StyledNavLink>
          <StyledNavLink to="/profile">
            <StyledAccountCircle />
          </StyledNavLink>
          <StyledSignOutIcon onClick={handleSignOut} />
        </Toolbar>
      </StyledMainBar>
    </div>
  ) : (
    <div>
      <StyledMainBar position="static">
        <Toolbar variant="dense">
          <Typography style={{ flexGrow: 1 }} color="inherit" variant="h6">
            <StyledNavLink to="/"> {TITLE}</StyledNavLink>
          </Typography>
          <NavLink
            to="/signup"
            activeStyle={{ textDecoration: "none" }}
            style={{ textDecoration: "none" }}
          >
            <StyledButton>
              <Label label={"signup"} />
            </StyledButton>
          </NavLink>
          <NavLink
            to="/signin"
            activeStyle={{ textDecoration: "none" }}
            style={{ textDecoration: "none" }}
          >
            <StyledButton>
              <Label label={"signin"} />
            </StyledButton>
          </NavLink>
        </Toolbar>
      </StyledMainBar>
    </div>
  );

  return <div>{mainBar}</div>;
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: auth => dispatch(signOut(auth)),
    updateUserSettings: newSettings => dispatch(updateUserSettings(newSettings))
  };
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    currentUser: state.auth.currentUser,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBar);
