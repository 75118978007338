import React from "react";
import { connect } from "react-redux";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import styled from "styled-components";

import { updateParticipation } from "../../store/actions/eventActions";
import { getTileSettings } from "../../config/settings";
import {
  buttonParticipationStyle,
  tileParticipationStyle
} from "../../config/styles";

function ParticipationTile(props: any): JSX.Element {
  const {
    auth,
    profile,
    participation,
    activityAdmins,
    tileStyle,
    updateParticipation
  } = props;

  let activityAdmin = false;
  if (activityAdmins && activityAdmins.includes(auth.uid)) {
    activityAdmin = true;
  }
  let myParticipation = false;
  if (participation && participation.userId === auth.uid) {
    myParticipation = true;
  }

  let tileSettings: any = getTileSettings(tileStyle);

  const handleClick = () => {
    if (participation) {
      if (participation.userId === auth.uid || activityAdmin) {
        participation.presence
          ? (participation.presence = false)
          : (participation.presence = true);
        updateParticipation(auth, profile, participation);
      }
    }
  };

  const StyledTile = styled("div")`
    ${tileParticipationStyle}
    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    background-color: ${(props: any) => props.theme.colors.background};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  const StyledParticipantButton = styled("div")`
    ${buttonParticipationStyle}

    width: ${tileSettings.buttonWidth};
    height: ${tileSettings.buttonHeight};
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    color: ${(props: any) => props.theme.colors.tileColor};
  `;

  const StyledSupporterButton = styled("div")`
    ${buttonParticipationStyle}
    width: ${tileSettings.buttonWidth};
    height: ${tileSettings.buttonHeight};
    background-color: ${(props: any) =>
      props.theme.colors.tileSupportBackgroundColor};
    color: ${(props: any) => props.theme.colors.tileColor};
  `;

  const StyledParticipantCursorButton = styled(StyledParticipantButton)`
    cursor: pointer;
    cursor: hand;
  `;

  const StyledSupporterCursorButton = styled(StyledSupporterButton)`
    cursor: pointer;
    cursor: hand;
  `;

  const StyledCheckIcon = styled(Check)`
    color: green;
    width: ${tileSettings.iconWidth};
    height: ${tileSettings.iconHeight};
  `;

  const StyledCloseIcon = styled(Close)`
    color: red;
    width: ${tileSettings.iconWidth};
    height: ${tileSettings.iconHeight};
  `;

  if (participation) {
    // participants
    if (!participation.supportOnly) {
      if (myParticipation || activityAdmin) {
        if (participation.presence) {
          return (
            <StyledTile>
              <StyledParticipantCursorButton onClick={handleClick}>
                <StyledCheckIcon />
              </StyledParticipantCursorButton>
            </StyledTile>
          );
        } else {
          return (
            <StyledTile>
              <StyledParticipantCursorButton onClick={handleClick}>
                <StyledCloseIcon />
              </StyledParticipantCursorButton>
            </StyledTile>
          );
        }
      } else {
        if (participation.presence) {
          return (
            <StyledTile>
              <StyledParticipantButton>
                <StyledCheckIcon />
              </StyledParticipantButton>
            </StyledTile>
          );
        } else {
          return (
            <StyledTile>
              <StyledParticipantButton>
                <StyledCloseIcon />
              </StyledParticipantButton>
            </StyledTile>
          );
        }
      }
    } else {
      // supporters
      if (myParticipation || activityAdmin) {
        if (participation.presence) {
          return (
            <StyledTile>
              <StyledSupporterCursorButton onClick={handleClick}>
                <StyledCheckIcon />
              </StyledSupporterCursorButton>
            </StyledTile>
          );
        } else {
          return (
            <StyledTile>
              <StyledSupporterCursorButton onClick={handleClick}>
                <StyledCloseIcon />
              </StyledSupporterCursorButton>
            </StyledTile>
          );
        }
      } else {
        if (participation.presence) {
          return (
            <StyledTile>
              <StyledSupporterButton>
                <StyledCheckIcon />
              </StyledSupporterButton>
            </StyledTile>
          );
        } else {
          return (
            <StyledTile>
              <StyledSupporterButton>
                <StyledCloseIcon />
              </StyledSupporterButton>
            </StyledTile>
          );
        }
      }
    }
  } else {
    return (
      <StyledTile>
        <StyledSupporterButton />
      </StyledTile>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activityAdmins: state.activity.activityAdmins,
    participationError: state.event.participationError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateParticipation: (auth: any, profile: any, participation: any) =>
      dispatch(updateParticipation(auth, profile, participation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipationTile);
