

export const nlHomeText = {
  line1:
    "Aanwezigheids monitoring voor groepsactiviteiten zoals sportwedstrijden, trainingssessies, lessen, hobbyclubs en ook bijeenkomsten met collega's, vrienden, familie of elke terugkerende activiteit met een groep mensen.",
  line2: "Van te voren weten wie present is en wanneer.",
  line3: "Meld je aanwezigheid of afwezigheid met een druk op de knop.",
  line4: "Weet wat je volgende evenementen zijn en bekijk snel alle details.",
  line5: "Stuur berichten naar eventdeelnemers.",
  line6: "presYes is een webbrowser app voor desktop, tablet en telefoon. Klein en eenvoudig in gebruik.",
  line7: "Oorspronkelijk gemaakt voor Anoeta Basketbal en HSV'69 Veteranen 1.",
  line8:
    "Maar beschikbaar voor iedereen die het wil gebruiken. Gratis en voor niets."
};

export const nlLineTexts = [
  {
    header: "",
    text: "Van te voren weten wie aanwezig is en wanneer.",
    icon: "eventAvailable",
    image: ""
  },
  {
    header: "",
    text: "Meld je aanwezigheid of afwezigheid met een druk op de knop.",
    icon: "touch",
    image: ""
  },
  {
    header: "",
    text: "Weet wat je volgende evenementen zijn en bekijk snel alle details.",
    icon: "events",
    image: ""
  },

  {
    header: "",
    text: "Stuur berichten naar eventdeelnemers.",
    icon: "messages",
    image: ""
  }
];
