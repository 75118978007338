import React, { useState } from "react";
import styled from "styled-components";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const StyledCheckBox = styled(Checkbox)`
  margin-left: 10px;
  color: ${(props: any) => props.theme.colors.primaryVariant};
`;
const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)`
  color: ${(props: any) => props.theme.colors.primaryVariant};
`;

const StyledCheckBoxIcon = styled(CheckBoxIcon)`
  color: ${(props: any) => props.theme.colors.primaryVariant};
`;

export default function BaseCheckBox(props: any) {
  const { name, checked, disabled } = props;
  const [checkedBox, setCheckedBox] = useState(checked);

  React.useEffect(() => {
    setCheckedBox(checked);
  }, [checked]);

  const uncheckBox = () => {
    setCheckedBox(false);
  };

  const checkBox = () => {
    setCheckedBox(true);
  };

  const box = checkedBox ? (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <StyledCheckBox
              id={name}
              name={name}
              disabled={disabled}
              checked={checkedBox}
              checkedIcon={<StyledCheckBoxIcon />}
              icon={<StyledCheckBoxOutlineBlankIcon />}
              onChange={uncheckBox}
              value={true}
            />
          }
          label={props.label}
        />
      </FormGroup>
    </div>
  ) : (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <StyledCheckBox
              id={name}
              name={name}
              disabled={disabled}
              checked={checkedBox}
              checkedIcon={<StyledCheckBoxIcon />}
              icon={<StyledCheckBoxOutlineBlankIcon />}
              onChange={checkBox}
              value={false}
            />
          }
          label={props.label}
        />
      </FormGroup>
    </div>
  );

  return <div>{box}</div>;
}
