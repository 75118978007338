import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import { backgroundDivStyle } from "../../config/styles";
import { getHeight } from "../../config/styles";

import { convertParticipationsToParticipationsPerEvent } from "../../utils";
import ParticipantsLineUp from "../participants/ParticipantsLineUp";
import ParticipationsLineUp from "../participations/ParticipationsLineUp";
import EmptyScreen from "../fields/EmptyScreen";
import {
  getActivityParticipants,
  getActivityParticipations,
  clearActivityState
} from "../../store/actions/activityActions";
import { Participation } from "../../config/types";

function ActivityParticipations(props: any): JSX.Element {
  const {
    auth,
    match,
    profile,
    participants,
    participations,
    getActivityParticipants,
    getActivityParticipations,
    clearActivityState
  } = props;

  const { activityId } = match.params;
  const { uid } = auth;
  // const [count, setCount] = useState(1);

  let flexDirection = "row";
  let tileStyle = "wide";
  let flexDirectionLines = "column";
  if (profile && profile.settings && profile.settings.xy) {
    flexDirection = profile.settings.xy.x;
    flexDirectionLines = profile.settings.xy.y;
    tileStyle = profile.settings.tileStyle;
  }

  useEffect(() => {
    if (uid && activityId) {
      getActivityParticipants(activityId);
      getActivityParticipations(activityId);
    }
    return () => {
      clearActivityState();
    };
  }, [
    uid,
    activityId,
    getActivityParticipants,
    getActivityParticipations,
    clearActivityState
  ]);

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
    & {
      margin-bottom: 58px;
    }
  `;

  const StyledStickyLeftDiv = styled("div")`
    margin-left: 0px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  `;

  const StyledEventWrapper = styled("div")`
    display: flex;
    flex-direction: ${flexDirection};
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow-y: overlay;
    background-color: ${(props: any) => props.theme.colors.background};
    padding-top: 5px;
    margin-left: 200px;
    margin-right: 200px;
    @media (max-width: 1280px) {
      margin-left: 70px;
      margin-right: 70px;
    }
    @media (max-width: 960px) {
      margin-left: 4px;
      margin-right: 4px;
    }
    @media (max-width: 640px) {
      margin-left: 1x;
      margin-right: 1px;
    }
  `;

  // const StyledSnapContainer = styled("div")`
  //   display: flex;
  //   flex-direction: ${flexDirection};
  //   scroll-snap-type: x mandatory;
  //   scroll-padding: 50%
  // `;

  // const StyledSnapContainer = styled("div")`
  //   display: flex;
  //   flex-direction: ${flexDirection};
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   align-content: flex-start;
  //   scroll-snap-type: both mandatory;
  // `;

  // const StyledSnapColumn = styled("div")`
  //   scroll-snap-align: start;
  // `;

  // const StyledParticipationsLineUp = styled(ParticipationsLineUp)`
  //   scroll-snap-align: start;
  // `;

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  } else if (participants && participations && participations.length > 0) {
    const participationsPerEvent: any = convertParticipationsToParticipationsPerEvent(
      participations
    );
    return (
      <StyledBackgroundDiv>
        <StyledEventWrapper>
          <StyledStickyLeftDiv>
            <ParticipantsLineUp
              participants={participants}
              flexDirection={flexDirectionLines}
              tileStyle={tileStyle}
            />
          </StyledStickyLeftDiv>
          {participationsPerEvent.map(
            (participations: Array<Participation>) => {
              return (
                <ParticipationsLineUp
                  participations={participations}
                  flexDirection={flexDirectionLines}
                  tileStyle={tileStyle}
                />
              );
            }
          )}
        </StyledEventWrapper>
      </StyledBackgroundDiv>
    );
  } else {
    return (
      <StyledBackgroundDiv>
        <EmptyScreen text="no-events" />
      </StyledBackgroundDiv>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    participations: state.activity.participations,
    participants: state.activity.participants
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivityParticipations: (activityId: string) =>
      dispatch(getActivityParticipations(activityId)),
    getActivityParticipants: (activityId: string) =>
      dispatch(getActivityParticipants(activityId)),
    clearActivityState: () => dispatch(clearActivityState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParticipations);

// Styles
