import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { getText } from '../../config/labels';
import { cardHeaderTextStyle } from '../../config/styles';

function TextBox(props: any) {
  const { text, variant, component, profile } = props;

  const textVariant = variant ? variant : 'h5';
  const textComponent = component ? component : 'h3';

  let userLanguage = window.navigator.language;
  if (profile && profile.settings && profile.settings.language) {
    userLanguage = profile.settings.language;
  }
  const boxText = getText(text, userLanguage);

  const StyledDiv = styled('div')`
    ${cardHeaderTextStyle}
    margin-top: 60px;
    margin-bottom 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <div>
      <StyledDiv>
        <Typography variant={textVariant} component={textComponent}>
          {boxText}
        </Typography>
      </StyledDiv>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(TextBox);
