import { MESSAGE_LIMIT } from "../../config/settings";
import { timestampToDateString, getIdDatePart, testLog, logCurrentDateTime } from "../../utils";

export const addMessage = message => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    let messageId = `${getIdDatePart()}`;
    if (message && message.senderInitials) {
      messageId = `${getIdDatePart()}-${message.senderInitials}`;
    }

    const now = new firebase.firestore.Timestamp.now();

    firestore
      .collection("messages")
      .doc(messageId)
      .set({
        ...message,
        messageId: messageId,
        messageDateTime: now
      })
      .then(() => {
        dispatch({
          type: "ADD_MESSAGE_SUCCESS"
        });
      })
      .catch(err => {
        dispatch(
          {
            type: "ADD_MESSAGE_ERROR"
          },
          err
        );
      });
  };
};

export const getEventMessages = eventId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundMessages = [];
    firestore
      .collection("messages")
      .where("eventId", "==", `${eventId}`)
      .orderBy("messageDateTime", "desc")
      .limit(MESSAGE_LIMIT)
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const message = doc.data();
          foundMessages.push(message);
        });
      })
      .then(() => {
        dispatch({
          eventMessages: foundMessages,
          type: "GET_EVENT_MESSAGES_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_EVENT_MESSAGES_ERROR" }, err);
      });
  };
};

export const getUserMessages = userId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundMessages = [];

    // select all activities where user is participant.
    firestore
      .collectionGroup("participants")
      .where("userId", "==", userId)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const myActivity = doc.data();
          const myActivityId = myActivity.activityId;
          // get the messages
          firestore
            .collection("messages")
            .where("activityId", "==", `${myActivityId}`)
            .orderBy("messageDateTime", "desc")
            .limit(MESSAGE_LIMIT)
            .get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                const message = doc.data();
                foundMessages.push(message);
              });
              // sort messages based on messageDateTime
              foundMessages.sort((a, b) =>
                a.messageDateTime < b.messageDateTime ? 1 : -1
              );
            })
            .then(() => {
              dispatch({
                userMessages: foundMessages,
                type: "GET_USER_MESSAGES_SUCCESS"
              });
            })
            .catch(err => {
              dispatch({ type: "GET_USER_MESSAGES_ERROR" }, err);
            });
        });
      })
      .catch(err => {
        dispatch(
          {
            type: "GET_USER_MESSAGES_ERROR"
          },
          err
        );
      });
  };
};

export const newMessageCheck = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const userId = getState().firebase.auth.uid;
    let foundMessages = [];
    let lastMessageRead;

    firestore
      .collection("users")
      .doc(`${userId}`)
      .get()
      .then(doc => {
        if (doc.exists) {
          const userDetails = doc.data();
          // devLog("something started - userDetails ", userDetails);
          lastMessageRead = userDetails.lastMessageRead
            ? userDetails.lastMessageRead
            : firebase.firestore.Timestamp.now();
        }
      })
      .then(() => {
        // select all activities where user is participant.
        firestore
          .collectionGroup("participants")
          .where("userId", "==", userId)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              const myActivity = doc.data();
              const myActivityId = myActivity.activityId;
              // get the messages
              firestore
                .collection("messages")
                .where("activityId", "==", `${myActivityId}`)
                .orderBy("messageDateTime", "desc")
                // .where("messageDateTime", ">", `${lastMessageRead}`)
                .limit(1)
                .get()
                .then(snapshot => {
                  snapshot.forEach(doc => {
                    const message = doc.data();
                    logCurrentDateTime('Check new messages at :')
                    testLog(
                      "Last Messages Read : ",
                      timestampToDateString(lastMessageRead.seconds)
                    );
                    if (
                      message &&
                      message.messageDateTime &&
                      message.messageDateTime.seconds &&
                      lastMessageRead &&
                      lastMessageRead.seconds
                    ) {
                      const diff =
                        message.messageDateTime.seconds -
                        lastMessageRead.seconds;
                      // check if newmessages from others
                      if (diff > 0 && userId !== message.senderId) {
                        // devLog("diff > 0", diff);
                        // devLog("message date ", message.messageDateTime);
                        // devLog("new message", message);
                        foundMessages.push(message);
                      } else {
                        // devLog("diff < 0", diff);
                        // devLog("old message", message);
                      }
                    }
                    // sort messages based on messageDateTime
                  });
                })
                .then(() => {
                  // devLog("nrNewMessages: ", foundMessages.length);
                  dispatch({
                    nrNewMessages: foundMessages.length,
                    type: "MESSAGE_CHECK_SUCCESS"
                  });
                })
                .catch(err => {
                  dispatch({ type: "MESSAGE_CHECK_ERROR" }, err);
                });
            });
          })
          .catch(err => {
            dispatch(
              {
                type: "MESSAGE_CHECK_ERROR"
              },
              err
            );
          });
      })
      .catch(err => {
        dispatch({ type: "MESSAGE_CHECK_ERROR" }, err);
      });
  };
};

export const clearMessageState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_MESSAGE_STATE"
    });
  };
};
