import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { getUserDetails } from "../../store/actions/userActions";
import {
  getActivitiesFromUser,
  clearActivityState
} from "../../store/actions/activityActions";
import ActivityCard from "../activities/ActivityCard";
import StyledAddFab from "../layout/StyledAddFab";
import MessageBeam from "../dialogs/MessageBeam";
import CreateNewCard from "../layout/CreateNewCard";

import styled from "styled-components";
import { getHeight, logoStyle, backgroundDivStyle } from "../../config/styles";

import { Activity } from "../../config/types";

function Activities(props: any): JSX.Element {
  const { auth, profile, activities, getActivitiesFromUser, clearActivityState } = props;
  const { uid } = auth;
  const [showMessageBeam, setShowMessageBeam] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (uid) {
      getActivitiesFromUser(uid);
    }
    return () => {
      clearActivityState();
    };
  }, [uid, getActivitiesFromUser, clearActivityState]);

  const addActivityUrl = "/add-activity";

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle};
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
  `;

  const checkProfile = (userProfile: any) => {
    if (
      userProfile &&
      userProfile.isLoaded &&
      userProfile.isEmpty === false &&
      count > 0
    ) {
      if (
        !userProfile.firstName ||
        !userProfile.initials ||
        !userProfile.lastName
      ) {
        setShowMessageBeam(true);
        setCount(count - 1);
      }
    }
  };

  if (auth && !auth.uid) {
    return <Redirect to="/" />;
  } else if (auth && auth.uid && !auth.emailVerified) {
    return <Redirect to="/need-verification" />;
  }

  if (profile && profile.isLoaded && profile.isEmpty === false)
    checkProfile(profile);

  const beam = showMessageBeam ? (
    <MessageBeam
      message={"complete-profile"}
      setShowMessage={setShowMessageBeam}
      stayOpen
    />
  ) : (
    <div></div>
  );

  if (activities) {
    return (
      <div>
        <StyledBackgroundDiv>
          {activities.map((activity: Activity) => (
            <ActivityCard key={activity.activityId} activity={activity} />
          ))}
          <CreateNewCard label="new-activity" url={addActivityUrl} />
          <StyledAddFab url={addActivityUrl} />
        </StyledBackgroundDiv>
        <StyledFooterDiv />
        {beam}
      </div>
    );
  } else {
    return (
      <div>
        <StyledBackgroundDiv>
          <CreateNewCard label="new-activity" url={addActivityUrl} />
          <StyledNavLink to="/add-activity">
            <StyledAddFab url={addActivityUrl} />
          </StyledNavLink>
        </StyledBackgroundDiv>
        <StyledFooterDiv />
        {beam}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    userDetails: state.user.userDetails,
    currentUser: state.auth.currentUser,
    activities: state.activity.activities,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivitiesFromUser: (userId: string) =>
      dispatch(getActivitiesFromUser(userId)),
    getUserDetails: (userId: string) => dispatch(getUserDetails(userId)),
    clearActivityState: () => dispatch(clearActivityState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);

const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.logo};
  ${logoStyle};
`;
const StyledFooterDiv = styled("div")`
  background-color: ${(props: any) => props.theme.colors.background};
  height: 30px;
`;
