import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getText } from '../../config/labels';
import { cardFieldTextStyle } from '../../config/styles';

function MessageBeam(props: any) {
  const { message, profile, setShowMessageBeam, stayOpen } = props;
  const [open, setOpen] = useState(true);

  let userLanguage = window.navigator.language;
  if (profile && profile.settings && profile.settings.language) {
    userLanguage = profile.settings.language;
  }
  const boxText = getText(message, userLanguage);

  const handleClickAway = () => {
    if (!stayOpen) {
    setOpen(false);
    setShowMessageBeam(false);
    }
  };

  const StyledDiv = styled('div')`
    ${cardFieldTextStyle}
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props: any) => props.theme.colors.error};
    color: ${(props: any) => props.theme.colors.onBackground};
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 3em;
    font-size: 2em;
  `;

  if (open) {
    return (
      <StyledDiv>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>{boxText}</div>
        </ClickAwayListener>
      </StyledDiv>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(MessageBeam);
