export const langEN = {
  "LANGUAGE": "EN",
  "activities": "Activities",
  "activity-description": "Description",
  "activity-description-PH": "Short description",
  "activity-main-location": "Main location",
  "activity-name": "Activity name",
  "activity-name-PH": "Short activity name",
  "add-activity": "Add activity",
  "add-default-presence": "Default present",
  "add-event": "Add event",
  "add-user": "Add user to activity",
  "add-user-details": "Presyes needs some user details",
  "address": "Address",
  "address-PH": "The full address",
  "admin": "Activity admin",
  "bio-keywords": "Bio-Keywords",
  "bio-keywords-to-find": "Some words so people can find you",
  "cancel": "Cancel",
  "clear": "Clear",
  "complete-profile": "Presyes needs some data. Please complete profile!",
  "confirm-delete": "Confirm delete",
  "confirm-delete-description": "Are you certain?",
  "cookies":
    "Presyes only uses cookies that are absolutely needed for its proper functioning. We do not track or store any usage or traffic information. By using Presyes you accept the use of these cookies.",
  "days": "days",
  "default-absent": "Default absent",
  "default-address": "Default address",
  "default-address-PH": "The full address of the activity",
  "default-duration-hhmm": "Default duration of activity in hh:mm",
  "default-duration": "Default duration",
  "default-location": "Default location",
  "default-location-PH":
    "The standard place for the activity like a building, park, room, field",
  "default-presence-PH": "Desired minimum of participants",
  "default-presence-limit": "Presence minimum",
  "default-presence-limit-PH": "Desired minimum of participants",
  "default-presence": "Default presence",
  "default-present": "Default present",
  "delete": "Delete",
  "delete-activity": "Delete activity",
  "delete-event": "Delete event from activity",
  "delete-participant": "Delete participant from activity",
  "email": "Email",
  "email-invalid": "Invalid email address",
  "end": "End",
  "end-date": "End date",
  "end-date-time": "End date and time",
  "end-date-time-PH": "End date and time",
  "end-time": "End time",
  "enter-email": "Please enter your email",
  "enter-email-invite": "Not found - Enter email to invite",
  "enter-firstname": "Please enter your firstname",
  "enter-lastname": "Please enter your lastname",
  "enter-password": "Please enter your password",
  "enter-password-again": "Please enter your password again",
  "event-details": "Event details",
  "event-name": "Name event",
  "event-name-please": "Please add an event name",
  "event-name-PH": "Short name for the event",
  "features": "Features and benefits",
  "firstname": "Firstname",
  "forgot-password": "Forgot password",
  "get-started": "Get started",
  "hello": "Hello",
  "how-to": "And how to...",
  "incorrect-email": "Invalid email address",
  "initials": "Initials",
  "invite-to-presyes": "Invite to join",
  "invited-to-presyes": "You are invited to Presyes",
  "is-absent": "is absent",
  "is-present": "is present",
  "lastname": "Lastname",
  "location-PH":
    "Anything that indicates a known place like a building, park, room, field",
  "location": "Location",
  "logout": "Log out",
  "monitor-attendees": "Monitor attendees",
  "name": "Name",
  "need-verification":
    "Verify your account with the email you received. You can resend this verification mail if you did not receive it. Or check your spambox.",
  "no-activities": "No activities yet",
  "new-activity": "Create new activity",
  "new-event": "Add new event",
  "new-participant": "Add partipant",
  "no-events": "No events yet",
  "no-messages": "No messages",
  "no-users": "No users found",
  "note": "Note",
  "note-PH": "Short note",
  "notifications": "Notifications",
  "or": "- or -",
  "participants": "Participants",
  "password-minimum": "Password should be at least 6 characters",
  "password": "Password",
  "presence": "Presence",
  "presence-limit-PH": "Desired minimum of participants",
  "presence-limit": "Presence minimum",
  "presence-management": "Presence Management",
  "presence-manager": "Presence Manager",
  "presence-total": "Presence total",
  "profile": "Profile",
  "repeat-password": "Repeat password",
  "password-same": "The passwords need to be the same",
  "resend-verification": "Resend the verification email",
  "reset-password": "Reset password",
  "reset-password-request": "Send me a password reset email.",
  "reset?": "Do you want to reset your password?",
  "role": "Role",
  "role-function-task": "Role, function, task",
  "same-passwords": "The passwords need to be the same",
  "saved": "Saved",
  "save-profile": "Save profile",
  "save-settings": "Save settings",
  "search-user": "Search user",
  "search-user-PH": "Search with firstname, lastname or enter email",
  "select-enter-invite": "Select or enter new email to invite",
  "see-how": "See how",
  "settings": "Settings",
  "signin": "Sign in",
  "signin-google": "Sign In with Google",
  "signup": "Sign up",
  "signup-google": "Sign up with Google",
  "start": "Start",
  "start-date-time-PH": "Start date and time *",
  "start-date-time": "Start date and time *",
  "submit": "Submit",
  "support-only": "Supporter, follower, staff, other member",
  "this-week": "this week",
  "today": "today",
  "tomorrow": "tomorrow",
  "upcoming": "Upcoming",
  "update-activity": "Update activity",
  "update-event": "Update event",
  "update-participant": "Update participant",
  "user-added": "Participant added",
  "user-added-invited": "User added or invited",
  "using-presyes": "Start with presYes",
  "within-one-week": "within one week"
};
