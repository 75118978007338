import authReducer from './authReducer';
import userReducer from './userReducer';
import activityReducer from './activityReducer';
import eventReducer from './eventReducer';
import participantReducer from './participantReducer';
import messageReducer from "./messageReducer";
import historyReducer from "./historyReducer";
import mailReducer from './mailReducer';
import translateReducer from "./translateReducer";
import firebaseTestReducer from './firebaseTestReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  activity: activityReducer,
  event: eventReducer,
  participant: participantReducer,
  message: messageReducer,
  history: historyReducer,
  mailReducer: mailReducer,
  translate: translateReducer,
  firebaseTest: firebaseTestReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "SIGNOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
