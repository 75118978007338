import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import TextBox from '../fields/TextBox';

import { saveUserDetails } from '../../store/actions/userActions';
import SpecialField from '../fields/SpecialField';

// import { devLog } from '../../utils';
import { getValueFromField } from '../../utils/htmlUtils';
import Label from '../layout/Label';
import { buttonStyle, cardStyle, wrapperStyle } from '../../config/styles';

const AddUserDetails = (props: any) => {
  const { auth, currentUser, profile, saveUserDetails } = props;
  const [formValues, setFormValues] = useState({
    firstname: '',
    firstnameError: false,
    lastname: '',
    lastnameError: false,
    initials: '',
    initialsError: false,
    bioKeywords: '',
    bioKeywordsError: false
  });

  if (!currentUser) {
    return <Redirect to="/signin" />;
  } else if (currentUser && currentUser.emailVerified === false) {
    return <Redirect to="need-verification" />;
  } else if (currentUser && currentUser.emailVerified) {
    const url = `${'/activities/'}${auth.uid}`;
    return <Redirect to={url} />;
  }

  const handleSave = async () => {
    let newFormValues = {
      firstname: '',
      firstnameError: false,
      lastname: '',
      lastnameError: false,
      initials: '',
      initialsError: false,
      bioKeywords: '',
      bioKeywordsError: false
    };

    let formError = false;
    newFormValues.firstname = getValueFromField('firstname');
    if (!newFormValues.firstname) {
      newFormValues.firstnameError = true;
      formError = true;
    }
    newFormValues.lastname = getValueFromField('lastname');
    if (!newFormValues.lastname) {
      newFormValues.lastnameError = true;
      formError = true;
    }
    newFormValues.initials = getValueFromField('initials');
    if (!newFormValues.initials) {
      newFormValues.initialsError = true;
      formError = true;
    }
    newFormValues.bioKeywords = getValueFromField('bio-keywords');

    if (formError) {
      setFormValues(newFormValues);
    } else {
      const userDetails = {
        uid: auth.uid,
        email: profile.email,
        firstName: newFormValues.firstname,
        lastName: newFormValues.lastname,
        initials: newFormValues.initials,
        bioKeywords: newFormValues.bioKeywords
      };
      await saveUserDetails(userDetails);
    }
  };

  const StyledTextBox = styled(TextBox)`
    margin-left: 30px;
  `;

  const StyledButton = styled(Button)`
    background: ${(props: any) => props.theme.colors.primary};
    ${buttonStyle}
  `;

  const StyledCard = styled(Card)`
    background: ${(props: any) => props.theme.colors.secundary};
    ${cardStyle}
  `;

  const StyledWrapper = styled('div')`
    ${wrapperStyle}
    margin-top: 50px;
  `;

  return (
    <div>
      <StyledWrapper>
        <StyledCard raised>
          <StyledTextBox text="add-user-details" />
          <form>
            <SpecialField name="email" defaultValue={profile.email} disabled />
            <SpecialField
              name="firstname"
              defaultValue={formValues.firstname}
              error={formValues.firstnameError}
            />
            <SpecialField
              name="lastname"
              defaultValue={formValues.lastname}
              error={formValues.lastnameError}
            />
            <SpecialField
              name="initials"
              defaultValue={formValues.initials}
              error={formValues.initialsError}
            />
            <SpecialField
              name="bio-keywords"
              defaultValue={formValues.bioKeywords}
              error={formValues.bioKeywordsError}
            />
          </form>
          <StyledButton onClick={handleSave}>
            <Label label={'save'} />
          </StyledButton>
        </StyledCard>
      </StyledWrapper>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    currentUser: state.auth.currentUser,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveUserDetails: (userDetails: any) =>
      dispatch(saveUserDetails(userDetails))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserDetails);
