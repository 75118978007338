import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getTileSettings } from "../../config/settings";
import { logoStyle, tileParticipantStyle } from "../../config/styles";

const StyledRoleText = styled("text")`
  font-weight: 600;
  font-style: italic;
`;
const StyledNameText = styled("text")`
  font-weight: 700;
`;

const StyledNavLink = styled(NavLink)`
  ${logoStyle};
  color: ${(props: any) => props.theme.colors.onBackground};
`;

function ParticipantTile(props: any): JSX.Element {
  const { participant, tileStyle } = props;
  const tileSettings: any = getTileSettings(tileStyle);

  const StyledParticipantTile = styled("div")`
    ${tileParticipantStyle}
    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    font-size: ${tileSettings.fontSize};
    background-color: ${(props: any) => props.theme.colors.tileBackgroundColor};
    color: ${(props: any) => props.theme.colors.tileColor};
    border-radius: ${tileSettings.tileRadius};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  const StyledSupporterTile = styled("div")`
    ${tileParticipantStyle}
    width: ${tileSettings.tileWidth};
    height: ${tileSettings.tileHeight};
    font-size: ${tileSettings.fontSize};
    background-color: ${(props: any) =>
      props.theme.colors.tileSupportBackgroundColor};
    color: ${(props: any) => props.theme.colors.tileColor};
    border-radius: ${tileSettings.tileRadius};
    border-color: ${(props: any) => props.theme.colors.background};
  `;

  const participantsUrl = `/activity-participants/${participant.activityId}`;

  if (participant) {
    // participants
    if (!participant.supportOnly) {
      if (tileStyle === "flat") {
        return (
          <StyledNavLink to={participantsUrl}>
            <StyledParticipantTile>
              <StyledNameText>
                {participant.firstName} {participant.lastName}
              </StyledNameText>
            </StyledParticipantTile>
          </StyledNavLink>
        );
      } else if (tileStyle === "wide") {
        return (
          <StyledNavLink to={participantsUrl}>
            <StyledParticipantTile>
              <StyledNameText>{participant.firstName}</StyledNameText>
              <StyledNameText>{participant.lastName}</StyledNameText>
              <StyledRoleText>{participant.role}</StyledRoleText>
            </StyledParticipantTile>
          </StyledNavLink>
        );
      } else {
        return (
          <StyledNavLink to={participantsUrl}>
            <StyledParticipantTile>
              <StyledNameText>{participant.initials}</StyledNameText>
            </StyledParticipantTile>
          </StyledNavLink>
        );
      }
    } else {
      // non-participants
      if (tileStyle === "flat") {
        return (
          <StyledNavLink to={participantsUrl}>
            <StyledSupporterTile>
              {participant.firstName} {participant.lastName}
            </StyledSupporterTile>
          </StyledNavLink>
        );
      } else if (tileStyle === "wide") {
        return (
          <StyledNavLink to={participantsUrl}>
            <StyledSupporterTile>
              <StyledNameText>{participant.firstName}</StyledNameText>
              <StyledNameText>{participant.lastName}</StyledNameText>
              <StyledRoleText>{participant.role}</StyledRoleText>
            </StyledSupporterTile>
          </StyledNavLink>
        );
      } else {
        return (
          <StyledNavLink to={participantsUrl}>
            <StyledSupporterTile>
              <StyledNameText>{participant.initials}</StyledNameText>
            </StyledSupporterTile>
          </StyledNavLink>
        );
      }
    }
  } else {
    return <div />;
  }
}

export default ParticipantTile;
