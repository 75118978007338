export const getParticipant = participantId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let foundParticipants = [];

    firestore
      .collectionGroup('participants')
      .where('participantId', '==', `${participantId}`)
      .get()
      .then(docs => {
        docs.forEach(doc => {
          const activityParticipant = JSON.parse(JSON.stringify(doc.data()));
          foundParticipants.push(activityParticipant);
        });
      })
      .then(() => {
        dispatch({
          participant: foundParticipants[0],
          type: 'GET_PARTICIPANT_SUCCESS'
        });
      })
      .catch(err => {
        dispatch({ type: 'GET_PARTICIPANT_ERROR' }, err);
      });
  };
};

export const updateParticipant = participant => {
return (dispatch, getState, { getFirebase, getFirestore }) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  firestore
    .collection("activities")
    .doc(participant.activityId)
    .collection("participants")
    .doc(participant.participantId)
    .update({
      ...participant,
      changedAt: firebase.firestore.Timestamp.now()
    })
    .then(() => {
      dispatch({
        participant: participant,
        type: "UPDATE_PARTICIPANT_SUCCESS"
      });
    })
    .catch(err => {
      dispatch({ type: "UPDATE_PARTICIPANT_ERROR" }, err);
    });
};
};

export const deleteParticipant = participant => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('activities')
      .doc(participant.activityId)
      .collection('participants')
      .doc(participant.participantId)
      .delete()
      .then(() => {
        dispatch({
          type: 'DELETE_PARTICIPANT_SUCCESS'
        });
      })
      .catch(err => {
        dispatch({ type: 'DELETE_PARTICIPANT_ERROR' }, err);
      });
  };
};

export const clearParticipantState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_PARTICIPANT_STATE"
    });
  };
};
