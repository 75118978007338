import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";

import {
  updateUserSettings,
  getUserDetails
} from "../../store/actions/userActions";
// import ProfileField from "../fields/ProfileField";

// import { devLog } from '../../utils';
import {
  getLanguageCode,
  DEFAULT_USER_SETTINGS,
  VERSION
} from "../../config/settings";
import { getCurrentVersion } from "../../utils/serviceWorker";
// import { getValueFromField } from "../../utils/htmlUtils";
import Label from "../layout/Label";
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

const Settings = (props: any) => {
  const { auth, userDetails, getUserDetails, updateUserSettings } = props;
  const { uid } = auth;
  const [formValues, setFormValues] = useState({
    receivedFromUserDetails: false,
    language: "",
    theme: ""
  });

  useEffect(() => {
    if (uid) {
      getUserDetails(uid);
    }
  }, [uid, getUserDetails]);

  if (!auth.uid) {
    return <Redirect to="/signin" />;
  }
  const handleChangeLanguage = (event: any) => {
    setFormValues({
      ...formValues,
      language: event.target.value
    });
  };
  const handleChangeTheme = (event: any) => {
    setFormValues({
      ...formValues,
      theme: event.target.value
    });
  };

  const handleSave = () => {
    // get new form values from DOM for input fields and formValues for select menu fields.
    // let newFormValues = {
    //   receivedFromUserDetails: true,
    //   language: formValues.language,
    //   theme: formValues.theme
    // };

    // create the new settings.
    if (userDetails) {
      let newSettings = {};
      if (userDetails.settings) {
        newSettings = {
          ...userDetails.settings,
          language: formValues.language,
          theme: formValues.theme
        };
      } else {
        newSettings = {
          ...DEFAULT_USER_SETTINGS,
          language: formValues.language,
          theme: formValues.theme
        };
      }
      updateUserSettings(newSettings);
      window.history.back();
    }
  };

  if (formValues && formValues.receivedFromUserDetails) {
    const currentVersion = getCurrentVersion();
    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard raised>
            <form>
              <StyledFormControl variant="outlined">
                <InputLabel>Theme</InputLabel>
                <Select
                  id="theme"
                  value={formValues.theme}
                  onChange={handleChangeTheme}
                  autoWidth={false}
                  labelWidth={70}
                  displayEmpty={false}
                  inputProps={{
                    name: "age",
                    id: "outlined-age-simple"
                  }}
                >
                  <MenuItem value={"dark"}>Dark</MenuItem>
                  <MenuItem value={"light"}>Light</MenuItem>
                  <MenuItem value={"indigo"}>Indigo</MenuItem>
                  <MenuItem value={"teal"}>Teal</MenuItem>
                </Select>
              </StyledFormControl>
              <StyledFormControl variant="outlined">
                <InputLabel>Language</InputLabel>
                <Select
                  id="language"
                  value={formValues.language}
                  onChange={handleChangeLanguage}
                  autoWidth={false}
                  labelWidth={70}
                  displayEmpty={false}
                  inputProps={{
                    name: "age",
                    id: "outlined-age-simple"
                  }}
                >
                  <MenuItem value={"EN"}>English</MenuItem>
                  <MenuItem value={"NL"}>Nederlands</MenuItem>
                  {/* <MenuItem value={"ES"}>Espanol</MenuItem> */}
                </Select>
              </StyledFormControl>
            </form>
            <StyledButton onClick={handleSave}>
              <Label label={"save-settings"} />
            </StyledButton>
          </StyledCard>
          <StyledVersion>
            {"Cur:"}
            {currentVersion}
            {" / "}
            {"Lat:"}
            {VERSION}
          </StyledVersion>
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  } else if (userDetails) {
    // check if there are settings.
    let userLanguage;
    if (userDetails.settings && userDetails.settings.language) {
      userLanguage = userDetails.settings.language;
    } else {
      userLanguage = getLanguageCode(window.navigator.language);
    }

    let userTheme;
    if (userDetails.settings && userDetails.settings.theme) {
      userTheme = userDetails.settings.theme;
    } else {
      userTheme = "light";
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        userTheme = "dark";
      }
    }

    setFormValues({
      ...formValues,
      receivedFromUserDetails: true,
      language: userLanguage,
      theme: userTheme
    });
    return <div></div>;
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    userDetails: state.user.userDetails
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserDetails: (userDetails: any) => dispatch(getUserDetails(userDetails)),
    updateUserSettings: (newSettings: any) =>
      dispatch(updateUserSettings(newSettings))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
  `;
const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  ${buttonStyle}
`;
const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.secundary};
  ${cardStyle}
`;
const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 15px;
`;
const StyledVersion = styled(Typography)`
  background: ${(props: any) => props.theme.colors.secundary};
  float: right;
  margin-top: 30px;
  font-size: 0.5rem;
  opacity: 0.7;
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;
