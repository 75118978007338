import React, { useState } from "react";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import teal from "@material-ui/core/colors/teal";
import grey from "@material-ui/core/colors/grey";
import styled from "styled-components";
import { textFieldStyle } from "../../config/styles";
import { getLabel, getPlaceholder, getHelperText } from "../../config/labels";
import { getValueFromField } from "../../utils/htmlUtils";
import { dateStringToDate } from "../../utils";

import { isPast, addHours } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

const standardTheme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: teal
  }
});

const StyledDiv = styled("div")`
  ${textFieldStyle}
  margin-top: 10px;
  color: ${props => props.theme.colors.onBackground};
`;

function DatePickerTimeField(props) {
  const { profile, name } = props;
  const [dateTime, setDateTime] = useState(props.defaultValue);
  const [minDateTime, setMinDateTime] = useState(props.minDateTime);
  const [count, setCount] = useState(1);
  const [locale, setLocale] = useState("en");
  const [fieldProps, setFieldProps] = useState({
    error: false,
    helperText: false,
    initialFocusedDate: null,
    required: false,
    placeholder: ""
  });

  if (count) {
    let userLanguage = window.navigator.language;
    if (profile && profile.settings && profile.settings.language) {
      fieldProps.label = getLabel(name, userLanguage);
      fieldProps.required = true;
      userLanguage = profile.settings.language;
      setLocale(userLanguage);
    }

    switch (name) {
      case "start-date-time":
        if (!props.defaultValue || isPast(props.defaultValue))
          setDateTime(null);
        fieldProps.helperText = props.helperText;
        fieldProps.error = props.error;
        fieldProps.required = true;
        fieldProps.placeholder = getPlaceholder(name, userLanguage);
        setFieldProps(fieldProps);
        break;
      case "end-date-time":
        if (!props.defaultValue || isPast(props.defaultValue))
          setDateTime(null);
        if (props.minDateTime) {
          setMinDateTime(props.minDateTime);
        } else setMinDateTime(new Date());
        fieldProps.required = true;
        fieldProps.placeholder = getPlaceholder(name, userLanguage);
        setFieldProps(fieldProps);
        break;
      default:
        if (!props.defaultValue || isPast(props.defaultValue))
          setDateTime(null);
        fieldProps.placeholder = getPlaceholder(name, userLanguage);
        if (!props.defaultValue) setDateTime(null);
        setFieldProps(fieldProps);
        break;
    }
    setCount(count - 1);
  }

  const accept = () => {
    setFieldProps({
      ...fieldProps,
      error: false,
      helperText: ""
    });
  };

  const reject = name => {
    setFieldProps({
      ...fieldProps,
      error: true,
      helperText: getHelperText(name, locale)
    });
  };

  const handleBlur = event => {
    switch (name) {
      case "start-date-time":
        if (event.target.value) {
          accept();
        } else {
          reject(name);
        }
        break;
      case "end-date-time":
        // get the date of element 'start-date-time' and check is end-date-time is after start-date-time
        break;
      default:
        accept();
        break;
    }
  };

  const handleMouseLeave = event => {
    switch (name) {
      case "start-date-time":
        accept();
        break;
      default:
        accept();
        break;
    }
  };

  const handleOnFocus = () => {
    switch (name) {
      case "end-date-time":
        const value = getValueFromField("start-date-time");
        const dateTime = dateStringToDate(value);
        const initialFocusedDate = addHours(dateTime, 1);
        setFieldProps({
          ...fieldProps,
          initialFocusedDate: initialFocusedDate
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <ThemeProvider theme={standardTheme}>
        <StyledDiv>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              id={props.name}
              ampm={false}
              autoOk
              clearable
              disablePast
              disableToolbar
              error={fieldProps.error}
              format="MM-dd-yyyy HH:mm"
              helperText={fieldProps.helperText}
              inputVariant="outlined"
              initialFocusedDate={fieldProps.initialFocusedDate}
              minDate={minDateTime}
              minutesStep={5}
              placeholder={fieldProps.placeholder}
              value={dateTime}
              onChange={setDateTime}
              onFocus={handleOnFocus}
              onMouseLeave={handleMouseLeave}
              onBlur={handleBlur}
              fullWidth={props.fullWidth}
              required={true}
            />
          </MuiPickersUtilsProvider>
        </StyledDiv>
      </ThemeProvider>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(DatePickerTimeField);
