import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import reportAbsenceImage from "../../img/report-absence.png";

import {
  iconGettingStartedStyle,
  instructionAvatarStyle,
  instructionImageStyle,
  instructionTextStyle,
  instructionTextWrapper
} from "../../config/styles";

import { getInstructionText } from "../../language";

const StyledAvatar = styled(Avatar)`
  ${instructionAvatarStyle}
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledImage = styled("img")`
  ${instructionImageStyle}
`;
const StyledText = styled(Typography)`
  ${instructionTextStyle}
  color: ${(props: any) => props.theme.colors.onBackground};
  background-color: ${(props: any) => props.theme.colors.background};
`;
const StyledIcon = styled(TouchAppIcon)`
  ${iconGettingStartedStyle}
  color: ${(props: any) => props.theme.colors.textIcon};
`;
const StyledTextWrapper = styled("div")`
  ${instructionTextWrapper}
`;

export default function ReportAbsence(props: any) {
  const { language, mobileSize } = props;

  if (language) {
    const txt = getInstructionText("reportAbsence", language);

    const txtBlock = (
      <StyledTextWrapper>
        <StyledAvatar>
          <StyledIcon />
        </StyledAvatar>
        <StyledText>{txt}</StyledText>
      </StyledTextWrapper>
    );

    const blockToShow = mobileSize ? (
      <div>
        {txtBlock}
        <StyledImage src={reportAbsenceImage} />
      </div>
    ) : (
      <div>
        {txtBlock}
        <StyledImage src={reportAbsenceImage} />
      </div>
    );

    return <div>{blockToShow}</div>;
  } else return null;
}
