import React from "react";

import InstructionPaper from "./InstructionPaper";
import { getLabel } from "../../config/labels";
import { getHowToTexts } from "../../language";

import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {} from "../../config/styles";

const StyledH3 = styled(Typography)`
  font-size: 30px;
  font-weigth: 500;
  margin-top: 30px;
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;

const HowTo = (props: any) => {
  const { language } = props;

  let hoe = "";
  if (language) {
    hoe = `${getLabel("how-to", language.substring(0, 2).toUpperCase())}`;
  }

  const howToTexts = getHowToTexts(language);

  return (
    <div>
      <StyledH3>{hoe}</StyledH3>
      {howToTexts.map((text: any) => {
        return (
          <div>
            <InstructionPaper text={text} language={language} />
          </div>
        );
      })}
    </div>
  );
};

export default HowTo;
