import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { backgroundDivStyle, getHeight } from "../../config/styles";
import { getNavigatorLanguage } from "../../language";
import { getLabel } from "../../config/labels";
import AddNewActivity from "../instructions/AddActivity";
import AddParticipant from "../instructions/AddParticipant";
import AddEvent from "../instructions/AddEvent";
import PresOverview from "../instructions/PresOverview"
import ReportAbsence from "../instructions/ReportAbsence";
;

const UsingPresYes = (props: any) => {
  const { auth, profile } = props;
  const [mobileSize, setMobileSize] = React.useState(
    window.innerWidth < 640 ? true : false
  );
  const determineSize = () => {
    if (window.innerWidth < 640) {
      setMobileSize(true);
    } else {
      setMobileSize(false);
    }
  };
  window.addEventListener("resize", determineSize);

  if (!auth || !auth.uid) return <Redirect to="/signin" />;

  let language = getNavigatorLanguage();
  let how = "";
  if (profile && profile.settings && profile.settings.language) {
    language = profile.settings.language;
    how = `${getLabel("using-presyes", language)}`;
  }
  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledH3>{how}</StyledH3>
        <AddNewActivity language={language} mobileSize={mobileSize} />
        <AddParticipant language={language} mobileSize={mobileSize} />
        <AddEvent language={language} mobileSize={mobileSize} />
        <ReportAbsence language={language} mobileSize={mobileSize} />
        <PresOverview language={language} mobileSize={mobileSize} />
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(UsingPresYes);

// Styles
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    min-height: ${getHeight()}px;
    getHeight()
  `;
const StyledH3 = styled(Typography)`
  font-size: 40px;
  font-weigth: 800;
  margin-top: 40px;
  margin-bottom: 30px;
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledWrapper = styled("div")`
  width: 60%;
  @media (max-width: 640px) {
    width: 80%;
  }
`;
