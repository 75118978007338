import React from "react";
// import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Divider from "@material-ui/core/Divider";

import Message from './Message'
import AddMessageBar from "./AddMessageBar";
// import Divider from "@material-ui/core/Divider";
// import ListItemText from "@material-ui/core/ListItemText";
// import Lens from "@material-ui/icons/Lens";

import styled from "styled-components";
// import {
//   cardIconStyle,
//   messageHeaderStyle,
//   messageTextStyle,
//   navLinkStyle
// } from "../../config/styles";

// import Label from '../layout/Label';
import { getEventParticipation } from "../../store/actions/eventActions";
// import { getLabel } from "../../config/labels";

function MessageWithReply(props: any) {
  const { auth, profile, message, setGetMessages } = props;
  const [ showAddMessageBar, setShowAddMessageBar ] = React.useState(false)

  const eventDataFromMessage = {
    activityId: message.activityId,
    activityName: message.activityName ? message.activityName : "",
    eventId: message.eventId,
    eventName: message.eventName,
    startDateTime: message.eventStartDateTime
  };

  const addMessageBar = showAddMessageBar ? (
    <AddMessageBar
      event={eventDataFromMessage}
      setGetMessages={setGetMessages}
      setShowAddMessageBar={setShowAddMessageBar}
    />
  ) : null;

  if (auth && profile && message) {
    return (
      <div>
        <Message
          message={message}
          showReply
          setShowAddMessageBar={setShowAddMessageBar}
        />
        {addMessageBar}
        <StyledDivider />
      </div>
    );
  } else {
    return <div />;
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    participation: state.event.participation
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventParticipation: (eventId: string, uid: string) =>
      dispatch(getEventParticipation(eventId, uid))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageWithReply);

const StyledDivider = styled(Divider)`
  background-color: ${(props: any) => props.theme.colors.onBackground};
  margin-bottom: 0px;
  opacity: 0.1;
`;