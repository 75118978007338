import React from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import Label from "../layout/Label";
// import { getLabel, getPlaceholder, getHelperText } from "../../config/labels";
import { searchUser } from "../../store/actions/userActions";
import { addParticipant } from "../../store/actions/activityActions";
import { createDisplayName } from "../../utils/index";
// import { validateEmail } from "../../utils/validations";
// import { buttonStyle } from "../../config/styles";

// const StyledAddCircle = styled(AddCircle)`
//   background-color: ${props => props.theme.colors.fab};
//   color: ${props => props.theme.colors.fabIcon};
// `;

const AddFab = styled(Fab)`
  background-color: ${props => props.theme.colors.fab};
  color: ${props => props.theme.colors.fabIcon};
  float: right;
  margin-bottom: 5px;

  width: 50px;
  height: 50px;
  @media (max-width: 1280px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 960px) {
    width: 45px;
    height: 45px;
  @media (max-width: 640px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledSearchDiv = styled("div")`
  padding-top: 15px;
  padding-top: 10px;
  padding-left: 12px;
  background-color: ${props => props.theme.colors.primaryLight};
`;

const FoundUser = props => {
  const {
    foundUser,
    activityId,
    setSearchValue,
    addParticipant,
    setShowMessage
  } = props;

  const displayName = createDisplayName(
    foundUser.firstName,
    foundUser.lastName
  );
  let secondaryText;
  if (foundUser.bioKeywords) {
    secondaryText = `${foundUser.email} (${foundUser.bioKeywords})`;
  } else {
    secondaryText = `${foundUser.email}`;
  }

  const handleAdd = () => {
    const email = foundUser.email;
    const participant = {
      email: email,
      activityId: activityId,
      admin: false,
      defaultPresence: true
    };
    addParticipant(participant);
    setSearchValue("");
    setShowMessage(true);
  };

  return (
    <div>
      <ListItem>
        <ListItemText primary={displayName} secondary={secondaryText} />
        <ListItemSecondaryAction>
          <Button onClick={handleAdd}>
            <AddFab>
              <AddIcon />
            </AddFab>
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </div>
  );
};

const SelectUserField = props => {
  const {
    addParticipant,
    foundUsers,
    searchUser,
    setSearchValue,
    searchValue,
    findUsers,
    setFindUsers,
    setShowMessage,
    activityId
  } = props;

  // const StyledButton = styled(Button)`
  //   background: ${props => props.theme.colors.primaryVariant};
  //   color: ${props => props.theme.colors.onBackground};
  //   ${buttonStyle}
  // `;

  React.useEffect(() => {
    if (findUsers && searchValue) {
      searchUser(searchValue);
    }
  }, [searchUser, findUsers, searchValue]);

  if (foundUsers) {
    if (foundUsers.length === 0) {
      return (
        <StyledSearchDiv>
          <Label label="enter-email-invite" />
        </StyledSearchDiv>
      );
    } else if (findUsers) {
      setFindUsers(false);
      return (
        <div>
          <Divider />
          <List>
            {foundUsers.map(foundUser => {
              return (
                <FoundUser
                  key={foundUser.userId}
                  foundUser={foundUser}
                  activityId={activityId}
                  setSearchValue={setSearchValue}
                  addParticipant={addParticipant}
                  setShowMessage={setShowMessage}
                />
              );
            })}
          </List>
        </div>
      );
    } else {
      return (
        <div>
          <Divider />
          <List>
            {foundUsers.map(foundUser => {
              return (
                <FoundUser
                  key={foundUser.userId}
                  foundUser={foundUser}
                  activityId={activityId}
                  setSearchValue={setSearchValue}
                  addParticipant={addParticipant}
                  setShowMessage={setShowMessage}
                />
              );
            })}
          </List>
        </div>
      );
    }
  } else {
    return null;
  }
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    foundUsers: state.user.foundUsers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchUser: searchValue => dispatch(searchUser(searchValue)),
    addParticipant: participant => dispatch(addParticipant(participant))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectUserField);
