const initState = {
  firebaseTestError: null
};

const firebaseTestReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FIREBASE_TEST_ERROR':
      return {
        ...state,
        firebaseTestError: 'Getting activity failed'
      };

    case 'FIREBASE_TEST_SUCCESS':
      return {
        ...state,
        firebaseTestError: null
      };

    default:
      return state;
  }
};

export default firebaseTestReducer;
