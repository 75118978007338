// STYLE CONFIGURATION PARAMETERS FOR STYLED OMPONENTS

// export const addFabStyle = `
//     position: fixed;
//     right: 200px;

//     @media (min-width: 641px) {
//       bottom: 65px;
//     }

//     width: 65px;
//     height: 65px;
//     @media (max-width: 1280px) {
//       right: 120px;
//       bottom: 65px;
//       width: 65px;
//       height: 65px;
//     }
//     @media (max-width: 960px) {
//       right: 40px;
//       width: 60px;
//       height: 60px;
//     }
//     @media (max-width: 640px) {
//       right: 20px;
//       bottom: 65px;
//       width: 50px;
//       height: 50px;
//     }
// `;

export const getHeight = () => window.innerHeight;

export const addFabStyle = `
    position: fixed;
    z-index: 9999;
    width: 35px;
    height: 35px;

    @media (min-width: 641px) {
      bottom: 65px;
      top: 52x;
      right: 20px;
    }
    @media (max-width: 640px) {
      bottom: 30px;
      right: 5px;
    }
`;

export const addFabIconStyle = `
    position: fixed;
    z-index: 9999;
    width: 30px;
    height: 30px;

    @media (min-width: 641px) {
      top: 57px;
      right: 20px;
    }
    @media (max-width: 640px) {
      bottom: 12px;
      right: 5px;
    }
`;

export const backgroundDivStyle = `
  width: 100%;
  @media (min-width: 641px) {
    padding-top: 110px;
    margin-bottom: 2px;
  }
  @media (max-width: 640px) {
    padding-top: 50px;
    margin-bottom: 22px;
  }
`;

export const baseFieldStyle = `
    margin-left: 0.2em;
    margin-rigth: 0.2em;
    width: 50%;
    @media (max-width: 1280px) {
      padding: 1.5em;
      margin: 1.5em;
      width: 65%;
    }
    @media (max-width: 960px) {
      padding: 1em;
      margin: 1em;
      width: 80%;
    }
    @media (max-width: 640px) {
      padding: 0.5em;
      margin: 0.5em;
      width: 96%;
    }

    && :hover {
      border-color: #000000;
    }
    && :focus {
      border-color: #000000
      background-color: #fdfdfd
        }
`;
export const buttonStyle = `
    margin-top: 2em;
    color: white;
    text-align: center;
    width: 100%;
    height: 4.2em;
    && :hover {
      color: black;
    }
  `;

export const buttonParticipationStyle = `
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    border: solid 0px;
    border-radius: 3%;
    border-color: black;
    font-weight: bold;
  `;

export const cardStyle = `
    align-self: center;
    padding: 2em;
    
    margin: 10px;
    width: 50%;

    &&& {
      padding-top: 0px;
      padding-bottom: 10px;
    }

    @media (max-width: 1280px) {
      padding: 1.5em;
      margin: 10px;
      width: 70%;
    }
    @media (max-width: 960px) {
      padding: 1em;
      margin: 5px;
      width: 80%;
    }
    @media (max-width: 640px) {
      padding: 0.5em;
      margin: 2px;
      width: 90%;
    }
    @media (max-width: 640px) {
      padding: 0.5em;
      margin: 1px;
      width: 94%;
    }
`;

export const cardButtonStyle = `

  :hover {
    cursor: pointer;
    }
  font-size: 14px;
  @media (max-width: 1280px) {
    font-size: 13px;
  }
  @media (max-width: 960px) {
    font-size: 12px;
  }
  @media (max-width: 640px) {
    font-size: 12px;
  }
`;

export const cardButtonTextStyle = `
  :hover {
    cursor: pointer;
    }
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 1280px) {
    font-size: 15px;
  }
  @media (max-width: 960px) {
    font-size: 13px;
  }
  @media (max-width: 640px) {
    font-size: 13px;
  }
  @media (max-width: 412px) {
    font-size: 12px;
  }
`;

export const cardDayFieldStyle = `
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 15px;
  }
  @media (max-width: 960px) {
    font-size: 14px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
  @media (max-width: 412px) {
    font-size: 13px;
  }
`;

export const cardFieldTextStyle = `
  font-size: 17px;
  @media (max-width: 1280px) {
    font-size: 16px;
  }
  @media (max-width: 960px) {
    font-size: 15px;
  }
  @media (max-width: 640px) {
    font-size: 15px;
  }
  @media (max-width: 412px) {
    font-size: 14px;
  }
`;

export const cardHeaderTextStyle = `
    font-size: 38px;
    @media (max-width: 1280px) {
      font-size: 32px;
    }
    @media (max-width: 960px) {
      font-size: 26px;
    }
    @media (max-width: 640px) {
      font-size: 22px;
    }
`;

export const cardIconStyle = `
  cursor: pointer;
  cursor: hand;
  height: 35px;
  width: 35px;
  }
  margin-right: 15px;
  @media (max-width: 1280px) {
    height: 35px;
    width: 35px;
  }
  @media (max-width: 960px) {
    height: 35px;
    width: 35px;
  }
  @media (max-width: 640px) {
    margin-right: 12px;
    height: 30px;
    width: 30px;
  }
  @media (max-width: 412px) {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }
`;

export const cardIconWrappeStyle = `
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const eventWrapperStyle = `
    display: flex;
    flex-grow: 1;

    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    max-width: 100%;
    overflow-y: overlay;

    padding-left: 100px;
    @media (max-width: 1280px) {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media (max-width: 960px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (max-width: 640px) {
      padding-left: 10x;
      padding-right: 10px;
    }
`;

export const h1Style = `
    color: gray;
    text-align: center;
    font-size: 5em;
    @media (max-width: 1280px) {
      font-size: 6em;
    }
    @media (max-width: 960px) {
      font-size: 4em;
    }
    @media (max-width: 640px) {
      font-size: 2em;
    }
`;

export const h2Style = `
    margin-bottom: 0px;
    margin-top: 60px;
    font-size: 55px;
    @media (max-width: 1280px) {
      font-size: 50px;
    }
    @media (max-width: 960px) {
      font-size: 45px;
    }
    @media (max-width: 640px) {
      margin-top: 80px;
      font-size: 40px;
    }
`;

export const homeTextStyle = `
  font-size: 22px;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-width: 640px) {
    font-size: 17px;
  }
`;

export const imageStyle = `
    width: 70%;
    margin-top: 30px;
    margin-bottom: 60px;
    @media (max-width: 960px) {
      width: 80%;
    }
    @media (max-width: 640px) {
      width: 90%;    
    }
    @media (max-width: 640px) {
      width: 100%;  
    }
`;

export const instructionAvatarStyle = `
  height: 70px;
  width: 70px;
  margin-top: -20px;
  margin-right: 10px;
  margin-bottom: 0px;
`;
export const instructionHeaderStyle = `
  font-weight: 500;
  font-size: 20px;
  @media (max-width: 1280px) {
    font-size: 19px;
  }
  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-width: 640px) {
    font-size: 17px;
  }
`;
export const instructionImageStyle = `
  border-radius: 2%;
  margin-top: -0px;
  margin-bottom: 0px;
  
  width: 80%;
  @media (max-width: 960px) {
    width: 80%;
  }
  @media (max-width: 640px) {
    width: 90%;    
  }
  @media (max-width: 640px) {
    width: 100%;  
  }
`;

export const instructionTextStyle = `
  font-weight: 400;
  font-size: 23px;
  @media (max-width: 1280px) {
    font-size: 21px;
  }
  @media (max-width: 960px) {
    font-size: 19px;
  }
  @media (max-width: 640px) {
    font-size: 18px;
  }
`;

export const instructionTextWrapper = `
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 0px;
`;

export const iconButtonStyle = `
  margin-left: 0.8em;
  cursor: pointer;
  cursor: hand;
`;

export const iconGettingStartedStyle = `
  width: 40px;
  height: 40px;
`;

export const listItemIconStyle = `
  cursor: pointer;
  cursor: hand;

  height: 34px;
  width: 34px;
  @media (max-width: 1280px) {
    height: 32px;
    width: 32px;
  }
  @media (max-width: 960px) {
    height: 30px;
    width: 30px;
  }
  @media (max-width: 640px) {
    height: 28px;
    width: 28px;
  }
`;

export const messageHeaderStyle = `
  font-weight: 400;
  opacity: 0.3;
  font-size: 1rem;
  @media (max-width: 1280px) {
    font-size: 1rem;
  }
  @media (max-width: 960px) {
    font-size: 1rem;
  }
  @media (max-width: 640px) {
    font-size: 0.9rem;
  }
  @media (max-width: 412px) {
    font-size: 0.8rem;
  }
`;

export const messageTextStyle = `
  font-size: 1.1rem;
  @media (max-width: 1280px) {
    font-size: 1.1rem;
  }
  @media (max-width: 960px) {
    font-size: 1.1rem;
  }
  @media (max-width: 640px) {
    font-size: 1.1rem;
  }
  @media (max-width: 412px) {
    font-size: 1rem;
  }
`;

export const listItemFieldTextStyle = `
  font-size: 1rem;
  @media (max-width: 1280px) {
    font-size: 1rem;
  }
  @media (max-width: 960px) {
    font-size: 1rem;
  }
  @media (max-width: 640px) {
    font-size: 0.9rem;
  }
  @media (max-width: 412px) {
    font-size: 0.8rem;
  }
  opacity: 0.8;
`;

export const listItemHeaderTextStyle = `
  font-size: 1.4rem;
  @media (max-width: 1280px) {
    font-size: 1.4rem;
  }
  @media (max-width: 960px) {
    font-size: 1.4rem;
  }
  @media (max-width: 640px) {
    font-size: 1.3rem;
  }
  @media (max-width: 412px) {
    font-size: 1.2rem;
`;

export const logoStyle = `
  text-decoration: none;
  font-size: 1.2em;
`;

export const mainBarStyle = `
  position: fixed;
  right: 0px;
  top: 0px;
  height: 50px;
  padding-left: 15px;
  @media (max-width: 640px) {
    position: absolute;
    padding-left: 5px;
  }
`;

export const modalButtonStyle = `
    margin-top: 2em;
    color: white;
    text-align: center;
    width: 100%;
    height: 3em;
    && :hover {
      color: black;
    }
  `;

export const navBarStyle = `
  position: fixed;
  right: 0px;

  @media (min-width: 641px) {
    top: 50px;
    height: 45px;
  }
  @media (max-width: 640px) {
    bottom: 0px;
    height: 50px;
  }
  @media (max-width: 480px) {
    padding-right: 20px;
  }  
`;

export const navBarLinkStyle = `
  text-decoration: none;
`;

export const navbarLabelStyle = `
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 1280px) {
    font-size: 13px;
  }
  @media (max-width: 960px) {
    font-size: 13px;
  }
  @media (max-width: 640px) {
    font-size: 12px;
  }
`;

export const navbarIconStyle = `
  cursor: pointer;
  cursor: hand;

  height: 18px;
  width: 18px;
  @media (max-width: 1280px) {
    height: 18px;
    width: 18px;
  }
  @media (max-width: 960px) {
    height: 18px;
    width: 18px;
  }
  @media (max-width: 640px) {
    height: 17px;
    width: 17px;
  }
`;

export const navLinkStyle = `
  text-decoration: none;
`;

export const textFieldStyle = `
  margin-left: 0.2em;
  margin-left: 0.2em;
  && :hover {
    border-color: black;
  }
  && :focus {
    border-color: #000000
    background-color: #fdfdfd
  }

  height: 55px;
  @media (max-width: 1280px) {
    height: 55px;
  }
  @media (max-width: 960px) {
    height: 55px;
  }
  @media (max-width: 640px) {
    height: 50px;
  }
  @media (max-width: 412px) {
    height: 50px;
  }
`;

export const tinyTextButtonStyle = `

  margin: 1em;
  :hover {
    cursor: pointer;
    }
  font-size: 12px;
  @media (max-width: 1280px) {
    font-size: 11px;
  }
  @media (max-width: 960px) {
    font-size: 10px;
  }
  @media (max-width: 640px) {
    font-size: 10px;
  }
`;

const tileStyle = `
    @media (min-width: 641px) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    @media (max-width: 640px) {
      margin-right: 5px;
      margin-bottom: 5px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    border: solid 1px;
`;

export const tileParticipantStyle = `
    ${tileStyle}
  `;

export const tileParticipationStyle = `
    ${tileStyle}
  `;

export const tileEmptyStyle = `
    ${tileStyle}
    height: 50px;
  `;

export const wrapperStyle = `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
