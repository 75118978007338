// import { DEFAULT_USER_SETTINGS, getLanguageCode } from '../../config/settings';
import { generateUserSearchKeys, testLog } from "../../utils";

export const saveUser = user => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const userId = getState().firebase.auth.uid;

    firestore
      .collection("users")
      .doc(`${userId}`)
      .set({
        ...user,
        changedAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        dispatch({ type: "SAVE_USER_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SAVE_USER_ERROR" }, err);
      });
  };
};

export const getUserDetails = userId => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    let userDetails;

    firestore
      .collection("users")
      .doc(`${userId}`)
      .get()
      .then(doc => {
        if (doc.exists) {
          userDetails = doc.data();
        }
      })
      .then(() => {
        dispatch({
          userDetails: userDetails,
          type: "GET_USER_DETAILS_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_USER_DETAILS_SUCCESS" }, err);
      });
  };
};

export const getUserByEmail = email => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const foundUsers = [];
    firestore
      .collection("users")
      .where("email", "==", email)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const user = JSON.parse(JSON.stringify(doc.data()));
          foundUsers.push(user);
        });
      })
      .then(() => {
        dispatch({
          user: foundUsers[0],
          type: "GET_USER_BY_EMAIL_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "GET_USER_BY_EMAIL_ERROR" }, err);
      });
  };
};

export const updateUserSettings = newSettings => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection("users")
      .doc(`${userId}`)
      .update({
        settings: newSettings
      })
      .then(() => {
        dispatch({ type: "UPDATE_USER_SETTINGS_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_USER_SETTINGS_ERROR" }, err);
      });
  };
};

export const updateLastMessageRead = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    let userDetails;
    firestore
      .collection("users")
      .doc(`${userId}`)
      .get()
      .then(doc => {
        if (doc.exists) {
          userDetails = doc.data();
        }
        const previousMessageRead =
          userDetails && userDetails.lastMessageRead
            ? userDetails.lastMessageRead
            : firebase.firestore.Timestamp.now();
        firestore
          .collection("users")
          .doc(`${userId}`)
          .update({
            lastMessageRead: firebase.firestore.Timestamp.now(),
            previousMessageRead: previousMessageRead
          })
          .then(() => {
            dispatch({ type: "UPDATE_LAST_MESSAGE_READ_SUCCESS" });
          })
          .catch(err => {
            dispatch({ type: "UPDATE_LAST_MESSAGE_READ_ERROR" }, err);
          });
      })
      .catch(err => {
        dispatch({ type: "UPDATE_LAST_MESSAGE_READ_ERROR" }, err);
      });
  };
};

export const saveUserDetails = userDetails => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const userId = getState().firebase.auth.uid;

    const displayName = userDetails.firstName
      ? `${userDetails.firstName}${" "}${userDetails.lastName}`
      : `${userDetails.lastName}`;
    const initials =
      userDetails.firstName.charAt(0) +
      userDetails.lastName
        .split(" ")
        .pop()
        .charAt(0);

    firestore
      .collection("users")
      .doc(`${userId}`)
      .update({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        displayName: displayName,
        initials: initials,
        bioKeywords: userDetails.bioKeywords,
        keys: generateUserSearchKeys(
          userDetails.email,
          userDetails.firstName,
          userDetails.lastName
        ),
        settings: userDetails.settings,
        changedAt: firebase.firestore.Timestamp.now()
      })
      .then(() => {
        dispatch({ type: "SAVE_USER_DETAILS_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SAVE_USER_DETAILS_ERROR", err });
      });
  };
};

// action for generating keys for all users
export const generateUserKeys = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("users")
      .where("email", ">", "0")
      .get()
      .then(snapshot => {
        testLog("getUserByEmail - snapshot ", snapshot);
        snapshot.forEach(doc => {
          const user = JSON.parse(JSON.stringify(doc.data()));
          const id = doc.id;

          const keys = generateUserSearchKeys(
            user.email,
            user.firstName,
            user.lastName
          );
          firestore
            .collection("users")
            .doc(`${id}`)
            .update({
              keys: keys
            })
            .then(() => {
              dispatch({
                type: "SAVE_USER_KEYS_SUCCESS"
              });
            });
        });
      })
      .catch(err => {
        dispatch({ type: "SAVE_USER_KEYS_ERROR" }, err);
      });
  };
};

export const searchUser = value => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const foundUsers = [];
    firestore
      .collection("users")
      .where("keys", "array-contains", value.toLowerCase())
      .limit(7)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const user = { ...doc.data(), userId: doc.id };
          foundUsers.push(user);
        });
      })
      .then(() => {
        dispatch({
          foundUsers: foundUsers,
          type: "SEARCH_USER_SUCCESS"
        });
      })
      .catch(err => {
        dispatch({ type: "SEARCH_USER_ERROR" }, err);
      });
  };
};

export const clearUserState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_USER_STATE"
    });
  };
};
