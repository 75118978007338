import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SendIcon from "@material-ui/icons/Send";

import { addMessage } from "../../store/actions/messageActions";

import styled from "styled-components";
// import Label from "../layout/Label";
import { getLabel } from "../../config/labels";
import {
  cardFieldTextStyle,
  cardIconStyle,
} from "../../config/styles";
// import { devLog } from "../../utils";
import { getValueFromField, setValueOfField } from "../../utils/htmlUtils";
// import { devLog } from "../../utils";

function AddMessageBar(props: any) {
  const {
    auth,
    profile,
    addMessage,
    event,
    setGetMessages,
    setShowAddMessageBar
  } = props;
  const [showSendButton, setShowSendButton] = React.useState(false);

  if (auth && profile) {
    let userLanguage = window.navigator.language.toUpperCase().substring(0, 2);
    if (profile && profile.settings && profile.settings.language) {
      userLanguage = profile.settings.language;
    }

    const handleAddMessage = async () => {
      const messageText = getValueFromField("message-field");

      if (messageText) {
        const message = {
          activityId: event.activityId,
          activityName: event.activityName ? event.activityName : "",
          eventId: event.eventId,
          eventName: event.eventName,
          eventStartDateTime: event.startDateTime,
          messageText: messageText,
          senderDisplayName: profile.displayName,
          senderId: auth.uid,
          senderInitials: profile.initials
        };
        await addMessage(message);
        setValueOfField("message-field", "");
        setShowSendButton(false);
        setGetMessages(true);
        if (setShowAddMessageBar) setShowAddMessageBar(false);
      }
    };
    const send = showSendButton ? (
      <StyledButton onClick={handleAddMessage}>
        <StyledSendIcon />
      </StyledButton>
    ) : (
      <div />
    );
    const handleChange = () => {
      setShowSendButton(true);
    };

    return (
      <StyledInlineBlock>
        <StyledInputField
          id="message-field"
          onChange={handleChange}
          margin="dense"
          multiline
          placeholder={getLabel("message", userLanguage)}
          variant="outlined"
        />
        {send}
      </StyledInlineBlock>
    );
  } else return <div />;
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addMessage: (message: any) => dispatch(addMessage(message))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMessageBar);

//// Styled Components ****************
const StyledButton = styled(Button)`
  margin-top: -2px;
`;
const StyledInlineBlock = styled("span")`
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
`;
const StyledSendIcon = styled(SendIcon)`
  ${cardIconStyle}
  color: green;
`;
const StyledInputField = styled(OutlinedInput)`
  ${cardFieldTextStyle};
  font-size: 16px;
  color: ${(props: any) => props.theme.colors.onSurface};
  background: ${(props: any) => props.theme.colors.background};

  margin-left: 15px;
  margin-right: 0px;
  width: 85%;
  @media (max-width: 1280px) {
    width: 85%;
  }
  @media (max-width: 960px) {
    width: 80%;
  }
  @media (max-width: 640px) {
    width: 80%;
  }
  @media (max-width: 412px) {
    width: 70%;
  }
`;
