import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import { ACTIVITIES } from '../../config/links';

import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import {
  signUp,
  signUpWithGoogle
  // signUpWithFacebook
} from '../../store/actions/authActions';
import SpecialField from '../fields/SpecialField';

// import { devLog } from '../../utils';
import { getValueFromField } from '../../utils/htmlUtils';
import Label from '../layout/Label';
// import { getText } from '../../config/labels';
import {
  getHeight,
  buttonStyle,
  cardStyle,
  wrapperStyle,
  backgroundDivStyle
} from '../../config/styles';

  const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle};
    background-color: ${(props: any) => props.theme.colors.background};
    min-height: ${getHeight()}px;
  `;

  const StyledButton = styled(Button)`
    background: ${(props: any) => props.theme.colors.primary};
    ${buttonStyle}
    margin-bottom: 15px;
  `;

  const StyledGoogleButton = styled(Button)`
    background: #d50000;
    ${buttonStyle}
    margin-top: 15px;
    margin-bottom: 30px;
  `;

  const StyledCard = styled(Card)`
    background: ${(props: any) => props.theme.colors.secundary};
    ${cardStyle}
  `;

  const StyledWrapper = styled('div')`
    ${wrapperStyle}
  `;

  const StyledDiv = styled('div')`
    ${wrapperStyle}
  `;

const SignUp = (props: any) => {
  const { currentUser } = props;
  const [formValues, setFormValues] = useState({
    email: '',
    emailError: false,
    password: '',
    passwordError: false,
    repeatPassword: '',
    repeatPasswordError: false,
    firstname: '',
    firstnameError: false,
    lastname: '',
    lastnameError: false,
    bioKeywords: '',
    bioKeywordsError: false
  });

  if (currentUser && currentUser.emailVerified) {
    // const url = `${ACTIVITIES}/${auth.uid}`;
    const url = `/myhome`;
    return <Redirect to={url} />;
  } else if (currentUser && currentUser.emailVerified === false) {
    return <Redirect to="need-verification" />;
  }

  const handleSignUpWithGoogle = async () => {
    await props.signUpWithGoogle();
  };

  // const handleSignUpWithFacebook = async () => {
  //   await props.signUpWithFacebook();
  // };

  const handleSignUp = async () => {
    let newFormValues = {
      email: '',
      emailError: false,
      password: '',
      passwordError: false,
      repeatPassword: '',
      repeatPasswordError: false,
      firstname: '',
      firstnameError: false,
      lastname: '',
      lastnameError: false,
      bioKeywords: '',
      bioKeywordsError: false
    };

    let formError = false;
    newFormValues.email = getValueFromField('email');
    if (!newFormValues.email) {
      newFormValues.emailError = true;
      formError = true;
    }
    newFormValues.password = getValueFromField('password');
    if (!newFormValues.password) {
      newFormValues.passwordError = true;
      formError = true;
    }
    newFormValues.repeatPassword = getValueFromField('repeat-password');
    if (!newFormValues.repeatPassword) {
      newFormValues.repeatPasswordError = true;
      formError = true;
    }
    newFormValues.firstname = getValueFromField('firstname');
    if (!newFormValues.firstname) {
      newFormValues.firstnameError = true;
      formError = true;
    }
    newFormValues.lastname = getValueFromField('lastname');
    if (!newFormValues.lastname) {
      newFormValues.lastnameError = true;
      formError = true;
    }
    newFormValues.bioKeywords = getValueFromField('bio-keywords');

    if (formError) {
      setFormValues(newFormValues);
    } else {
      const credentials = {
        email: newFormValues.email.toLowerCase(),
        password: newFormValues.password,
        firstName: newFormValues.firstname,
        lastName: newFormValues.lastname,
        bioKeywords: newFormValues.bioKeywords
      };
      await props.signUp(credentials);
    }
  };

  // if (auth) devLog('SignUp - auth ', auth, false);
  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledCard raised>
          <form>
            <SpecialField
              name="email"
              defaultValue={formValues.email}
              error={formValues.emailError}
            />
            <SpecialField
              name="password"
              defaultValue={formValues.password}
              error={formValues.passwordError}
            />
            <SpecialField
              name="repeat-password"
              defaultValue={formValues.repeatPassword}
              error={formValues.repeatPasswordError}
            />
            <SpecialField
              name="firstname"
              defaultValue={formValues.firstname}
              error={formValues.firstnameError}
            />
            <SpecialField
              name="lastname"
              defaultValue={formValues.lastname}
              error={formValues.lastnameError}
            />
            <SpecialField
              name="bio-keywords"
              defaultValue={formValues.bioKeywords}
              error={formValues.bioKeywordsError}
            />
          </form>
          <StyledButton onClick={handleSignUp}>
            <Label label={'signup'} />
          </StyledButton>
          <StyledDiv>
            <Label label="or" />
          </StyledDiv>
          <StyledGoogleButton onClick={handleSignUpWithGoogle}>
            <Label label={'signup-google'} />
          </StyledGoogleButton>
        </StyledCard>
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    currentUser: state.auth.currentUser,
    authError: state.auth.authError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signUp: (credentials: any) => dispatch(signUp(credentials)),
    signUpWithGoogle: () => dispatch(signUpWithGoogle())
    // signUpWithFacebook: () => dispatch(signUpWithFacebook())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
