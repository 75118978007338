import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import GetStarted from "../instructions/GetStarted";
import { getLabel } from "../../config/labels";

import styled from "styled-components";
import {
  backgroundDivStyle,
  h2Style,
  logoStyle,
  getHeight
} from "../../config/styles";

import { getNavigatorLanguage } from "../../language";
// import { ReactComponent as Logo } from "../../img/presyes.svg";

const MyHome = (props: any) => {
  const { auth, profile } = props;

  if (auth && !auth.uid) {
    return <Redirect to="/" />;
  }

  let language = getNavigatorLanguage();
  let hello = "Hello";
  let how = "";
  let features = "";
  if (
    profile &&
    profile.settings &&
    profile.settings.language &&
    profile.firstName
  ) {
    language = profile.settings.language;
    hello = `${getLabel("hello", language)}${" "}${profile.firstName}`;
    how = `${getLabel("see-how", language)}`;
    features = `${getLabel("features", language)}`;
  }

  return (
    <StyledBackgroundDiv>
      <StyledWrapper>
        <StyledH2>{hello}</StyledH2>
        <GetStarted language={language} />
        <StyledNavLink to="/using-presyes">
          <StyledH2>{how}</StyledH2>
        </StyledNavLink>
        <StyledNavLink to="/features">
          <StyledH2>{features}</StyledH2>
        </StyledNavLink>
      </StyledWrapper>
    </StyledBackgroundDiv>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(MyHome);

// Styles
const StyledH2 = styled("h2")`
  ${h2Style}
  background-color: ${(props: any) => props.theme.colors.background};
  color: ${(props: any) => props.theme.colors.onBackground};
`;
const StyledBackgroundDiv = styled("div")`
    ${backgroundDivStyle}
    background-color: ${(props: any) => props.theme.colors.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    min-height: ${getHeight()}px;
  `;

const StyledNavLink = styled(NavLink)`
  ${logoStyle};
  color: ${(props: any) => props.theme.colors.logo};
  text-decoration: underline grey;
`;
const StyledWrapper = styled("div")`
  width: 60%;
  @media (max-width: 640px) {
    width: 80%;
  }
  @media (max-width: 412px) {
    width: 90%;
  }
`;
