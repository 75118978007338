const initState = {
  participant: null,
  participantError: null
};

const participantReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_USER_TO_ACTIVITY_ERROR":
      return {
        ...state,
        participantError: "Adding user to activity failed"
      };

    case "ADD_USER_TO_ACTIVITY_SUCCESS":
      return {
        ...state,
        participantError: null
      };

    case "GET_PARTICIPANT_ERROR":
      return {
        ...state,
        participantError: "Get Participant failed"
      };

    case "GET_PARTICIPANT_SUCCESS":
      return {
        ...state,
        participant: action.participant,
        participantError: null
      };

    case "UPDATE_PARTICIPANT_ERROR":
      return {
        ...state,
        participantError: "Updating participant failed"
      };

    case "UPDATE_PARTICIPANT_SUCCESS":
      return {
        ...state,
        participant: action.participant,
        participantError: null
      };
    case "DELETE_PARTICIPANT_ERROR":
      return {
        ...state,
        participantError: "Deleting participant failed"
      };

    case "DELETE_PARTICIPANT_SUCCESS":
      return {
        ...state,
        participantError: null
      };

    case "CLEAR_PARTICIPANT_STATE":
      return {
        ...state,
        participant: null,
        participantError: null
      };

    default:
      return state;
  }
};

export default participantReducer;
