import React from "react";

import styled from "styled-components";
// import { cardStyle, logoStyle, wrapperStyle } from '../../config/styles';

import ParticipantTile from "./ParticipantTile";
import ChangeHeaderWidthTile from "../participations/ChangeHeaderWidthTile";
import ChangeAxisTile from "../participations/ChangeAxisTile";
// import SumTile from '../participations/SumTile';
// import { tileSettingsWide, tileSettingsSquare } from '../../config/settings';

export default function ParticipationsLineUp(props: any): JSX.Element {
  const { participants, flexDirection, tileStyle } = props;

  const StyledWrapper = styled("div")`
    display: flex;
    flex-direction: ${flexDirection};
  `;

  return (
    <StyledWrapper>
      <ChangeAxisTile tileStyle={tileStyle} />
      <ChangeHeaderWidthTile tileStyle={tileStyle} />
      {participants.map((participant: any) => {
        return (
          <ParticipantTile participant={participant} tileStyle={tileStyle} />
        );
      })}
    </StyledWrapper>
  );
}
