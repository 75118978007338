import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
// import { isMobile } from "../../utils/device";

import { ACTIVITIES, UPCOMING } from "../../config/links";
import { newMessageCheck } from "../../store/actions/messageActions";
import { devLog } from "../../utils";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import styled from "styled-components";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
// import NavigateNext from "@material-ui/icons/NavigateNext";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import Label from "./Label";
import {
  navBarStyle,
  navBarLinkStyle,
  // navbarIconStyle,
  navbarLabelStyle
} from "../../config/styles";
import { Typography } from "@material-ui/core";
import { setCurrentVersion } from "../../utils/serviceWorker";


const handleArrowBack = () => {
  window.history.back();
};

// const handleArrowForward = () => {
//   window.history.forward();
// };

function NavBar(props: any) {
  const { currentUser, newMessageCheck, nrNewMessages } = props;
  const [showBadge, setShowBadge] = React.useState(true);

  React.useEffect(() => {
    devLog("NavBar - MOUNTS ****************************");
    newMessageCheck();
    setShowBadge(true);
    setInterval(() => {
      newMessageCheck();
      setShowBadge(true);
    }, 1000 * 60);
    setTimeout(() => setCurrentVersion(), 4000);
    devLog("NavBar - message check ", new Date());

    return () => {
      devLog("NavBar - UNMOUNTS ----------------------- ");
    };
  }, [newMessageCheck]);

  const icon =
    nrNewMessages && showBadge ? (
      <Badge color="error" overlap="circle" badgeContent="!">
        <StyledMailOutlineIcon />
      </Badge>
    ) : (
      <StyledMailOutlineIcon />
    );

  if (currentUser && currentUser.emailVerified) {
    return (
      <div>
        <StyledNavBar position="static">
          <Tabs centered value={false}>
            <StyledArrowBack onClick={handleArrowBack} />
            <StyledNavLink to={ACTIVITIES}>
              <Tab
                value={0}
                label={
                  <StyledLabel>
                    <Label label={"activities"} />
                  </StyledLabel>
                }
              />
            </StyledNavLink>
            <StyledNavLink to="/messages">
              <Tab value={1} icon={icon} onClick={() => setShowBadge(false)} />
            </StyledNavLink>
            <StyledNavLink to={UPCOMING}>
              <Tab
                value={2}
                label={
                  <StyledLabel>
                    <Label label={"upcoming"} />
                  </StyledLabel>
                }
              />
            </StyledNavLink>
          </Tabs>
        </StyledNavBar>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentUser: state.auth.currentUser,
    nrNewMessages: state.message.nrNewMessages
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    newMessageCheck: () => dispatch(newMessageCheck())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

const StyledNavBar = styled(AppBar)`
  ${navBarStyle}
  color: ${(props: any) => props.theme.colors.onSecondary};
  background-color: ${(props: any) => props.theme.colors.secondary};
`;

// const StyledNotificationsIcon = styled(NotificationsIcon)`
//   ${navbarIconStyle}
//   color: ${props => props.theme.colors.onSecondary};
// `;

const StyledNavLink = styled(NavLink)`
  color: ${(props: any) => props.theme.colors.onSecondary};
  ${navBarLinkStyle};
`;

const StyledLabel = styled(Typography)`
  ${navbarLabelStyle};
`;

const StyledArrowBack = styled(NavigateBefore)`
  color: ${(props: any) => props.theme.colors.onSecondary};
  padding-top: 12px;
  margin-left: 4px;
`;

const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  color: ${(props: any) => props.theme.colors.onSecondary};
  margin-left: 0px;
  margin-right: 0px;
`;
