import isEmail from 'validator/lib/isEmail';
import { getValueFromField} from './htmlUtils'

export const validateEmail = (email: string): boolean => {
  if (isEmail(email)) {
    return true;
  }
  return false;
};

export const validatePassword = (password: string): boolean => {
  if (password.length > 5) {
    return true;
  }
  return false;
};

export const validateRepeatPassword = (password: string, repeatPassword: string): boolean => {
  if (getValueFromField(password) === getValueFromField(repeatPassword)) {
      return true;
    }
  return false;
};

export const validateInitials = (initials: string): boolean => {
  if (initials.length < 7) {
    return true;
  }
  return false;
};



