import React from 'react';
import { connect } from 'react-redux';
import { getLabel } from '../../config/labels';
import { getLanguageCode } from '../../config/settings'

function Label(props) {
  const { profile, label } = props;
  if (profile && label) {
    let labelText;
    if (label && profile.settings && profile.settings.language) {
      labelText = getLabel(label, profile.settings.language);
    } else if (label) {
      const browserLanguage = getLanguageCode(window.navigator.language);
      labelText = getLabel(label, browserLanguage);
    } else {
      labelText = getLabel(label);
    }
    return <div style={{ display: 'inline' }}> {labelText}</div>;
  } else {
    return 'no label';
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Label);
