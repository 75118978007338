import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

// import { consLog } from '../../utils/index';
import Label from "../layout/Label";
import styled from "styled-components";
import {
  getHeight,
  backgroundDivStyle,
  buttonStyle,
  cardStyle,
  wrapperStyle
} from "../../config/styles";

import {
  getActivity,
  clearActivityState
} from "../../store/actions/activityActions";
import { addEvent, clearEventState } from "../../store/actions/eventActions";
import DateTimePickerField from "../fields/DateTimePickerField";
import SpecialField from "../fields/SpecialField";
import { getHelperText } from "../../config/labels";
// import { Activity } from '../../config/types';
import { HISTORY_BACK_INTERVAL } from "../../config/settings";

import { dateStringToTimestamp, getId } from "../../utils";
import { getValueFromField, setValueOfField } from "../../utils/htmlUtils";

const AddEvent = (props: any) => {
  const { auth, profile, match, getActivity, activity, clearActivityState, clearEventState } = props;
  const { activityId } = match.params;
  const [formValues, setFormValues] = useState({
    eventName: "",
    eventNameError: false,
    eventNameErrorText: "",
    startDateTime: null,
    startDateTimeError: false,
    startDateTimeErrorText: "",
    endDateTime: null,
    eventLocation: "",
    eventAddress: "",
    presenceLimit: null,
    note: ""
  });

  React.useEffect(() => {
    if (activityId) {
      getActivity(activityId);
    }
    return () => {
      clearActivityState();
      clearEventState();
    };
  }, [activityId, getActivity, clearActivityState, clearEventState]);

  if (!auth.uid) return <Redirect to="/signin" />;

  const handleAdd = async () => {
    let newFormValues = { ...formValues };
    let formError = false;

    newFormValues.eventName = getValueFromField("event-name");
    if (!newFormValues.eventName) {
      newFormValues.eventNameError = true;
      newFormValues.eventNameErrorText = getHelperText("event-name");
      formError = true;
    }
    newFormValues.startDateTime = getValueFromField("start-date-time");
    if (!newFormValues.startDateTime) {
      newFormValues.startDateTimeError = true;
      newFormValues.startDateTimeErrorText = getHelperText("start-date-time");
      formError = true;
    }
    newFormValues.endDateTime = getValueFromField("end-date-time");
    newFormValues.eventLocation = getValueFromField("location");
    newFormValues.eventAddress = getValueFromField("address");
    newFormValues.presenceLimit = getValueFromField("presence-limit");
    newFormValues.note = getValueFromField("note");

    if (formError) {
      setFormValues(newFormValues);
    } else {
      const startDateTimeNumber: number = dateStringToTimestamp(
        getValueFromField("start-date-time")
      );
      const endDateTimeNumber: number = dateStringToTimestamp(
        getValueFromField("end-date-time")
      );

      const activityName =
        activity && activity.activityName ? activity.activityName : "";

      const eventId = getId("");
      const event = {
        eventId: eventId,
        eventName: newFormValues.eventName,
        activityId: activityId,
        activityName: activityName,
        startDateTime: startDateTimeNumber,
        endDateTime: endDateTimeNumber,
        eventLocation: getValueFromField("location"),
        eventAddress: getValueFromField("address"),
        presenceLimit: getValueFromField("presence-limit") ? parseInt(getValueFromField("presence-limit")) : 0,
        presenceTotal: 0,
        note: getValueFromField("note")
      };
      await props.addEvent(auth, profile, event);
      setTimeout(() => window.history.back(), HISTORY_BACK_INTERVAL);
    }
  };

  const handleChangeLocation = (event: any) => {
    if (!event.target.value) {
      setValueOfField("address", "");
    }
  };

  // const handleMinDateTime = () => {
  //   const minDateTime: any = getValueFromField('start-date-time');
  //   setMinDateTime(minDateTime);
  // };

  if (activity) {
    const location = activity.defaultLocation
      ? activity.defaultLocation
      : formValues.eventLocation;

    const address = activity.defaultAddress
      ? activity.defaultAddress
      : formValues.eventAddress;

    const presenceLimit = activity.defaultPresenceLimit
      ? activity.defaultPresenceLimit
      : formValues.presenceLimit;

    return (
      <StyledBackgroundDiv>
        <StyledWrapper>
          <StyledCard raised>
            <form>
              <SpecialField
                name="event-name"
                defaultValue={formValues.eventName}
                helperText={formValues.eventNameErrorText}
                error={formValues.eventNameError}
                fullWidth
              />
              <DateTimePickerField
                name="start-date-time"
                defaultValue={formValues.startDateTime}
                helperText={formValues.startDateTimeErrorText}
                error={formValues.startDateTimeError}
                fullWidth
              />
              <DateTimePickerField
                name="end-date-time"
                defaultValue={formValues.endDateTime}
                // minDateTime={minDateTime}
                fullWidth
              />
              <SpecialField
                name="location"
                defaultValue={location}
                fullWidth
                onChange={handleChangeLocation}
              />
              <SpecialField name="address" defaultValue={address} fullWidth />
              <SpecialField
                name="presence-limit"
                defaultValue={presenceLimit}
                fullWidth
              />
              <SpecialField
                name="note"
                defaultValue={formValues.note}
                fullWidth
              />
              <StyledButton onClick={handleAdd}>
                <Label label={"add-event"} />
              </StyledButton>
            </form>
          </StyledCard>
        </StyledWrapper>
      </StyledBackgroundDiv>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activity: state.activity.activity
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivity: (activityId: string) => dispatch(getActivity(activityId)),
    addEvent: (auth: any, profile: any, event: Event) =>
      dispatch(addEvent(auth, profile, event)),
    clearActivityState: () => dispatch(clearActivityState()),
    clearEventState: () => dispatch(clearEventState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);

// Styles
const StyledBackgroundDiv = styled("div")`
  ${backgroundDivStyle}
  background-color: ${(props: any) => props.theme.colors.background};
  min-height: ${getHeight()}px
`;
const StyledButton = styled(Button)`
  background: ${(props: any) => props.theme.colors.primaryVariant};
  color: ${(props: any) => props.theme.colors.onBackground};
  ${buttonStyle}
`;
const StyledCard = styled(Card)`
  background: ${(props: any) => props.theme.colors.primaryLight};
  ${cardStyle}
`;
const StyledWrapper = styled("div")`
  ${wrapperStyle}
`;
